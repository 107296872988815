import { GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { useEffect, useMemo, useState } from 'react';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../../../components/grid-renderers/FadeInGridWrapper';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { useSchemaToGrid } from '../../../../../util/schema-utils';
import { defaultColDef } from '../../KPICompanies/AssignedCompaniesColDefs';
import NotSentActionsCellRenderer from '../components/NotSentActionsCellRenderer';
import ResponsesActionsCellRenderer from '../components/ResponsesActionsCellRenderer';
import { SentRequestActions } from '../components/SentRequestActions';
import { getFieldsForStatus, kpiAllRequestGridSchema } from './kpi-manage-grids.view-model';
import { KpiRequestManageViewModel } from './kpi-manage.view-model';

export type KpiManageGridProps = {
  requests: KpiRequestManageViewModel[];
  selectedStatus: KPIRequestStatus | string;
};

export function KpiManageGrid(props: KpiManageGridProps) {
  const { requests, selectedStatus } = props;
  const [gridApi, setGridApi] = useState<GridApi>();
  const schemaToGrid = useSchemaToGrid();
  const colDefs = useMemo(() => {
    return schemaToGrid(kpiAllRequestGridSchema(), getFieldsForStatus(selectedStatus));
  }, [schemaToGrid, selectedStatus]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  useEffect(() => {
    if (!gridApi) return;

    const filteredData =
      selectedStatus === 'All' ? requests : requests.filter((request) => request.status === selectedStatus);

    gridApi.setFilterModel({});
    gridApi.setGridOption('rowData', filteredData);
  }, [gridApi, selectedStatus, requests]);

  return (
    <FadeInGridWrapper>
      <AgTable
        rowData={requests}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        rowGroupPanelShow='always'
        groupDefaultExpanded={-1}
        suppressMovableColumns={true}
        suppressRowDrag={true}
        statusBar={{
          statusPanels: [
            { statusPanel: 'agTotalRowCountComponent' },
            { statusPanel: 'agFilteredRowCountComponent' },
          ],
        }}
      />
    </FadeInGridWrapper>
  );
}

export function KpiManageActionCellRenderer(params: ICellRendererParams<KpiRequestManageViewModel>) {
  switch (params.data?.status) {
    case KPIRequestStatus.Rejected:
    case KPIRequestStatus.Accepted:
    case KPIRequestStatus.Submitted:
      return <ResponsesActionsCellRenderer {...params} />;
    case KPIRequestStatus.NotSent:
      return (
        <NotSentActionsCellRenderer {...(params as unknown as ICellRendererParams<IKPIRequestDataModel>)} />
      );
    case KPIRequestStatus.Sent:
      return <SentRequestActions {...(params as unknown as ICellRendererParams<IKPIRequestDataModel>)} />;
    case KPIRequestStatus.Archived:
    default:
      return null;
  }
}
