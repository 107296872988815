import { EChartsOption } from 'echarts';
import { ECharts, init } from 'echarts/core';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Chart, ChartSizes } from '../../../../components/Charts/chart-utils';
import { ChartTitleWrapper } from '../../../../components/Charts/ChartTitleWrapper';
import { ChartWrapper } from '../../../../components/Charts/ChartWrapper/ChartWrapper';
import { MIXED_COLORS } from '../../../../components/Charts/MixedChart/MixedChart';
import { IconTypes } from '../../../../components/Icon';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { UnknownUser } from '../../../../data-models/user.data-model';
import { CHART_COMPONENTS } from '../../../../types';
import { FMT } from '../../../../util/formatter-service';
import { useGetCompanyIfSet } from '../../../CompanyProfiles/hooks/useGetCompanyData';
import { irrIterator, moicIterator } from '../OverviewTable/columns/AggregationConfigs';
import { IPortfolioChartProps } from './ChartComponent';

export function MoicAndIrrByDealLeadChart(props: IPortfolioChartProps<IMetricsDataModel>) {
  const getCompany = useGetCompanyIfSet();
  const filteredData = getDataByDealLead(props.filteredMetrics, getCompany);
  const [widthState, setWidthState] = useState<string | number>('Full Screen');
  const [isHovering, setIsHovering] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [size, setSize] = useState('Full Screen');
  const containerRef = useRef(null);
  const mixedChart = useRef<ECharts | null>(null);
  const xData = useMemo(
    () =>
      Array.from(filteredData.keys()).map((dealLeadId) => {
        const fullName = FMT.format('user', dealLeadId) || '';
        const names = fullName.split(' ');
        if (names.length < 2) return fullName;
        const lastName = names.pop() || '';
        const initials = names.map((name) => name[0]).join('');
        return `${initials} ${lastName}`;
      }),
    [filteredData]
  );
  const moicData = useMemo(
    () => Array.from(filteredData.values()).map((value) => value.moic),
    [filteredData]
  );
  const irrData = useMemo(() => Array.from(filteredData.values()).map((value) => value.irr), [filteredData]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };
    updateSize();
  }, [size]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    if (!mixedChart.current) {
      mixedChart.current = init(container);
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          mixedChart.current?.resize();
        }
      }
    });
    resizeObserver.observe(container);
    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [containerRef]);

  useEffect(() => {
    const options: EChartsOption = {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        containLabel: true,
        top: 30,
        left: 0,
        right: 50,
        bottom: 0,
      },
      xAxis: [
        {
          type: 'category',
          data: xData,
          axisLabel: {
            rotate: 90,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'MOIC',
          axisLabel: {
            formatter: (value) => `${FMT.format('multiplier', value)}`,
          },
          position: 'left',
        },
        {
          type: 'value',
          name: 'IRR',
          axisLabel: { formatter: (value) => `${FMT.format('percent2dpAsIs', value)}` },
          position: 'right',
        },
      ],
      series: [
        {
          name: 'MOIC',
          data: moicData,
          type: 'bar',
          yAxisIndex: 0,
          tooltip: {
            valueFormatter: function (value) {
              return FMT.format('multiplier', value);
            },
          },
          color: MIXED_COLORS[0],
        },
        {
          name: 'IRR',
          data: irrData,
          type: 'scatter',
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return FMT.format('percent2dpAsIs', value);
            },
          },
          color: MIXED_COLORS[3],
        },
      ],
    };
    if (!mixedChart.current) return;
    mixedChart.current.setOption(options, true);
  }, [xData, moicData, irrData]);

  return (
    <>
      <ChartWrapper
        id={CHART_COMPONENTS.MOIC_AND_IRR_BY_DEAL_LEAD}
        onMouseLeave={() => {
          setShowMore(false);
          setIsHovering(false);
        }}
        onMouseEnter={() => setIsHovering(true)}
        width={widthState}
      >
        <ChartTitleWrapper
          isHovering={isHovering}
          title={'MOIC and IRR by Deal Lead'}
          showMore={showMore}
          setShowMore={setShowMore}
          id={CHART_COMPONENTS.MOIC_AND_IRR_BY_DEAL_LEAD}
          icon={IconTypes.FINANCE}
          handleSizeChange={setSize}
          size={size}
          refProp={containerRef}
        />
        <Chart ref={containerRef} width={'100%'} height='300px' />
      </ChartWrapper>
    </>
  );
}

function getDataByDealLead(
  metrics: IMetricsDataModel[],
  getCompany: (id: number) => ICompanyDataModel | null
) {
  const metricsByDealLead = metrics.reduce((map, metric) => {
    const dealLeadId = getCompany(metric.companyId)?.dealLeadId;
    if (!dealLeadId || FMT.format('user', dealLeadId) == UnknownUser.name) return map;

    if (map.has(dealLeadId)) {
      map.get(dealLeadId)?.push(metric);
    } else {
      map.set(dealLeadId, [metric]);
    }
    return map;
  }, new Map<number, IMetricsDataModel[]>());

  const sortedMap = new Map(
    Array.from(metricsByDealLead).sort((a, b) => {
      const aDealLead = FMT.format('user', a[0]);
      const bDealLead = FMT.format('user', b[0]);
      return aDealLead?.localeCompare(bDealLead ?? '') ?? 0;
    })
  );

  const dataByDealLead = new Map<number, { moic: number; irr: number }>();
  sortedMap.entries().forEach(([dealLeadId, metrics]) => {
    const moicIt = moicIterator();
    const irrIt = irrIterator();
    metrics.forEach((metric) => {
      moicIt.next(metric);
      irrIt.next(metric);
    });
    dataByDealLead.set(dealLeadId, { moic: moicIt.value.value ?? 0, irr: irrIt.value.value ?? 0 });
  });

  return dataByDealLead;
}
