import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Chip, Paper, Stack, styled, Typography, useTheme } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { Fragment } from 'react';
import { ControllerRenderProps, get, useFormContext, useFormState, useWatch } from 'react-hook-form';
import { FieldKPIFileUpload2 } from '../../../../../components/Form/Display/FieldKPIFileUpload2';
import { FormFieldFactory } from '../../../../../components/Form/FormFieldFactory';
import { RendererType } from '../../../../../data-models/field.data-model';
import {
  field2ToFormField,
  IField,
  IRendererConfig,
  ITextFieldConfig,
} from '../../../../../data-models/field2.data-model';
import {
  IKPITableDataModel,
  IKPITemplateSectionDataModel,
} from '../../../../../data-models/kpi-template.data-model';
import { IFormField, IFormFieldUploadMeta } from '../../../../../view-models/form.view-model';
import { DisabledFieldset } from '../../../../CompanyProfiles/Scenarios/components/commonStyledComponents';
import { getFieldRefByRenderType } from '../../../utils';
import { KPITable, KPITableEditable } from './KPITable/KPITable';
import { EditableParagraphField, ParagraphField } from './ParagraphField';

const SectionTextPreviewWrapper = styled(Paper)(({ theme }) => ({
  height: 'fit-content',
  ...theme.typography.body2,
  color: theme.colors.neutral[60],
  padding: 0,
  background: 'transparent',
  width: '100%',
}));

export const ErrorMessagesWrapper = styled('div')`
  align-items: flex-end;
  display: flex;
  margin-left: 2rem;
  margin-bottom: 1.25rem;
  width: 400px;
`;

const FormErrorMessages = ({
  editMode,
  errorPointer,
}: {
  editMode: boolean;
  errorPointer: { [key: string]: string };
}) => {
  const { colors } = useTheme();

  let mt = '.25rem';
  let message: string;

  if (errorPointer && errorPointer.length) {
    message = 'Please enter the values';
  } else {
    mt = '1rem';
    message = errorPointer?.message ?? '';
  }

  return errorPointer ? (
    <ErrorMessagesWrapper>
      <Typography
        variant='caption'
        color={colors.critical[50]}
        sx={{ mt, display: 'flex', direction: 'row', alignItems: 'center', gap: '.25rem' }}
      >
        <ErrorOutlineOutlinedIcon
          sx={{
            fontSize: '1.25rem',
          }}
        />
        {message}
      </Typography>
    </ErrorMessagesWrapper>
  ) : editMode ? (
    <div style={{ width: '400px', marginLeft: '2rem' }}></div>
  ) : null;
};

export interface IKPIItemBuilderProps {
  sectionRef: string;
  editMode?: boolean;
  editSectionIndex?: number;
  readOnly?: boolean;
  showErrors?: boolean;
}
export function KPIItemFactory(props: IKPIItemBuilderProps) {
  const { colors } = useTheme();
  const { errors } = useFormState();
  const showErrors = props.showErrors ?? true;
  const section = useWatch({ name: props.sectionRef }) as IKPITemplateSectionDataModel;
  const { editMode, readOnly } = props;
  const orderNumber = section?.meta?.sortOrder !== undefined ? section?.meta?.sortOrder + 1 : '';

  const Wrapper = readOnly ? DisabledFieldset : Fragment;

  if (section.type === 'grid') {
    const { displayName, description, metrics, section: actualOrBudget } = section.meta as IKPITableDataModel;
    const sectionFieldRef = editMode ? `sectionData.${props.editSectionIndex}.value` : props.sectionRef;

    return (
      <SectionTextPreviewWrapper elevation={0}>
        <Box sx={{ width: '100%', height: '100%', overflowX: 'scroll' }}>
          <Stack marginBottom={'1rem'}>
            <Stack direction='row'>
              <Typography color={colors.primary[80]} sx={{ mt: '.75rem', mb: '.5rem' }} fontWeight={500}>
                {editMode && <>{orderNumber}. </>}
                {displayName}
              </Typography>
              <Chip label={actualOrBudget} color={'secondary'} sx={{ margin: '.75rem 0 0 .5rem' }} />
            </Stack>
            {description && description.length > 0 && (
              <Typography
                variant='caption'
                color={colors.neutral[60]}
                sx={{ mb: '0.25rem' }}
                fontWeight={500}
              >
                {description}
              </Typography>
            )}
          </Stack>
          {editMode ? (
            <Wrapper>
              <KPITableEditable
                sectionFieldRef={sectionFieldRef}
                sectionRef={props.sectionRef}
                metrics={metrics}
                showErrors={showErrors}
              />
            </Wrapper>
          ) : (
            <KPITable metrics={metrics} sectionMeta={section.meta as IKPITableDataModel} />
          )}
        </Box>
      </SectionTextPreviewWrapper>
    );
  } else {
    const fieldSection = section.meta as IField<unknown>;
    const { entityField, description, formMeta } = fieldSection;

    const sectionFieldRef = editMode ? `sectionData.${props.editSectionIndex}.value` : props.sectionRef;

    const sectionFieldErrorsRef = editMode
      ? getFieldRefByRenderType(
          formMeta?.renderer?.type as RendererType,
          `sectionData.${props.editSectionIndex}.value`
        )
      : props.sectionRef;

    const errorPointer = get(errors, sectionFieldErrorsRef, null);

    return (
      <>
        <SectionTextPreviewWrapper elevation={0}>
          <Box sx={{ width: '100%', height: '100%', maxWidth: '900px' }}>
            <Stack gap='.5rem'>
              <Stack>
                <Typography color={colors.primary[80]} sx={{ mt: '.75rem' }} fontWeight={500}>
                  {editMode && <>{orderNumber}. </>}
                  {entityField}
                </Typography>
              </Stack>

              {description?.length && (
                <Typography
                  variant='caption'
                  color={colors.neutral[60]}
                  sx={{ mb: '0.25rem' }}
                  fontWeight={500}
                >
                  {description}
                </Typography>
              )}
              {formMeta?.renderer ? (
                <KPIItem
                  sectionRef={sectionFieldRef}
                  field={fieldSection}
                  renderer={formMeta?.renderer}
                  editMode={editMode}
                  readOnly={readOnly}
                />
              ) : null}
            </Stack>
          </Box>

          {showErrors && <FormErrorMessages editMode={editMode as boolean} errorPointer={errorPointer} />}
        </SectionTextPreviewWrapper>
      </>
    );
  }
}

interface IKPIItemProps {
  field: IField<unknown>;
  sectionRef: string;
  renderer: IRendererConfig<unknown>;
  editMode?: boolean;
  readOnly?: boolean;
}

function KPIItem({ field, renderer, editMode, sectionRef, readOnly }: IKPIItemProps) {
  const Wrapper = readOnly ? DisabledFieldset : Fragment;
  if (editMode) {
    switch (renderer?.type) {
      case RendererType.text:
        return (
          <Wrapper>
            <EditableParagraphField
              responseFormRef={sectionRef}
              renderer={renderer as IRendererConfig<ITextFieldConfig>}
            />
          </Wrapper>
        );
      case RendererType.fileUpload:
        // cannot wrap file upload field with disabled fieldset because we need to have the download button enabled
        return (
          <FieldKPIFileUpload2Wrapper
            field={sectionFieldToField2(field)}
            sectionRef={sectionRef}
            readOnly={readOnly ?? false}
          />
        );
      default:
        return null;
    }
  } else {
    switch (renderer?.type) {
      case RendererType.multiSelect: {
        return <FormFieldFactory formField={field2ToFormField(field)} />;
      }
      case RendererType.text:
        return <ParagraphField renderer={renderer as IRendererConfig<ITextFieldConfig>} />;

      // We don't need to render the file upload features in the preview mode
      default:
        return null;
    }
  }
}

function FieldKPIFileUpload2Wrapper({
  field,
  sectionRef,
  readOnly = false,
}: {
  field: IField<unknown>;
  sectionRef: string;
  readOnly: boolean;
}) {
  const { setValue, watch } = useFormContext();

  const formProps = {
    onChange: (newValue) => {
      setValue(sectionRef, newValue);
    },
    value: cloneDeep(watch(sectionRef)),
    disabled: readOnly,
  } as ControllerRenderProps;

  const formField = {
    ...field2ToFormField(field),
    key: sectionRef,
    renderer: RendererType.fileUpload,
    rendererMeta: {
      multiple: true,
      companyId: watch('kpiRequest.companyId'),
    },
  };

  const key = watch('sectionData').length; // initial values are empty, we need to force re-render
  return (
    <FieldKPIFileUpload2
      key={`${key}-${sectionRef}`}
      formField={formField as IFormField<IFormFieldUploadMeta>}
      formProps={formProps}
    />
  );
}

// FIXME: Delete after https://foresightdata.atlassian.net/browse/MAGGIE-8481 is resolved
function sectionFieldToField2(sectionField: IField<unknown>) {
  return {
    ...sectionField,
    displayName: sectionField.displayName || sectionField.entityField,
  };
}
