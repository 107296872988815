import { cloneDeep } from '@apollo/client/utilities';
import { GridState, StateUpdatedEvent } from 'ag-grid-community';
import { atom } from 'jotai';
import { atomFamily, useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import {
  companyViewChangeMessage,
  companyViewChangeTitle,
  sharedViewChangeMessage,
  sharedViewChangeTitle,
} from '../hooks/useViewActions';
import { useSaveMprStateInView } from './mprViewActions';
import { mpReportingGridApi } from './MultiPeriodGrid';

export function useGetMprGridState() {
  return useAtomCallback(
    useCallback((get) => {
      const gridApi = get(mpReportingGridApi);
      if (!gridApi || gridApi.isRowDataEmpty()) return;
      return getStateToPersist(gridApi.getState());
    }, [])
  );
}

const stateToRemove = new Set<keyof GridState>(['cellSelection', 'focusedCell', 'scroll']);
function getStateToPersist(gridState: GridState) {
  const res = cloneDeep(gridState);
  stateToRemove.forEach((key) => {
    if (key in res) delete res[key as keyof typeof res];
  });
  return res;
}

const shownWarning = atomFamily(() => atom(false));

export function useSaveGridState({
  id,
  isDefault,
  isShared,
}: {
  id: number;
  isDefault: boolean;
  isShared: boolean;
}) {
  const saveGridState = useSaveMprStateInView();
  const { pushWarningToast } = useToastMessageState();

  return useAtomCallback(
    useCallback(
      (get, set, event: StateUpdatedEvent) => {
        if (
          event.sources?.includes('gridInitializing') ||
          stateToRemove.union(new Set(event.sources)).size === stateToRemove.size
        ) {
          return;
        }
        const gridState = getStateToPersist(event.state);
        if (isDefault || isShared) {
          if (!get(shownWarning(id))) {
            pushWarningToast({
              message: isDefault ? companyViewChangeMessage : sharedViewChangeMessage,
              title: isDefault ? companyViewChangeTitle : sharedViewChangeTitle,
            });
            set(shownWarning(id), true);
          }
          return;
        }
        saveGridState({ gridState });
      },
      [id, isDefault, isShared, pushWarningToast, saveGridState]
    )
  );
}
