import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Typography, styled, useTheme } from '@mui/material';
import { useCallback } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NoteActionButtons from '../../../pages/CompanyProfiles/components/ActivityDrawer/Notes/components/NoteActionsButtons';
import { useParseCompanyIdFromPath } from '../../../pages/CompanyProfiles/utils/useParseCompanyIdFromPath';
import { useDeleteNote } from '../../../services/hooks/useNoteActions';
import { useLexicalContext } from '../provider/LexicalProvider';

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

const RightSection = styled('div')`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const NoteActionButtonsWrapper = styled('div')`
  width: 2rem;
`;

export function BackIconButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton color={'secondary'} onClick={onClick} sx={{ padding: '10px' }}>
      <ArrowBackIcon sx={{ fontSize: '22px' }} />
    </IconButton>
  );
}

interface ILexicalHeader {
  onExit: () => void;
}

const SuccessIndicatorWrapper = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: center;
  margin-right: 0.5rem;
`;

export function LexicalHeader({ onExit }: ILexicalHeader) {
  const { noteData, lastSuccessUpdateOnNotes } = useLexicalContext();
  const companyId = useParseCompanyIdFromPath();
  const { palette } = useTheme();
  const deleteNote = useDeleteNote();

  const onDeleteNote = useCallback(async () => {
    const wasDeleleted = await deleteNote(companyId, noteData?.id ?? 0);

    if (wasDeleleted) {
      onExit();
    }
  }, [companyId, deleteNote, noteData?.id, onExit]);

  return (
    <Header>
      <BackIconButton onClick={onExit} />
      <RightSection>
        {lastSuccessUpdateOnNotes && (
          <SuccessIndicatorWrapper>
            <CheckCircleIcon sx={{ color: palette.success.main }} />
            <Typography
              variant='body2'
              sx={{
                color: palette.success.main,
              }}
            >
              Changes saved
            </Typography>
          </SuccessIndicatorWrapper>
        )}
        <NoteActionButtonsWrapper>
          <NoteActionButtons deleteNote={onDeleteNote} />
        </NoteActionButtonsWrapper>
      </RightSection>
    </Header>
  );
}
