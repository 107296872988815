import { styled } from '@mui/material/styles';
import { EChartsType, init as echartsInit } from 'echarts';
import { MutableRefObject, useEffect } from 'react';
import { ISeries } from '../../pages/CompetitiveIntelligence/components/HistoryComparison/HistoryComparisonChart/HistoryComparisonChart';
import { formatInteger } from '../../util/formatters/NumericFormatters';

export type ValuationBridgeDataItem = {
  amount: number;
  colour: string;
  hidden: number;
  isNegative?: boolean;
  moic?: number;
  multiplier?: string;
  name: string;
  value: number;
};

export function findNearestValue(series: ISeries, xTime: number) {
  let shortestDistance = Number.MAX_VALUE;
  let value = null;

  for (let i = 0; i < series.data.length; i++) {
    const distance = Math.abs((series.data[i][0] as number) - xTime);

    if (distance > shortestDistance) {
      break;
    }

    if (distance < shortestDistance) {
      shortestDistance = distance;
      value = series.data[i][1];
    }
  }

  return value;
}

export const ChartIconWrapper = styled('div')<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  padding: 0.3rem 0.43rem;
  font-size: 0.2rem;
  margin-right: 1rem;
  cursor: initial;
`;

export const ChartTitle = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

export const Chart = styled('div')<{ width: number | string; height?: string }>`
  min-width: ${({ width }) => `${width}`};
  height: ${({ height }) => (height ? `${height}` : '280px')};
  position: relative;
`;

export const ChartSizes = {
  '1/2 Screen': '1/2',
  '1/4 Screen': '1/4',
  'Full Screen': '1',
};

export const barchartTrendColors = [
  'rgba(45, 52, 192, 1)',
  'rgba(110, 196, 223, 1)',
  'rgba(182, 187, 196, 1)',
];

export function getTooltip(label: string, value: number, color: string) {
  return `
    <div style='display: grid; grid-template-columns: repeat(2, 1fr);column-gap: 0.5rem; color: ${color}; font-weight: bold'>
      <p>${label}</p>
      <p><b>${formatInteger(value)}</b></p>
    </div>
`;
}

export const ChartContainer = styled('div')<{ isHidden: boolean }>`
  ${({ isHidden: hidden }) =>
    hidden
      ? `
    display: hidden;
    position: absolute;
   `
      : `
    display: display;
    position: relative;
   `}
`;

/**
 * @deprecated use EChartWrapper instead
 */
export function useChartResizeObserver(
  chartRef: MutableRefObject<EChartsType | null>,
  chartContainerRef: MutableRefObject<HTMLDivElement | null>
) {
  useEffect(() => {
    const container = chartContainerRef.current;
    if (!container) {
      return;
    }
    if (!chartRef.current) {
      chartRef.current = echartsInit(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          chartRef.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainerRef, chartRef]);
}
