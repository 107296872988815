import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Typography } from '@mui/material';
import { capitalize } from 'lodash-es';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';

interface IKpiResponseStatusProps
  extends Pick<IKPIRequestDataModel, 'status' | 'rejectReason' | 'acceptMessage'> {}
export function KpiResponseStatus({ status, rejectReason, acceptMessage }: IKpiResponseStatusProps) {
  if (status !== KPIRequestStatus.Rejected && status !== KPIRequestStatus.Accepted) return null;
  const color = status === KPIRequestStatus.Rejected ? 'error' : 'success';
  const icon = status === KPIRequestStatus.Rejected ? <CancelOutlinedIcon /> : <CheckCircleOutlinedIcon />;
  const commentary = status === KPIRequestStatus.Rejected ? rejectReason : acceptMessage;

  return (
    <Box pl='0.2rem'>
      <Typography variant='body2' color={color} display='flex' alignItems={'center'} gap={'0.25rem'}>
        {icon}
        {capitalize(status)}
      </Typography>
      {commentary && <Typography variant='body2' mt={'0.4rem'}>{`Commentary: "${commentary}"`}</Typography>}
    </Box>
  );
}
