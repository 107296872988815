// FIXME: MAGGIE-3904
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
// import TreeViewPlugin from './plugins/TreeViewPlugin';
// import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
// import { ListPlugin } from '@lexical/react/LexicalListPlugin';
// import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
// import { TRANSFORMERS } from '@lexical/markdown';
// import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
// import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
// import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  InputBase,
  Stack,
  styled,
  TextField,
  useTheme,
} from '@mui/material';
import { useAtomValue } from 'jotai/index';

import { EditorState } from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { createDraftNoteModel, ILexicalNoteFormValues } from '../../data-models/note.data-model';
import { requiredMsg } from '../../pages/Finance/components/TransactionModal/Forms/utils/validationMessages';
import { usersAtom, usersByIdMapAtom } from '../../services/state/AppConfigStateJ';
import { LexicalFooter } from './components/LexicalFooter';
import { LexicalHeader } from './components/LexicalHeader';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { AutoSavePlugin } from './plugins/AutoSavePlugin';
import './editorStyles.css';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin/FloatingTextFormatToolbarPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { useLexicalContext } from './provider/LexicalProvider';
import DefaultTheme from './themes/DefaultTheme';

const Container = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DividerLine = styled('div')`
  background-color: ${({ theme }) => theme.colors.neutral[30]};
  height: 1px;
  border-radius: 10px;
`;

const NoteContainer = styled('div')`
  width: 100%;
  padding: 1rem 1.5rem;
`;

const EDITOR_CONFIG = {
  // The editor theme
  namespace: 'Company Note',
  theme: DefaultTheme,
  // Handling of errors during update
  onError(error: Error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

function Placeholder() {
  return <div className='editor-placeholder'>Type your note here</div>;
}

const draftNote = JSON.stringify(createDraftNoteModel());

export function LexicalEditor() {
  const values = useLexicalContext();
  const { noteData, setNoteData, onExit, editorStateRef } = values;

  const { colors } = useTheme();
  const users = useAtomValue(usersAtom);
  const userMap = useAtomValue(usersByIdMapAtom);
  const userIds = useMemo(() => users.map((user) => user.id), [users]);

  const methods = useForm<ILexicalNoteFormValues>({
    mode: 'onChange',
    defaultValues: {
      title: noteData?.title ?? '',
      // Initialize sections data
      body: {
        attendees: noteData?.body?.attendees ?? [],
      },
    },
  });

  const title = methods.watch('title') ?? '';
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    // Auto focus on title input if title is empty (new note)
    setTimeout(() => {
      if (title) return;
      methods.setFocus('title');
    }, 50);
  }, [methods, title]);

  const getOptionDisabled = useCallback((id: number) => userMap.get(id)?.activeStatus !== true, [userMap]);

  const onChangeEditorState = useCallback(
    (editorState: EditorState) => {
      editorStateRef.current = editorState;
      setNoteData({
        ...noteData!,
        body: {
          ...noteData!.body,
          data: editorState.toJSON(),
        },
      });
    },
    [editorStateRef, noteData, setNoteData]
  );

  const selectedAttendees = methods.watch('body.attendees');

  const selectedAttendeesIds = useMemo(
    () => selectedAttendees.map((attendee) => attendee.userId),
    [selectedAttendees]
  );

  const onChangeSelectedAttendees = useCallback(
    (attendees: number[]) => {
      if (!selectedAttendees) return;
      const updatedAttendees = attendees.map((attendeeId: number, index: number) => {
        return {
          ...selectedAttendees[index],
          userId: attendeeId,
        };
      });

      methods.setValue('body.attendees', updatedAttendees);
    },
    [methods, selectedAttendees]
  );

  const onClearAllSelectedAttendees = useCallback(() => {
    methods.setValue('body.attendees', []);
  }, [methods]);

  return (
    <Container>
      <FormProvider {...methods}>
        <LexicalHeader onExit={onExit} />

        <DividerLine />

        <NoteContainer>
          <Controller
            name='title'
            rules={{ required: true }}
            render={({ field: { ref, ...titleField } }) => {
              const hasError = !!methods.formState.errors.title;
              return (
                <FormControl error={hasError} variant='standard' fullWidth>
                  <InputBase
                    {...titleField}
                    aria-describedby='note-title-error-text'
                    placeholder='Note Title'
                    sx={{ fontSize: '10px', marginTop: 0, width: '100%' }}
                    inputRef={ref}
                    inputProps={{
                      sx: {
                        fontSize: '24px',
                        height: '50px',
                        backgroundColor: 'white',
                        pt: 0,
                        border: 0,
                        '&::placeholder': {
                          color: colors.neutral[70],
                        },
                      },
                    }}
                  />
                  {hasError && (
                    <FormHelperText id='note-title-error-text'>{requiredMsg('Title')}</FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />

          <Stack gap='.75rem' direction='row' width={'100%'} justifyContent={'center'} alignItems={'center'}>
            <AccountCircleOutlinedIcon sx={{ color: colors.neutral[50], fontSize: '1.5rem' }} />
            <Controller
              name='body.attendees'
              rules={{ required: false }}
              render={({ field }) => (
                <Autocomplete
                  aria-label='Add attendees'
                  multiple
                  openOnFocus
                  options={userIds}
                  getOptionDisabled={getOptionDisabled}
                  value={selectedAttendeesIds}
                  onChange={(e: React.SyntheticEvent, attendees) => {
                    field.onChange(attendees);
                    onChangeSelectedAttendees(attendees);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={'Select Attendees'} {...field} />
                  )}
                  getOptionLabel={(option) => {
                    if (!userMap.get(option)) return '';

                    const user = userMap.get(option);

                    return `${user?.name} (${user?.email})`;
                  }}
                  autoHighlight
                  limitTags={0}
                  getLimitTagsText={(total) => (
                    <Chip
                      onDelete={onClearAllSelectedAttendees}
                      label={`${total} selected`}
                      sx={{
                        backgroundColor: colors.secondary[10],
                      }}
                    />
                  )}
                  disableCloseOnSelect
                  fullWidth
                />
              )}
            />
          </Stack>

          <LexicalComposer
            initialConfig={{
              ...EDITOR_CONFIG,
              editorState: JSON.stringify(noteData!.body.data) ?? draftNote,
            }}
          >
            <div className='editor-container'>
              <AutoSavePlugin />
              <ToolbarPlugin />
              <div className='editor-inner'>
                <RichTextPlugin
                  contentEditable={
                    <div ref={onRef}>
                      <ContentEditable className='editor-input' />
                    </div>
                  }
                  placeholder={<Placeholder />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <OnChangePlugin onChange={onChangeEditorState} />
                <HistoryPlugin />
                <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem as HTMLElement} />
                <AutoLinkPlugin />
                <ListPlugin />
                <LinkPlugin />
                {/*
              <CodeHighlightPlugin />
            <TreeViewPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin /> */}
                {/* <ListMaxIndentLevelPlugin maxDepth={7} /> */}
                {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
              </div>
            </div>
          </LexicalComposer>
        </NoteContainer>
        <LexicalFooter />
      </FormProvider>
    </Container>
  );
}
