import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputContainer } from '../../components/commonStyledComponents';
import {
  cashField,
  debtRepaymentField,
  exitDateField,
  exitValuationField,
  optionStrikePriceField,
  warrantStrikePriceField,
} from '../../forms/scenarioFields';
import {
  IScenarioDataModel,
  IScenarioTransactionDataModel,
} from '../../../../../data-models/scenario.data-model';
import { FormFieldWithLabelFactory } from '../../../../../components/Form/FormFieldAndLabelFactory';
import { ITransactionFieldsProps } from './TransactionFields';
import { AdvancedSettings } from './AdvancedSettings';

const advancedFields = new Set<keyof IScenarioTransactionDataModel>([
  'optionStrikePrice',
  'warrantStrikePrice',
  'cash',
  'debtRepayment',
]);

export const ExitFields: FC<ITransactionFieldsProps> = ({ index }) => {
  const {
    formState: { errors },
  } = useFormContext<Partial<IScenarioDataModel>>();

  const hasErrorsInAdvancedFields = !!Object.keys(errors.scenarioTransactions?.[index] ?? {})?.find((key) =>
    advancedFields.has(key as keyof IScenarioTransactionDataModel)
  );
  return (
    <InputContainer>
      <FormFieldWithLabelFactory formField={exitValuationField(index)} />
      <FormFieldWithLabelFactory formField={exitDateField(index)} />
      <AdvancedSettings hasErrors={hasErrorsInAdvancedFields}>
        <InputContainer>
          <FormFieldWithLabelFactory formField={optionStrikePriceField(index)} />
          <FormFieldWithLabelFactory formField={warrantStrikePriceField(index)} />
          <FormFieldWithLabelFactory formField={cashField(index)} />
          <FormFieldWithLabelFactory formField={debtRepaymentField(index)} />
        </InputContainer>
      </AdvancedSettings>
    </InputContainer>
  );
};
