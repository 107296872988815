import { ICellRendererParams } from 'ag-grid-community';
import { FC, memo, useCallback } from 'react';
import { IconButton, Stack } from '@mui/material';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import { IMetricsDataModel } from '../../../../../../../data-models/metrics.data-model';
import { CompanyViewGridEventType, ICompanyViewGridEvent } from '../../../CompanyViewGridEvent';

export const DrillDownRenderer: FC<ICellRendererParams<IMetricsDataModel>> = memo(function NameRenderer({
  node,
  api,
}: ICellRendererParams<IMetricsDataModel>) {
  const onClick = useCallback(() => {
    api.dispatchEvent({
      type: CompanyViewGridEventType.showRoundDetails,
      data: node.data,
    } as ICompanyViewGridEvent);
  }, [api, node.data]);

  if (!node.data || node.group) return null;
  return (
    <Stack height='100%' width='100%' alignItems={'center'} justifyContent={'center'}>
      <IconButton onClick={onClick}>
        <MoreOutlinedIcon fontSize={'small'} />
      </IconButton>
    </Stack>
  );
});
