import { ColDef } from 'ag-grid-community';
import { ColumnMeta, PredefinedColumn } from '../../../../../../types';
import { COMPANY_VIEW_TYPE } from '../../../../../../data-models/view-config.data-model';
import { CompanyNameAndLogo } from './CompanyNameAndLogo/CompanyNameAndLogo';
import { DrillDown } from './DrillDown/DrillDown';
import { CompanyRT } from './CompanyNameAndLogo/CompanyRT';

type ColumnsMap = Record<COMPANY_VIEW_TYPE, Record<string, PredefinedColumn>>;
export class PredefinedColumns {
  static columnsByView: ColumnsMap = {
    [COMPANY_VIEW_TYPE.PORTFOLIO_OVERVIEW]: {
      [CompanyNameAndLogo.NAME]: new CompanyNameAndLogo(),
      [DrillDown.NAME]: new DrillDown(),
    },
    [COMPANY_VIEW_TYPE.RETURN_FORECAST]: {
      [CompanyNameAndLogo.NAME]: new CompanyNameAndLogo(),
      [DrillDown.NAME]: new DrillDown(),
    },
    [COMPANY_VIEW_TYPE.ROUND_TRACKER]: {
      [CompanyRT.NAME]: new CompanyRT(),
    },
  };

  static getAllMeta(companyViewType: COMPANY_VIEW_TYPE): ColumnMeta[] {
    if (!companyViewType) return [];

    const viewPredefinedColumns = PredefinedColumns.columnsByView[companyViewType];

    return Object.values(viewPredefinedColumns).map((colConf) => colConf.getMeta());
  }

  static getDefinition(columnName: string, companyViewType?: COMPANY_VIEW_TYPE): ColDef | null {
    if (!companyViewType) return null;

    const column = PredefinedColumns.columnsByView[companyViewType]?.[columnName];
    if (!column) return null;

    return column.getDefinition();
  }
}
