import AddIcon from '@mui/icons-material/Add';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ThumbDownIconOutlined from '@mui/icons-material/ThumbDownOutlined';
import { Button, IconButton, Stack, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Add } from '@mui/icons-material';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { IListItemDataModel as ListItemType } from '../../../data-models/list-item.data-model';
import { useCurrentUser } from '../../../services/hooks/useCurrentUser';
import { useAddListItemToPipeline } from '../../../services/hooks/useListActions';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { useCompanyActions } from '../Summary/hooks/useCompanyActions';
import { companyState } from '../../../services/state/CompanyState';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { TrackCompanyModal } from '../forms/TrackCompanyModal';
import { colors } from '../../../theme/colors';
import { getAllItemActionsByPriority, isFollowedByUser } from '../utils/list-utils';
import { activeListState } from '../state/ListsState';
import { ListType } from '../../../data-models/list.data-model';
import { CompanySocialMedia } from '../Summary/components/CompanySocialMedia';
import { getCompanyDescription } from '../../../util/companyUtils';
import { CompanyDetails2 } from './CompanyDetails2';
import { UndoButton } from './UndoButton';
import { ItemCommentsSection } from './ItemCommentsSection';
import { TrackedListActions } from './TrackedListActions';
import { CompanyDetails } from './CompanyDetails';

const ContainerBreakpoint = 600;

const ListItemDetailsWrapper = styled('div')`
  display: grid;
  padding: 1.5rem;
  grid-template-rows: min-content min-content 1fr;
  row-gap: 1rem;
  overflow: hidden;
`;

const CompanyTitle = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
  gap: 0.5rem;

  & .socials {
    grid-column: span 3;
  }
`;

const MainPanel = styled('div')<{ fullWidth?: boolean }>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '4fr 3fr')};
  grid-gap: 1rem;
  overflow: auto;

  @container (width < ${ContainerBreakpoint}px) {
    grid-template-columns: 1fr;
  }
`;

const ActionsContainer = styled('div')`
  display: flex;
  align-items: start;
  gap: 1rem;

  @container (width < ${ContainerBreakpoint}px) {
    flex-direction: column;
    align-items: unset;
  }
`;

const disabledIconStyle = { fill: colors.neutral[30] };

export interface IListItemDetailsProps {
  item: ListItemType;
  gotoNext: () => void;
}

export function ListItemDetailsPanel(props: IListItemDetailsProps) {
  const { showListDetailsAsPanel } = useFlags<MaggieFeatureFlags>();
  const theme = useTheme();
  const addToPipeline = useAddListItemToPipeline();
  const { handleChangeHideStatus, handleUntrackCompany } = useCompanyActions();
  const companyId = props.item.companyId;
  const company = useRecoilValue(companyState(companyId));
  const { item, gotoNext } = props;
  const description = getCompanyDescription(company);
  const currentUser = useCurrentUser();
  const isPassed = item.actionType === 'hidden';
  const isTracked = Boolean(company) && isFollowedByUser(company!, currentUser.id);

  const activeList = useRecoilValue(activeListState);
  const isTrackingList = activeList?.name === 'Tracked Companies';

  const filteredSocials = useMemo(() => {
    if (!company?.socials) return [];
    return company.socials.filter(
      (s) => s.url.includes('linkedin') || s.url.includes('crunchbase') || s.url.includes('facebook')
    );
  }, [company]);

  const [showTrackModal, setShowTrackModal] = useState(false);
  const onCloseTrackModel = () => setShowTrackModal(false);

  const togglePass = useCallback(() => {
    handleChangeHideStatus({
      action: !isPassed,
      listItem: item,
      userId: currentUser.id,
    });
    if (!isPassed) gotoNext();
  }, [currentUser.id, gotoNext, handleChangeHideStatus, isPassed, item]);

  const doAddToPipeline = useCallback(() => {
    if (!company) return;
    addToPipeline(item, company);
    gotoNext();
  }, [addToPipeline, company, gotoNext, item]);

  const actions = useMemo(() => {
    if (!company) return null;

    if (activeList?.type !== ListType.DISCOVERY) {
      if (isTrackingList) {
        return <TrackedListActions company={company} />;
      } else {
        return <></>;
      }
    }

    const allItemActions = getAllItemActionsByPriority(item, company, currentUser.id);
    const strongestAction = allItemActions[0];
    if (strongestAction === 'pipeline') {
      return (
        <UndoButton
          hasUndo={false}
          disabled
          startIcon={<Add style={isTracked ? disabledIconStyle : undefined} />}
        >
          Added to Pipine
        </UndoButton>
      );
    }
    if (strongestAction === 'hidden') {
      return (
        <UndoButton
          hasUndo={isPassed}
          startIcon={<ThumbDownIconOutlined style={disabledIconStyle} />}
          onClick={togglePass}
        >
          Passed
        </UndoButton>
      );
    }
    if (strongestAction === 'followedByUser') {
      return (
        <UndoButton
          hasUndo
          startIcon={<HourglassEmptyIcon style={disabledIconStyle} />}
          onClick={async () => {
            await handleUntrackCompany(company);
            setShowTrackModal(false);
          }}
        >
          Untrack
        </UndoButton>
      );
    }

    return (
      <>
        <Button
          color='secondary'
          variant='outlined'
          startIcon={<ThumbDownIconOutlined />}
          onClick={togglePass}
        >
          Pass
        </Button>

        <Button
          color='secondary'
          variant='outlined'
          startIcon={<HourglassEmptyIcon style={isTracked ? disabledIconStyle : undefined} />}
          onClick={() => setShowTrackModal(true)}
        >
          Track
        </Button>

        <Button variant='outlined' color={'secondary'} startIcon={<AddIcon />} onClick={doAddToPipeline}>
          Add to pipeline
        </Button>
      </>
    );
  }, [
    company,
    activeList?.type,
    item,
    currentUser.id,
    togglePass,
    isTracked,
    doAddToPipeline,
    isTrackingList,
    isPassed,
    handleUntrackCompany,
  ]);

  if (!company || !item || !activeList) return null;

  return (
    <ListItemDetailsWrapper>
      <TrackCompanyModal
        open={showTrackModal}
        onSubmit={onCloseTrackModel}
        onClose={onCloseTrackModel}
        company={company}
      />
      <ActionsContainer>
        <CompanyTitle>
          <MUIAvatar src={company.logoUrl} nameParts={company.name.split(/\s+?/)} />
          <Typography variant='h6'>{company.name}</Typography>

          <Tooltip
            title='Open profile'
            componentsProps={{
              tooltip: { sx: { color: theme.colors.neutral[60] } },
            }}
          >
            <IconButton
              onClick={() => window.open(`/${ROUTES.COMPANY_PROFILES}/${company.name}-${company.id}/summary`)}
            >
              <OpenInNewIcon color={'secondary'} fontSize='small' />
            </IconButton>
          </Tooltip>
          <Stack direction='row' className='socials'>
            <CompanySocialMedia socials={filteredSocials} />
          </Stack>
        </CompanyTitle>

        {actions}
      </ActionsContainer>
      <Typography component='div' variant='body2'>
        {description}
      </Typography>
      <MainPanel>
        {showListDetailsAsPanel ? (
          <CompanyDetails2 company={company} activeList={activeList} />
        ) : (
          <CompanyDetails company={company} activeList={activeList} />
        )}
        <ItemCommentsSection item={item} />
      </MainPanel>
    </ListItemDetailsWrapper>
  );
}
