import { css } from '@emotion/react';
import { Clear } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { ContainerWithVerticalScrollShadow } from '../../../components/Form/FormComponents';
import { BasicDragHandle, SortableItem } from '../../../components/SortableList/SortableItem';
import { SortableList } from '../../../components/SortableList/SortableList';
import { kpiConfigByIdMapAtom } from '../../../services/state/KPIConfigState';
import { cardStyles } from '../../../theme/component-styles';
import { MprColumnSortType } from './MultiPeriodReporting.schemas';
import { getColumnConfigKey, getFormattedConfigPeriod, IMprColumnConfig } from './multiPeriodReportingUtils';
import { useMprColumnConfigs } from './useMprColumnConfigs';

const listHeight = '54dvh';
const formHeight = `calc(${listHeight} + 3rem)`;
const ListContainer = css`
  margin-top: 0.5rem;
  height: ${listHeight};
  max-height: ${listHeight};
`;
const ConfigCard = css({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr 4rem 9rem auto',
  columnGap: '1rem',
  alignItems: 'center',
  ...cardStyles,
  padding: '0rem 1rem',
});

interface ConfigWithId extends IMprColumnConfig {
  id: string;
}

export function MprColumnConfigList() {
  const { setDraftColumnConfigs, draftColumnConfigs, setDraftColumnSortType, draftColumnSortType } =
    useMprColumnConfigs();
  const onDelete = useCallback(
    (config: ConfigWithId) => {
      setDraftColumnConfigs((prev) =>
        prev.filter((c) => getColumnConfigKey(c) !== getColumnConfigKey(config))
      );
    },
    [setDraftColumnConfigs]
  );

  const items: ConfigWithId[] = useMemo(
    () => draftColumnConfigs.map((c) => ({ ...c, id: getColumnConfigKey(c) })),
    [draftColumnConfigs]
  );
  const onChange = useCallback(
    (reorderedItems: ConfigWithId[]) => {
      setDraftColumnConfigs(() =>
        reorderedItems.map(({ id, ...config }, i) => ({ ...config, sortOrder: i }))
      );
    },
    [setDraftColumnConfigs]
  );

  const onSortTypeChange = useCallback(
    (type: MprColumnSortType | null) => {
      setDraftColumnSortType(type);
    },
    [setDraftColumnSortType]
  );

  if (draftColumnConfigs.length === 0) {
    return (
      <Typography variant='body2' color='text.secondary' textAlign={'center'} py={'3rem'} height={formHeight}>
        No columns selected
      </Typography>
    );
  }

  return (
    <Box minHeight={formHeight}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <ColumnSortTypeToggle value={draftColumnSortType} onChange={onSortTypeChange} />
        <Button color='secondary' onClick={() => onChange([])}>
          Remove All
        </Button>
      </Stack>
      <ContainerWithVerticalScrollShadow css={ListContainer}>
        <SortableList
          items={items}
          onChange={onChange}
          renderItem={(item) => (
            <SortableItem id={item.id}>
              <ColumnConfigListItem
                config={item}
                onDelete={onDelete}
                disableDrag={draftColumnSortType != null}
              />
            </SortableItem>
          )}
        />
      </ContainerWithVerticalScrollShadow>
    </Box>
  );
}

function ColumnConfigListItem({
  config,
  onDelete,
  disableDrag,
}: {
  config: ConfigWithId;
  onDelete: (config: ConfigWithId) => void;
  disableDrag?: boolean;
}) {
  const kpiConfigsById = useAtomValue(kpiConfigByIdMapAtom);

  return (
    <>
      <Typography component='div' variant='body2' key={getColumnConfigKey(config)} css={ConfigCard}>
        <BasicDragHandle disabled={disableDrag} />
        <div>{kpiConfigsById.get(config.kpiId!)?.displayName}</div>
        <div>{capitalize(config.section)}</div>
        <div>{getFormattedConfigPeriod(config)}</div>
        <IconButton onClick={() => onDelete(config)} sx={{ justifySelf: 'end' }}>
          <Clear fontSize='small' />
        </IconButton>
      </Typography>
    </>
  );
}

interface IColumnSortTypeToggleProps {
  onChange: (type: MprColumnSortType | null) => void;
  value: MprColumnSortType | null;
}
function ColumnSortTypeToggle({ value, onChange }: IColumnSortTypeToggleProps) {
  return (
    <ToggleButtonGroup
      id='column-sort-type'
      color='secondary'
      size='small'
      value={value ?? 'custom'}
      exclusive
      onChange={(e, newValue: MprColumnSortType | 'custom' | null) => {
        if (newValue == null) return;
        onChange(newValue == 'custom' ? null : newValue);
      }}
    >
      <ToggleButton value={MprColumnSortType.dateAsc}>Sort by periodicity/date</ToggleButton>
      <ToggleButton value={MprColumnSortType.kpi}>Sort by KPI name</ToggleButton>
      <ToggleButton value={'custom'}>Custom</ToggleButton>
    </ToggleButtonGroup>
  );
}
