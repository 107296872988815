import { BarSeriesOption } from 'echarts';
import { EChartWrapper } from '../../../../../components/Charts/EchartWrapper';
import {
  KPIRequestStatus,
  kpiRequestStatusToColor,
} from '../../../../../data-models/kpi-requests.data-model';
import { FMT } from '../../../../../util/formatter-service';
import { KpiStatusOrder } from './KpiRequestManage';

type KpiRequestChartProps = {
  data: Map<string, number>;
  selectedStatus: KPIRequestStatus | string;
};

const BarBorderRadius = 5;

export function KpiRequestChart({ data, selectedStatus }: KpiRequestChartProps) {
  const series: BarSeriesOption[] = [];
  const values = [] as number[][];
  let total = 0;

  const keysSorted = Array.from(data.keys()).sort((status1, status2) => {
    return (
      KpiStatusOrder.indexOf(status1 as KPIRequestStatus) -
      KpiStatusOrder.indexOf(status2 as KPIRequestStatus)
    );
  });

  keysSorted.forEach((key) => {
    const value = data.get(key) || 0;
    values.push([value]);
    total += value;
  });

  keysSorted.forEach((status, seriesIndex) => {
    const percentValue = Math.round((values[seriesIndex][0] / total) * 100);
    const seriesName = FMT.format('kpiStatus', status as KPIRequestStatus);

    let borderStyle = [0, 0, 0, 0];
    if (seriesIndex === 0) {
      borderStyle = [BarBorderRadius, 0, 0, BarBorderRadius];
    } else if (seriesIndex === data.size - 1) {
      borderStyle = [0, BarBorderRadius, BarBorderRadius, 0];
    }

    let barColor = kpiRequestStatusToColor(status as KPIRequestStatus);
    if (selectedStatus !== 'All' && selectedStatus !== status) {
      barColor = 'rgba(0, 0, 0, 0.1)';
    }

    series.push({
      name: seriesName,
      data: [percentValue],
      emphasis: {
        disabled: true,
      },
      label: {
        show: true,
        formatter: (params) => `${FMT.format('percentRound', params.value)}`,
      },
      itemStyle: {
        borderRadius: borderStyle,
      },
      color: barColor,
      type: 'bar',
      stack: 'total',
    });
  });

  return (
    <EChartWrapper
      notMerge={true}
      options={{
        grid: {
          left: 5,
          right: 5,
          top: 0,
          bottom: 0,
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        yAxis: {
          type: 'category',
          data: ['total'],
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        series,
      }}
    />
  );
}
