import { useAtom } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { selectedFundStateFP, showFundFormsState, useResetFundFormState } from '../state/FPState';
import { FundCapitalAllocationForm } from './Allocation/FundCapitalAllocationForm';
import { FundWaterfallForm, IFundFormProps } from './FundWaterfallForm';
import { FundWaterfallForm2 } from './FundWaterfallForm2';

export function FundFormModal({ defaultValues, onSubmit }: Omit<IFundFormProps, 'onCancel'>) {
  const fund = useRecoilValue(selectedFundStateFP);
  const [showForm, setShowForm] = useAtom(showFundFormsState);
  const resetFormState = useResetFundFormState();
  const { showFundWaterfallForm2 } = useFlags<MaggieFeatureFlags>();

  const onClose = useCallback(() => {
    setShowForm(null);
    resetFormState();
  }, [resetFormState, setShowForm]);

  const title =
    defaultValues?.name &&
    `${defaultValues.name} ${showForm === 'waterfall' ? 'Waterfall' : 'Allocation'} Settings`;

  if (!fund) return null;

  return (
    <BasicDialog open={showForm != null} title={title} onClose={onClose}>
      {showForm === 'waterfall' &&
        (!showFundWaterfallForm2 ? (
          <FundWaterfallForm2 defaultValues={defaultValues} onCancel={onClose} onSubmit={onSubmit} />
        ) : (
          <FundWaterfallForm defaultValues={defaultValues} onSubmit={onSubmit} onCancel={onClose} />
        ))}
      {showForm === 'allocation' && (
        <FundCapitalAllocationForm defaultValues={defaultValues} onSubmit={onSubmit} />
      )}
    </BasicDialog>
  );
}
