import { css } from '@emotion/react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { subMonths } from 'date-fns';
import { noop } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { DefaultShortcuts } from '../../../../../components/DatePicker/RangeShortcuts';
import { FieldSelect } from '../../../../../components/Form/Display/FieldSelect';
import { createCompanyDataModel, ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { RendererType } from '../../../../../data-models/field.data-model';
import {
  IKPIRequestDataModel,
  KPIRequestStatus,
  kpiRequestStatusToColor,
} from '../../../../../data-models/kpi-requests.data-model';
import { kpisRequestsListState } from '../../../../../services/state/KPI/KPIRequestsState';
import { portCosState } from '../../../../../services/state/PortCosState';
import { colors } from '../../../../../theme/colors';
import { FMT } from '../../../../../util/formatter-service';
import { createFormField } from '../../../../../view-models/form.view-model';
import { DateRangeSelector } from '../../../../Finance/form-components/DateRangeSelector';
import { KpiRequestManageViewModel } from './kpi-manage.view-model';
import { KpiRequestChart } from './KpiManageChart';
import { KpiManageGrid } from './KpiManageGrid';

const wrapperCss = css`
  display: grid;
  grid-template-rows: min-content 80px 1fr;
  height: 100%;
  padding: 1rem;
  row-gap: 0.5rem;
  width: 100%;
`;

const kpiStatusMenuBarWrapperCss = css`
  align-items: center;
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 200px 1fr min-content;
  width: 100%;
`;

const statusButtonColorCircle = css`
  border-radius: 50%;
  height: 0.5rem;
  margin-left: 0.5rem;
  width: 0.5rem;
`;

export const KpiStatusOrder = [
  KPIRequestStatus.NotSent,
  KPIRequestStatus.Sent,
  KPIRequestStatus.Submitted,
  KPIRequestStatus.Rejected,
  KPIRequestStatus.Accepted,
  KPIRequestStatus.Archived,
];

export function KpiRequestManageLoader() {
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>(undefined);
  const [selectedDates, setSelectedDates] = useState<[Date, Date]>(() => {
    const now = new Date();
    return [subMonths(now, 9), now];
  });

  const kpiRequests = useRecoilValue(kpisRequestsListState);
  const portcos = useRecoilValue(portCosState);

  const onDateRangeChange = (from: Date | null, to: Date | null) => {
    if (from && to) {
      setSelectedDates([from, to]);
    }
  };
  const { includedCompanies, requests, requestStats } = useMemo(() => {
    if (kpiRequests && portcos) {
      return toKpiRequestsViewModel(
        kpiRequests,
        portcos,
        selectedDates[0],
        selectedDates[1],
        selectedCompanyId
      );
    }
    return { includedCompanies: new Map<number, string>(), requests: [], requestStats: new Map() };
  }, [kpiRequests, portcos, selectedCompanyId, selectedDates]);

  return (
    <KpiRequestManage
      onDateRangeChange={onDateRangeChange}
      includedCompanies={includedCompanies}
      onSelectedCompanyChange={setSelectedCompanyId}
      requests={requests}
      requestStats={requestStats}
      selectedCompanyId={selectedCompanyId}
    />
  );
}

type KpiManageMenuBarProps = {
  includedCompanies: Map<number, string>;
  onDateRangeChange: (from: Date | null, to: Date | null) => void;
  onSelectedStatusChange: (status: string) => void;
  onSelectedCompanyChange: (companyId: number | undefined) => void;
  requestStats: KpiRequestManageData['requestStats'];
  selectedCompanyId: number | undefined;
  selectedStatus: string;
};

function KpiManageMenuBar({
  onDateRangeChange,
  onSelectedCompanyChange,
  onSelectedStatusChange,
  requestStats,
  selectedStatus,
  selectedCompanyId,
  includedCompanies,
}: KpiManageMenuBarProps) {
  const initialDates = useMemo(() => {
    const now = new Date();
    return {
      from: subMonths(now, 3),
      to: now,
    };
  }, []);

  const _onDateRangeChange = (from: Date | null, to: Date | null) => {
    onSelectedStatusChange('All');
    onSelectedCompanyChange(undefined);
    onDateRangeChange(from, to);
  };

  const onChangeSelectedStatus = (event: React.MouseEvent<HTMLElement>, newStatus: string) => {
    onSelectedStatusChange(newStatus);
  };

  const statusToggles = KpiStatusOrder.map((status) => {
    const disabled = !requestStats.has(status);

    return (
      <ToggleButton
        key={status}
        value={status}
        disabled={disabled}
        sx={{ backgroundColor: disabled ? 'rgba(0, 0, 0, 0.1)' : '' }}
      >
        <span>{FMT.format('kpiStatus', status)}</span>
        <div
          css={statusButtonColorCircle}
          style={{ backgroundColor: disabled ? colors.neutral[30] : kpiRequestStatusToColor(status) }}
        />
      </ToggleButton>
    );
  });
  statusToggles.unshift(<ToggleButton value={'All'}>All</ToggleButton>);

  return (
    <div css={kpiStatusMenuBarWrapperCss}>
      <CompanySelect
        includedCompanies={includedCompanies}
        selectedCompanyId={selectedCompanyId}
        onCompanySelected={onSelectedCompanyChange}
      />
      <ToggleButtonGroup value={selectedStatus} onChange={onChangeSelectedStatus} exclusive fullWidth>
        {statusToggles}
      </ToggleButtonGroup>
      <DateRangeSelector
        onDateRangeChange={_onDateRangeChange}
        setSelectedStartingDate={noop}
        setSelectedEndingDate={noop}
        textContent={(selectedStartingDate, selectedEndingDate) =>
          selectedStartingDate && selectedEndingDate
            ? `${FMT.format('dateNumeric', selectedStartingDate)} - ${FMT.format(
                'dateNumeric',
                selectedEndingDate
              )}`
            : 'Select Date Range'
        }
        style={{ justifySelf: 'end' }}
        initialStartDate={initialDates.from}
        initialEndDate={initialDates.to}
        shortcuts={DefaultShortcuts}
      />
    </div>
  );
}

type KpiRequestManageProps = KpiRequestManageData & {
  onDateRangeChange: (from: Date | null, to: Date | null) => void;
  onSelectedCompanyChange: (companyId: number | undefined) => void;
  selectedCompanyId: number | undefined;
};

function KpiRequestManage(props: KpiRequestManageProps) {
  const {
    includedCompanies,
    requests,
    requestStats,
    onDateRangeChange,
    onSelectedCompanyChange,
    selectedCompanyId,
  } = props;
  const [selectedStatus, setSelectedStatus] = useState('All');

  return (
    <div css={wrapperCss}>
      <KpiManageMenuBar
        includedCompanies={includedCompanies}
        onDateRangeChange={onDateRangeChange}
        onSelectedCompanyChange={onSelectedCompanyChange}
        onSelectedStatusChange={setSelectedStatus}
        requestStats={requestStats}
        selectedCompanyId={selectedCompanyId}
        selectedStatus={selectedStatus}
      />
      <div>
        <KpiRequestChart data={requestStats} selectedStatus={selectedStatus} />
      </div>
      <div style={{ backgroundColor: 'white', borderRadius: '0.5rem' }}>
        <KpiManageGrid requests={requests} selectedStatus={selectedStatus} />
      </div>
    </div>
  );
}

function CompanySelect({
  selectedCompanyId,
  includedCompanies,
  onCompanySelected,
}: {
  selectedCompanyId: number | undefined;
  includedCompanies: Map<number, string>;
  onCompanySelected: (companyId: number | undefined) => void;
}) {
  const options = useMemo(() => {
    return Array.from(includedCompanies.entries()).map(([id, name]) => ({
      value: id,
      displayName: name,
    }));
  }, [includedCompanies]);

  const field = useMemo(
    () =>
      createFormField({
        key: 'company',
        label: 'company',
        renderer: RendererType.singleSelect,
        fullWidth: true,
        placeholder: 'Select Company',
        rendererMeta: {
          values: options,
        },
      }),
    [options]
  );

  return (
    <FieldSelect
      formField={field}
      formProps={{
        name: 'company',
        onBlur: noop,
        value: selectedCompanyId,
        onChange: (value) => {
          onCompanySelected(value);
        },
        ref: noop,
      }}
    />
  );
}

function toKpiRequestsViewModel(
  requests: IKPIRequestDataModel[],
  companies: ICompanyDataModel[],
  from: Date,
  to: Date,
  selectedCompanyId: number | undefined
) {
  const includedCompanies = new Map<number, string>();
  const companiesById = companies.reduce(
    (acc, company) => {
      acc[company.id] = company;
      return acc;
    },
    {} as Record<number, ICompanyDataModel>
  );

  const res: KpiRequestManageViewModel[] = [];
  const requestStats: Map<KPIRequestStatus | string, number> = new Map();
  const dummyCompany = createCompanyDataModel();

  const requestForPeriod = requests.filter((request) => {
    return (
      request.period == undefined ||
      (request.period >= from.toISOString() && request.period <= to.toISOString())
    );
  });

  requestForPeriod.forEach((request) => {
    const { name } = companiesById[request.companyId] ?? dummyCompany;
    includedCompanies.set(request.companyId, name);
  });

  requestForPeriod
    .filter((request) => selectedCompanyId == undefined || request.companyId === selectedCompanyId)
    .forEach((request) => {
      const { kpiRequestResponse, ...rest } = request;
      const { name, sectorId } = companiesById[request.companyId] ?? dummyCompany;
      const statusCount = requestStats.get(request.status) ?? 0;
      requestStats.set(request.status, statusCount + 1);

      res.push({
        ...rest,
        companyName: name,
        fye: request.fye ?? null,
        granularity: request.granularity ?? undefined,
        sectorId,
        responseDate: kpiRequestResponse?.respondedAt ?? undefined,
      });
    });

  return { includedCompanies, requests: res, requestStats };
}

type KpiRequestManageData = Awaited<ReturnType<typeof toKpiRequestsViewModel>>;
