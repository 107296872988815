import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { useAtomValue } from 'jotai';
import { fundsAtom } from '../../../services/state/AppConfigStateJ';
import { ConfigRowFieldWrapperClass } from '../Common/ConfigItemRow';
import { GenericConfigPage } from '../Common/GenericConfigPage';
import { getFundFields } from './FundFields';

const FundRowStyles = css`
  & .${ConfigRowFieldWrapperClass} {
    display: grid;
    grid-template-columns: 14rem 14rem repeat(auto-fit, 8rem);
  }
`;

export function ConfigFund() {
  const funds = useAtomValue(fundsAtom);

  return (
    <Box css={FundRowStyles} width={'100%'}>
      <GenericConfigPage
        baseTitle={'Fund'}
        data={funds}
        fields={getFundFields()}
        pageTitle={'Funds'}
        queryURL={'/fund'}
      />
    </Box>
  );
}
