import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { ColumnMeta, PredefinedColumn } from '../../../../../../../types';
import { IInvestmentDataModel } from '../../../../../../../data-models/investment.data-model';
import { CompanyIdCellRenderer } from '../../../../../../../components/grid-renderers/CompanyCellRenderer';

export class CompanyRT implements PredefinedColumn {
  public static NAME = 'Company Name';

  getMeta() {
    return this.meta;
  }

  getDefinition() {
    return this.definition;
  }

  private meta: ColumnMeta = {
    aggFunc: null,
    aggregation: false,
    createdAt: '',
    createdBy: null,
    default: false,
    description: '',
    displayName: 'Company',
    editable: false,
    exportable: true,
    filterable: true,
    formatting: 'text',
    groupName: 'Company Details',
    groupOrder: 1,
    groupPriority: 1,
    groupable: true,
    id: Math.ceil(Math.random() * 1000),
    name: CompanyRT.NAME,
    options: '',
    path: 'company',
    required: true,
    sortable: true,
    tableOrder: 1,
    type: 'string',
    updatedAt: '',
    updatedBy: null,
    visible: false,
  };

  private definition: ColDef<IInvestmentDataModel> = {
    cellRenderer: CompanyIdCellRenderer,
    cellRendererParams: {
      displayLogoInGroupRow: true,
    },
    colId: this.meta.name,
    headerName: this.meta.displayName,
    enableRowGroup: true,
    hide: true,
    initialRowGroup: true,
    rowGroupIndex: 0,
    valueFormatter: (params: ValueFormatterParams<IInvestmentDataModel>) => {
      return params.data?.company ?? '';
    },
    valueGetter: (params: ValueGetterParams<IInvestmentDataModel>) => {
      return params.data?.companyId;
    },
    filterValueGetter: (params: ValueGetterParams<IInvestmentDataModel>) => {
      return params.data?.company;
    },
    getQuickFilterText(params) {
      return params.data?.company ?? '';
    },
    keyCreator: (params) => params.data?.company ?? '',
    comparator: (valueA, valueB, nodeA, nodeB) => {
      if (nodeA?.data?.company && nodeB?.data?.company) {
        return nodeA.data.company.localeCompare(nodeB.data.company);
      } else if (nodeA?.key && nodeB?.key) {
        return nodeA.key.localeCompare(nodeB.key);
      }
      return (valueA ?? Number.MAX_SAFE_INTEGER) - (valueB ?? Number.MAX_SAFE_INTEGER);
    },
  };
}
