import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { ROUTE_TITLE_MAPPING, ROUTES } from '../../../constants/RouteNameMapping';
import { useTitle } from '../../../hooks/useTitle';
import { useParseCompanyIdFromPath } from '../../../pages/CompanyProfiles/utils/useParseCompanyIdFromPath';
import { selectedViewPF } from '../../../pages/PortfolioOverview/state/ViewState';
import { companyState } from '../../../services/state/CompanyState';
import { useRouteBasedFinanceTitles } from '../../../pages/Finance2/useRouteBasedFinanceTitles';

export const useBreadCrumbs = () => {
  const location = useLocation();
  const url = location.pathname;
  const label = useTitle();
  const FinTitles = useRouteBasedFinanceTitles();

  const companyProfilesBreadcrumbs = useMemo(() => {
    if (!url.startsWith(`/${ROUTES.COMPANY_PROFILES}`)) {
      return null;
    }
    return [
      {
        label,
      },
      { label: <CompanyNameBreadCrumb /> },
    ];
  }, [url, label]);

  const adminPanelConfigCrumbs = useMemo(() => {
    if (!url.startsWith(`/${ROUTES.ADMIN_PANEL}/${ROUTES.CONFIG}`)) {
      return null;
    }

    const subSections = url.split('/');
    const crumbs = [{ label }];
    if (subSections[2]) crumbs.push({ label: ROUTE_TITLE_MAPPING[subSections[2]] });
    if (subSections[3]) crumbs.push({ label: ROUTE_TITLE_MAPPING[subSections[3]] });
    return crumbs;
  }, [label, url]);

  const integrationsSubSections = useMemo(() => {
    if (!url.startsWith(`/${ROUTES.INTEGRATIONS}`)) return null;
    const subSections = url.split('/');
    const result = [];
    result.push({
      label: ROUTE_TITLE_MAPPING[ROUTES.INTEGRATIONS],
    });
    for (const subsection of subSections.slice(2)) {
      result.push({
        url: `/${ROUTES.INTEGRATIONS}/${subsection}`,
        label: ROUTE_TITLE_MAPPING[subsection.toLocaleLowerCase()],
      });
    }
    return result;
  }, [url]);

  const mappedUrl = useMemo(() => {
    const splitUrl = url.split('/')[1];
    if (label === ROUTE_TITLE_MAPPING[ROUTES.COMPANIES]) return `/${ROUTES.COMPANIES}`;
    else if (splitUrl === ROUTES.FINANCE2) return `${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}`;

    return `/${splitUrl}`;
  }, [label, url]);

  const lastLabel = useMemo(() => {
    if (url.startsWith('/company-comparison/ci-report')) return 'Competitive Intelligence';

    if (url.startsWith('/company-comparison/ga-report')) return 'Growth Analysis';

    if (url.startsWith('/portfolio')) return <PortfolioBreadCrumb />;

    if (url.startsWith(`/${ROUTES.COMPANY_PROFILES}`)) {
      const paths = url.split('/');

      return ROUTE_TITLE_MAPPING[paths[paths.length - 1]] ?? '';
    }

    if (url.startsWith(`/${ROUTES.ADMIN_PANEL}`)) {
      const subtitle = ROUTE_TITLE_MAPPING[url.split('/')[2]];
      if (subtitle) return subtitle;
    }

    if (url.startsWith(`/${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}/new`)) {
      return 'New Template';
    }

    if (url.startsWith(`/${ROUTES.DEAL_FLOW}/${ROUTES.DEAL_ANALYTICS}`)) {
      return 'Analytics';
    }

    if (url.startsWith(`/${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`)) {
      return ROUTE_TITLE_MAPPING[ROUTES.KPI_REQUESTS];
    }

    if (url.startsWith(`/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`)) {
      return ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES];
    }

    if (url.startsWith(`/${ROUTES.KPI}/${ROUTES.KPI_TEMPLATES}`)) {
      return ROUTE_TITLE_MAPPING[ROUTES.KPI_TEMPLATES];
    }

    if (url.startsWith(`/${ROUTES.KPI}/${ROUTES.KPI_MANAGE}`)) {
      return ROUTE_TITLE_MAPPING[ROUTES.KPI_MANAGE];
    }

    if (url.startsWith(`/${ROUTES.KPI}/${ROUTES.COMPANIES}`)) {
      return 'Companies';
    }

    if (url.startsWith(`/${ROUTES.DEAL_FLOW}`)) {
      let lastSection = url.split('/')[3];

      if (lastSection === 'current' || !lastSection) {
        return 'Active Deals';
      } else if (!lastSection.endsWith('ed')) {
        lastSection += 'ed';
      }

      return (
        lastSection.substring(0, 1).toUpperCase() + lastSection.substring(1, lastSection.length) + ' Deals'
      );
    }

    if (url.startsWith(`/${ROUTES.FINANCE2}`)) {
      if (url.includes(ROUTES.FINANCE_RECON)) {
        return FinTitles.recon;
      } else if (url.includes(ROUTES.FINANCE_ALL_SECURITIES)) {
        return FinTitles.securities;
      } else {
        const subSection = url.split('/')[4];
        return ROUTE_TITLE_MAPPING[subSection] ?? FinTitles.main;
      }
    }
    return 'Overview';
  }, [FinTitles.main, FinTitles.recon, FinTitles.securities, url]);

  if (companyProfilesBreadcrumbs) return [...companyProfilesBreadcrumbs, { label: lastLabel }];
  if (integrationsSubSections) return [...integrationsSubSections];
  if (adminPanelConfigCrumbs) return [...adminPanelConfigCrumbs];

  if (url.startsWith(`/${ROUTES.DEAL_FLOW}`)) return [{ label }, { label: lastLabel }];
  if (url.startsWith(`/${ROUTES.FUND_PROFILES}`)) return [];

  return [{ url: mappedUrl, label }, { label: lastLabel }];
};

// separate the portfolio breadcrumb component to avoid pulling view data from any module but the Portfolio one
const PortfolioBreadCrumb = () => {
  const view = useRecoilValue(selectedViewPF)?.label;
  if (!view) return null;
  else return <>{view}</>;
};

const CompanyNameBreadCrumb = () => {
  const companyId = useParseCompanyIdFromPath();
  const activeCompany = useRecoilValue(companyState(companyId));

  return <>{activeCompany?.name ?? ''}</>;
};
