import { useCallback, useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { merge } from 'lodash-es';
import { ObjectSchema } from 'yup';
import {
  fromFundViewModel,
  fundCapitalAllocationSchemaStep2,
  FundViewModel,
} from '../../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../../util/schema-utils';
import { FormContainer } from '../../../../components/Form/FormComponents';
import { fundFormCurrentStepState, fundFormState, showFundFormsState } from '../../state/FPState';
import { StepperFormButtons } from '../../../Finance2/Forms/StepperFormButtons';
import { IFundFormProps } from '../FundWaterfallForm';
import { FDMap } from '../../../../util/data-structure/FDMap';
import { createForm } from '../../../../view-models/form.view-model';
import { FormFactoryWithStandardLayout } from '../../../../components/Form/FormFactory';
import { Fund } from '../../../../schemas/Fund.schema';
import { getPlainDateString } from '../../../../services/queries/MaggieMetricsQueries';

interface IFundFormStep2Props extends Pick<IFundFormProps, 'onSubmit'> {}

export function AllocationStep2({ onSubmit }: IFundFormStep2Props) {
  const [formData, setFormData] = useAtom(fundFormState);
  const setCurrentStep = useSetAtom(fundFormCurrentStepState);
  const setShowForm = useSetAtom(showFundFormsState);

  const fields = useMemo(() => {
    return schemaToFormFields(fundCapitalAllocationSchemaStep2(), [
      'investingWindowInceptionDate',
      'initialInvestingWindowCloseDate',
      'followOnInvestingWindowCloseDate',
      'managementFeeTerminationDate',
    ]);
  }, []);

  const form = createForm({ fields: FDMap.fromArray(fields, 'key') });

  const methods = useForm<FundViewModel>({
    defaultValues: formData as FundViewModel,
    resolver: yupResolver(fundCapitalAllocationSchemaStep2() as unknown as ObjectSchema<FundViewModel>),
    mode: 'all',
  });

  const handleBack = useCallback(() => {
    setFormData((curr) => ({ ...curr, ...methods.getValues() }));
    setCurrentStep((curr) => curr - 1);
  }, [methods, setCurrentStep, setFormData]);

  const _onSubmit = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const payload = prepareAllocationPayload(fromFundViewModel(merge({}, formData, methods.getValues())));
      await onSubmit(payload);
      setShowForm(null);
      return true;
    } else {
      return false;
    }
  }, [methods, formData, onSubmit, setShowForm]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <FormFactoryWithStandardLayout form={form} />
      </FormContainer>
      <StepperFormButtons
        stepIsValid={_onSubmit}
        handleGoBack={handleBack}
        incrementOnNext={false}
        nextButtonLabel='Save Changes'
      />
    </FormProvider>
  );
}

function prepareAllocationPayload(fund: Partial<Fund>) {
  return {
    committedCapital: fund.committedCapital,
    feesAndExpensesAllocationPercentage: fund.feesAndExpensesAllocationPercentage,
    followOnInvestingWindowCloseDate: fund.followOnInvestingWindowCloseDate
      ? getPlainDateString(new Date(fund.followOnInvestingWindowCloseDate))
      : null,
    id: fund.id,
    initialInvestmentAllocationPercentage: fund.initialInvestmentAllocationPercentage,
    initialInvestingWindowCloseDate: fund.initialInvestingWindowCloseDate
      ? getPlainDateString(new Date(fund.initialInvestingWindowCloseDate))
      : null,
    investingWindowInceptionDate: fund.investingWindowInceptionDate
      ? getPlainDateString(new Date(fund.investingWindowInceptionDate))
      : null,
    managementFeeTerminationDate: fund.managementFeeTerminationDate
      ? getPlainDateString(new Date(fund.managementFeeTerminationDate))
      : null,
    recyclingLimitPercentage: fund.recyclingLimitPercentage,
    reservesAllocationPercentage: fund.reservesAllocationPercentage,
  };
}
