import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import {
  ICompanyFinancialsDataModel,
  KpiPeriod,
  KpiSection,
} from '../../../data-models/company-financials.data-model';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import { kpiConfigByIdMapAtom } from '../../../services/state/KPIConfigState';
import { IMprColumnConfig } from './multiPeriodReportingUtils';

export interface IMultiPeriodReportingDataPoint
  extends Pick<ICompanyFinancialsDataModel, 'date' | 'kpiId' | 'period' | 'section'> {}

export interface IMultiPeriodReportingRequest {
  dataPoints: IMultiPeriodReportingDataPoint[];
  isDateAdjusted: boolean;
  companyIds?: number[];
}

export const ReportingPeriodType = {
  absolute: 'absolute',
  relative: 'relative',
} as const;
export type ReportingPeriod = (typeof ReportingPeriodType)[keyof typeof ReportingPeriodType];

export function useMultiReportingColumnsSchema() {
  const kpiConfigById = useAtomValue(kpiConfigByIdMapAtom);
  const sections = Object.values(KpiSection);
  const kpiFields = useCallback(
    () => ({
      kpiId: yup
        .array()
        .of(yup.number())
        .min(1)
        .label('KPI')
        .nullable()
        .required()
        .default([])
        .formMeta({
          renderer: RendererType.multiSelect,
          rendererMeta: {
            values: [...kpiConfigById.values()].map((kpi) => ({
              value: kpi.id,
              displayName: kpi.displayName,
            })),
          },
        }),
      section: yup
        .array()
        .of(yup.string().oneOf(sections))
        .min(1)
        .nullable()
        .required()
        .default([KpiSection.actual])
        .formMeta({
          renderer: RendererType.multiSelect,
          rendererMeta: {
            values: sections.map((section) => ({
              value: section,
              displayName: section,
            })),
          },
        }),
    }),
    [kpiConfigById, sections]
  );

  return useMemo(() => yup.object({ ...kpiFields(), ...mprPeriodFields() }), [kpiFields]);
}

export function mprPeriodFields() {
  const periodTypes = Object.values(ReportingPeriodType);
  const kpiPeriods = Object.values(KpiPeriod);
  return {
    // common
    type: yup
      .string()
      .nullable()
      .oneOf(periodTypes)
      .required()
      .default(ReportingPeriodType.absolute)
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: periodTypes.map((type) => ({
            value: type,
            displayName: `${capitalize(type)}`,
          })),
        },
      }),
    period: yup
      .string()
      .nullable()
      .oneOf(kpiPeriods)
      .required()
      .default(KpiPeriod.quarter)
      .formMeta({
        renderer: RendererType.singleSelect,
        rendererMeta: {
          values: kpiPeriods.map((period) => ({
            value: period,
            displayName: period,
          })),
        },
      }),
    // absolute
    month: yup
      .array()
      .of(yup.number())
      .nullable()
      .default([])
      .test('isMonthRequired', 'This field is required', (value, ctx) => {
        if (ctx.parent.type !== ReportingPeriodType.absolute || ctx.parent.period === KpiPeriod.year) {
          return true;
        }
        return value != null && value.length > 0;
      }),
    year: yup
      .array()
      .of(yup.number())
      .default([])
      .nullable()
      .when('type', {
        is: ReportingPeriodType.absolute,
        then: (schema) => schema.required().min(1),
      }),
    // relative
    relativeDistance: yup
      .array()
      .of(yup.number())
      .default(null)
      .nullable()
      .formMeta({
        renderer: RendererType.multiSelect,
      })
      .when('type', {
        is: ReportingPeriodType.relative,
        then: (schema) => schema.required().min(1),
      }),
  };
}

export type MprConfigFormData = yup.InferType<ReturnType<typeof useMultiReportingColumnsSchema>>;

export function createMprConfigFormData(overrides: Partial<MprConfigFormData> = {}): MprConfigFormData {
  return {
    kpiId: [],
    section: [KpiSection.actual],
    type: ReportingPeriodType.absolute,
    period: KpiPeriod.quarter,
    month: null,
    year: null,
    relativeDistance: null,
    ...overrides,
  };
}

// sort order for these will be dynamic (because of relative dates and ability to rename custom kpis), so we can't store it in IMprColumnConfig.sortOrder
export const MprColumnSortType = {
  dateAsc: 'dateAsc', // sort by KpiPeriod/periodicity (month, quarter, year), then absolute date (use getDateForConfig )
  kpi: 'kpi', // sort by kpi name
} as const;
export type MprColumnSortType = (typeof MprColumnSortType)[keyof typeof MprColumnSortType];
export interface IMprViewSettings {
  kpis: IMprColumnConfig[]; // metrics will have a different structure
  columnSort: MprColumnSortType | null;
  // extend as needed
}
export function createMprViewSettings(overrides: Partial<IMprViewSettings> = {}): IMprViewSettings {
  return {
    kpis: [],
    columnSort: null,
    ...overrides,
  };
}
export interface IMultiPeriodReportResponse extends Pick<IMultiPeriodReportingRequest, 'isDateAdjusted'> {
  data: ICompanyFinancialsDataModel[];
  companies: ICompanyDataModel[];
}

export function createMultiPeriodReportResponse(
  overrides: Partial<IMultiPeriodReportResponse> = {}
): IMultiPeriodReportResponse {
  const { data, companies, ...rest } = overrides;
  return {
    isDateAdjusted: true,
    data: data ?? [],
    companies: companies ?? [],
    ...rest,
  };
}
