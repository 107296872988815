import { Card, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash-es';
import { useCallback, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { BasicDialog } from '../../../../../components/Dialog/BasicDialog';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { StickyFormButtons } from '../../../../../components/Form/StickyFormButtons';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { RendererType } from '../../../../../data-models/field.data-model';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { cardStyles } from '../../../../../theme/component-styles';
import { FMT } from '../../../../../util/formatter-service';
import { createFormField } from '../../../../../view-models/form.view-model';
import { InfoBox } from '../../../../Mappings/forms/InfoBox';
import { FieldLabel } from '../../../../../components/Form/FormComponents';
import { ModalFormWidth } from '../../../../../constants/styles';

const rejectInfo =
  'Rejection email will be automatically sent to the respondent. You can add an additional message with your feedback.';

interface IReviewResponseProps {
  company: ICompanyDataModel;
  request: IKPIRequestDataModel;
  onCancel: () => void;
  onSubmit: (comment?: string) => Promise<void>;
  action: 'accept' | 'reject';
}
export function ReviewResponseDialog({
  request,
  company,
  onCancel,
  onSubmit: onSubmit,
  action,
}: IReviewResponseProps) {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState<string | undefined>(undefined);

  const _onSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit(comment);
    setLoading(false);
  }, [comment, onSubmit]);
  return (
    <BasicDialog title={`${capitalize(action)} Response`} open onClose={onCancel}>
      <Stack
        p={'1rem 2.5rem 0'}
        gap={'0.5rem'}
        minWidth={ModalFormWidth}
        sx={{ '.field-label': { marginBottom: '-0.5rem', marginTop: '1rem' } }}
      >
        <Card
          component={Stack}
          style={cardStyles}
          display={'grid'}
          gridAutoFlow={'column'}
          gap={'1rem'}
          alignItems={'center'}
        >
          <CompanyLogoAndName name={company.name} logoUrl={company.logoUrl ?? ''} />
          <Typography variant='body2' noWrap>
            {FMT.formatKPIPeriod(request?.period, {
              frequency: request.frequency,
              showYear: true,
              yearFormat: 'yyyy',
            })}
          </Typography>
          <Typography variant='body2' minWidth={'1rem'} noWrap>
            {FMT.format('stringArray', company?.respondents)}
          </Typography>
        </Card>
        {action === 'reject' && <InfoBox message={rejectInfo} />}
        <CommentInputField value={comment} onChange={setComment} action={action} />
      </Stack>

      <StickyFormButtons
        onCancel={onCancel}
        onSubmit={_onSubmit}
        loading={loading}
        submitLabel={capitalize(action)}
      />
    </BasicDialog>
  );
}

interface ICommentInputFieldProps extends Pick<IReviewResponseProps, 'action'> {
  value?: string;
  onChange: (value: string) => void;
}
function CommentInputField({ value, onChange, action }: ICommentInputFieldProps) {
  const formField = createFormField({
    key: 'comment',
    description:
      action === 'accept' ? 'Include your feedback, which will be visible only to your team' : undefined,
    label: action === 'accept' ? 'Commentary' : '',
    renderer: RendererType.text,
    rendererMeta: {
      multiline: true,
      rows: 4,
      placeholder: 'Add your comment here',
    },
  });
  const formProps = {
    onChange: (e) => {
      onChange(e.target.value ?? '');
    },
    value,
  } as ControllerRenderProps;

  return (
    <>
      {action === 'accept' && <FieldLabel field={formField} />}
      <FieldFactory formField={formField} formProps={formProps} />
    </>
  );
}
