import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { getClientMetricsCharts } from '../../../../client-customizations/default/metrics-charts';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { IInvestmentDataModel } from '../../../../data-models/investment.data-model';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { CHART_COMPONENTS } from '../../../../types';
import { MaggieFeatureFlags } from '../../../../util/feature-flags';
import { useCurrentViewMetrics } from '../../hooks/useCurrentViewMetrics';
import { NumberOfRoundsOverTimeChart } from './AmountOfDealsOverTimeChart';
import { AmountOfDealsOverTimeChartRoundTracker } from './AmountOfDealsOverTimeChartRoundTracker';
import { InvestmentAmountBySectorChart } from './InvestmentAmountBySectorChart';
import { InvestmentAmountByStateChart } from './InvestmentAmountByStateChart';
import { InvestmentAmountOverTime } from './InvestmentAmountOverTime';
import { IrrByFundChart } from './IrrByFundChart';
import { IrrBySector } from './IrrBySector';
import { IrrChart } from './IrrChart/IrrChart';
import { MetricsByUserChart } from './MetricsByUserChart/MetricsByUserChart';
import { MoicBySectorChart } from './MoicBySectorChart/MoicBySectorChart';
import { MostCommonCoInvestors } from './MostCommonCoInvestors';
import { MostRecentParticipatingRounds } from './MostRecentParticipatingRoundsChart';
import { MostRecentRealizations } from './MostRecentRealizations';
import { MostRecentRounds } from './MostRecentRoundsChart';
import { MostRecentRoundsRoundTracker } from './MostRecentRoundsChartRoundTracker';
import { ValuationBridgeChart } from './ValuationBridgeChart';
import { MoicAndIrrByDealLeadChart } from './MoicAndIrrByDealLeadChart';

export interface IPortfolioChartProps<T> {
  metrics: T[];
  filteredMetrics: T[];
}

function getComponentsMap(flags: MaggieFeatureFlags) {
  return {
    [CHART_COMPONENTS.IRR]: IrrChart,
    [CHART_COMPONENTS.MOIC_BY_SECTOR]: MoicBySectorChart,
    [CHART_COMPONENTS.VALUATION_BRIDGE]: ValuationBridgeChart,
    [CHART_COMPONENTS.IRR_BY_FUND]: IrrByFundChart,
    [CHART_COMPONENTS.IRR_BY_SECTOR]: IrrBySector,
    [CHART_COMPONENTS.MOST_RECENT_REALIZATIONS]: MostRecentRealizations,
    [CHART_COMPONENTS.MOST_RECENT_ROUNDS]: MostRecentRounds,
    [CHART_COMPONENTS.MOST_RECENT_PARTICIPATING_ROUNDS]: MostRecentParticipatingRounds,
    [CHART_COMPONENTS.MOST_RECENT_ROUNDS_RT]: MostRecentRoundsRoundTracker,
    [CHART_COMPONENTS.MOST_COMMON_CO_INVESTORS]: MostCommonCoInvestors,
    [CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_STATE]: InvestmentAmountByStateChart,
    [CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME]: NumberOfRoundsOverTimeChart,
    [CHART_COMPONENTS.AMOUNT_OF_DEALS_OVER_TIME_RT]: AmountOfDealsOverTimeChartRoundTracker,
    [CHART_COMPONENTS.INVESTMENT_AMOUNT_BY_SECTOR]: InvestmentAmountBySectorChart,
    [CHART_COMPONENTS.INVESTMENT_AMOUNT_OVER_TIME]: InvestmentAmountOverTime,
    [CHART_COMPONENTS.METRICS_BY_USER]: MetricsByUserChart,
    [CHART_COMPONENTS.MOIC_AND_IRR_BY_DEAL_LEAD]: MoicAndIrrByDealLeadChart,
    ...getClientMetricsCharts(flags),
  } as Record<
    CHART_COMPONENTS,
    FC<IPortfolioChartProps<IMetricsDataModel>> | FC<IPortfolioChartProps<IInvestmentDataModel>>
  >;
}

interface Props {
  id: CHART_COMPONENTS;
}
export const ChartComponent: FC<Props> = ({ id }) => {
  const { metrics, filteredMetrics } = useCurrentViewMetrics();
  const flags = useFlags<MaggieFeatureFlags>();
  const componentsMap = getComponentsMap(flags);

  const Component = componentsMap[id] as FC<IPortfolioChartProps<unknown>>;

  return !Component ? null : (
    <GenericFallbacksWrapper style={{ gridColumn: 'span 2', maxHeight: '540px' }}>
      <Component metrics={metrics.getValue()} filteredMetrics={filteredMetrics.getValue()} />
    </GenericFallbacksWrapper>
  );
};
