import { IAppConfigDataModel } from '../../data-models/app-config.data-model';
import { IFormatterDataModel } from '../../data-models/formatter.data-model';
import { NumericFormattersConfig } from '../../util/formatters/NumericFormatters';
import { DateFormattersConfig } from '../../util/formatters/DateFormatters';
import { BooleanFormattersConfig } from '../../util/formatters/BooleanFormatters';
import { ArrayFormattersConfig } from '../../util/formatters/ArrayFormattersConfig';
import { StringFormattersConfig } from '../../util/formatters/StringFormatters';
import { three_rounds } from './round.scenario';
import {
  buy_transaction_type,
  secondary_purchase_transaction_type,
  sell_transaction_type,
} from './transaction-types.scenario';
import { two_sectors } from './sector.scenario';
import { two_users } from './user.scenario';
import { basic_metrics_config } from './metrics-config.scenario';
import { test_fund0, test_fund1, test_fund3 } from './fund.scenario';
import { four_currencies } from './currencies.scenario';

export function basic_formatters(): IFormatterDataModel<unknown>[] {
  return [
    ...Object.values(NumericFormattersConfig),
    ...Object.values(DateFormattersConfig),
    ...Object.values(BooleanFormattersConfig),
    ...Object.values(ArrayFormattersConfig),
    ...Object.values(StringFormattersConfig),
  ];
}

export function basic_app_config(): IAppConfigDataModel {
  return {
    currencies: [],
    formatters: [],
    funds: [],
    metricsConfig: basic_metrics_config(),
    objectiveCategory: [],
    rounds: three_rounds(),
    sectors: [],
    teams: [],
    transactionTypes: [],
    users: [],
    updatedAt: new Date().toISOString(),
  };
}

export function app_config_with_generalinfo(): IAppConfigDataModel {
  return {
    ...basic_app_config(),
    currencies: four_currencies(),
    users: two_users(),
    funds: [test_fund0(), test_fund3(), test_fund1()],
    sectors: two_sectors(),
    transactionTypes: [
      buy_transaction_type(),
      sell_transaction_type(),
      secondary_purchase_transaction_type(),
    ],
  };
}
