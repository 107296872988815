import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { useRecoilCallback } from 'recoil';
import { IFundDataModel } from '../../../data-models/fund.data-model';
import {
  RowGroupModel,
  ViewComponentUuid,
  ViewSectionUuid,
} from '../../../data-models/view-config.data-model';
import { CHART_COMPONENTS } from '../../../types';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { selectedViewPF } from '../state/ViewState';
import { useUpdateSelectedView } from './useViewActions';

export function useView() {
  const updateSelectedView = useUpdateSelectedView();
  const { showMostRecentRealizations, showMoicAndIrrByDealLead } = useFlags<MaggieFeatureFlags>();
  const getSection = useRecoilCallback(
    ({ snapshot }) =>
      (section: ViewSectionUuid) => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;

        if (!view?.sections?.[section]) {
          throw new Error(`There is no ${section} view section config!`);
        }

        return view.sections[section];
      },
    []
  );

  const getSectionsOrder = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        if (!view?.order?.sections) return [];

        return view.order.sections;
      },
    []
  );

  const getComponent = useRecoilCallback(
    ({ snapshot }) =>
      (component: ViewComponentUuid) => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        if (!view?.components?.[component]) {
          throw new Error(`There is no component!`);
        }

        return view.components[component];
      },
    []
  );

  const getComponentsOrder = useRecoilCallback(
    ({ snapshot }) =>
      (section: ViewSectionUuid) => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        if (!view?.order?.components?.[section]) return [];

        let components = view.order.components[section];
        if (!showMostRecentRealizations) {
          components = components.filter((c) => c !== CHART_COMPONENTS.MOST_RECENT_REALIZATIONS);
        }
        if (!showMoicAndIrrByDealLead) {
          components = components.filter((c) => c !== CHART_COMPONENTS.MOIC_AND_IRR_BY_DEAL_LEAD);
        }
        return components;
      },
    []
  );

  const setComponentsOrder = useRecoilCallback(
    ({ snapshot }) =>
      (section: ViewSectionUuid, order: ViewComponentUuid[]) => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        if (view) {
          updateSelectedView({
            order: { ...view.order, components: { ...view.order.components, [section]: order } },
          });
        }
      },
    [updateSelectedView]
  );

  const setComponent = useRecoilCallback(
    ({ snapshot }) =>
      (component: ViewComponentUuid, componentState: Record<string, unknown>) => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        if (view) {
          updateSelectedView({
            components: {
              ...view.components,
              [component]: { ...view.components[component], ...componentState },
            },
          });
        }
      },
    [updateSelectedView]
  );

  const getFundsFilter = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        return view?.filteredFunds || null;
      },
    []
  );

  const setFundsFilter = useCallback(
    (filteredFunds: IFundDataModel[] | null) => {
      updateSelectedView({ filteredFunds });
    },
    [updateSelectedView]
  );

  const getRowGroupModel = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        return view?.rowGroupModel || null;
      },
    []
  );

  const setRowGroupModel = useCallback(
    (rowGroupModel: RowGroupModel | null) => {
      updateSelectedView({
        rowGroupModel,
      });
    },
    [updateSelectedView]
  );

  const getTableFilters = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const view = snapshot.getLoadable(selectedViewPF).valueMaybe() ?? null;
        if (!view?.tableFilters) return null;
        return view.tableFilters;
      },
    []
  );

  const setTableFilters = useCallback(
    (filterModel: Record<string, unknown> | null) => {
      const updates = {
        tableFilters: filterModel && Object.keys(filterModel).length === 0 ? null : filterModel,
      };
      updateSelectedView(updates);
    },
    [updateSelectedView]
  );

  return useMemo(
    () => ({
      isLoading: false,

      getSection,
      getSectionsOrder,
      getComponent,
      getComponentsOrder,
      setComponentsOrder,
      setComponent,
      isFetching: false,
      setFundsFilter,
      getFundsFilter,
      getTableFilters,
      setTableFilters,
      getRowGroupModel,
      setRowGroupModel,
    }),
    [
      getSection,
      getSectionsOrder,
      getComponent,
      getComponentsOrder,
      setComponentsOrder,
      setComponent,
      setFundsFilter,
      getFundsFilter,
      getTableFilters,
      setTableFilters,
      getRowGroupModel,
      setRowGroupModel,
    ]
  );
}
