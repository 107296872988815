import { IconButton, styled, Typography } from '@mui/material';
import { ModeEdit } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FieldValues } from 'react-hook-form';
import { get } from 'lodash-es';
import { FormatterService } from '../../../util/formatter-service';
import { IForm, IFormField } from '../../../view-models/form.view-model';

export const ConfigRowFieldWrapperClass = 'configItemFields';

export const ItemRow = styled('div')`
  background: #ffffff80;
  border-radius: 4px;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: 1rem;
  box-shadow:
    0px -4px 4px rgba(255, 255, 255, 0.06),
    0px 4px 4px rgba(16, 37, 62, 0.04),
    0px 4px 4px rgba(75, 82, 93, 0.02);
`;

export const Items = styled('div')`
  column-gap: 1rem;
  row-gap: 0.5rem;
  display: grid;
  grid-template-columns: 10rem repeat(auto-fit, minmax(12rem, max-content));
`;

export const Actions = styled('div')`
  display: flex;
  column-gap: 0.5rem;
`;

export interface IConfigItemRowProps<T extends FieldValues> {
  form: IForm<T>;
  onDelete?: (item: T) => Promise<void> | void;
  onEdit?: (item: T) => Promise<void> | void;
}

export function ConfigItemRow<T extends FieldValues>(props: IConfigItemRowProps<T>) {
  const { form, onDelete, onEdit } = props;
  const { data, disabled, fields } = form;
  const rowItems: React.ReactElement[] = [];
  fields.forEach((item) => {
    const { key } = item;
    const value = get(data, key);

    rowItems.push(<TextItem key={key} item={item} value={value} />);
  });

  return (
    <ItemRow>
      <Items className={ConfigRowFieldWrapperClass}>{rowItems}</Items>
      <Actions>
        {onEdit && (
          <IconButton
            aria-label='edit'
            color={'secondary'}
            size='small'
            onClick={() => onEdit(form.data ?? ({} as T))}
            disabled={disabled}
          >
            <ModeEdit />
          </IconButton>
        )}
        {onDelete && (
          <IconButton
            aria-label='delete'
            color={'secondary'}
            size='small'
            onClick={() => onDelete(form.data ?? ({} as T))}
            disabled={disabled}
          >
            <DeleteOutlineIcon />
          </IconButton>
        )}
      </Actions>
    </ItemRow>
  );
}

export interface ITextItemProps {
  item: IFormField<unknown>;
  value: string | string[] | number | undefined;
}
export function TextItem(props: ITextItemProps) {
  const { item, value } = props;
  const { label } = item;
  const format = FormatterService.get().getFormatterForFormField(item);

  return (
    <div>
      <Typography variant={'caption'} color='text.secondary'>
        {label}
      </Typography>
      <Typography variant='body2' color='text.primary'>
        {format(value)}
      </Typography>
    </div>
  );
}
