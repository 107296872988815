import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useSetAtom } from 'jotai';
import { merge } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { FormContainer } from '../../../components/Form/FormComponents';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { Fund, fundFormSchema } from '../../../schemas/Fund.schema';
import { fromFundViewModel, FundViewModel, fundViewModelSchema } from '../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { StepperFormButtons } from '../../Finance2/Forms/StepperFormButtons';
import { fundFormCurrentStepState, fundFormState, showFundFormsState } from '../state/FPState';
import { IFundFormProps } from './FundWaterfallForm';

export function WaterfallSettingsFormStep2({ onSubmit }: Pick<IFundFormProps, 'onSubmit'>) {
  const setShowForm = useSetAtom(showFundFormsState);
  const [formData, setFormData] = useAtom(fundFormState);
  const setCurrentStep = useSetAtom(fundFormCurrentStepState);

  const methods = useForm<FundViewModel>({
    defaultValues: formData as FundViewModel,
    resolver: yupResolver(fundViewModelSchema() as ObjectSchema<FundViewModel>),
    mode: 'all',
  });

  const handleBack = useCallback(() => {
    setFormData((curr) => ({ ...curr, ...methods.getValues() }));
    setCurrentStep((curr) => curr - 1);
  }, [methods, setCurrentStep, setFormData]);

  const _onSubmit = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const payload = prepareFundPayload(
        fromFundViewModel(merge({}, methods.formState.defaultValues ?? {}, methods.getValues()))
      );
      await onSubmit(payload);
      setShowForm(null);
      return true;
    } else {
      console.debug('errors: ', methods.formState.errors);
      return false;
    }
  }, [methods, onSubmit, setShowForm]);

  return (
    <FormProvider {...methods}>
      <FormContainer>
        <WaterfallFormStep2Fields />
      </FormContainer>
      <StepperFormButtons
        stepIsValid={_onSubmit}
        handleGoBack={handleBack}
        incrementOnNext={false}
        nextButtonLabel='Submit'
      />
    </FormProvider>
  );
}

function WaterfallFormStep2Fields() {
  const step2Fields = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), [
      'isGPPortionIncludedInLPNav',
      'isIdleFundsIncomeIncluded',
      'isManagementFeeRestored',
      'isProfitLossIncludedInGPCapital',
      'isProfitLossIncludedInSLPCapital',
      'isExpensesRestored',
      'isCommitmentUsedInsteadOfLPContributions',
    ]);
  }, []);

  return (
    <>
      {step2Fields.map((field) => {
        return <FormFieldWithLabelFactory key={field.key} formField={field} />;
      })}
    </>
  );
}

function prepareFundPayload(fund: Partial<Fund>): Partial<Fund> {
  const { calculationType, isProceedsPercentAdjusted } = fundFormSchema().getDefault();
  return {
    ...fund,
    calculationType,
    isProceedsPercentAdjusted,
  };
}
