import { AgEvent } from 'ag-grid-community';
import { IMetricsDataModel } from '../../../../data-models/metrics.data-model';

export enum CompanyViewGridEventType {
  showRoundDetails = 'showRoundDetails',
}

export interface ICompanyViewGridEvent extends AgEvent {
  type: CompanyViewGridEventType;
  data: IMetricsDataModel;
}
