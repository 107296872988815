import { addYears, endOfDay } from 'date-fns';
import { atom as atomJ } from 'jotai';
import { atomFamily as atomFamilyJ, atomWithReset, useAtomCallback, useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { IFundMetricsResponseDataModel } from '../../../data-models/fund-metrics.data-model';
import { Fund } from '../../../schemas/Fund.schema';
import { FundContributionsResponse } from '../../../schemas/FundContributions.schema';
import { FundData } from '../../../schemas/FundData.schema';
import { FundProjectedInvestmentAudit } from '../../../schemas/FundProjectedInvestmentAudit.schema';
import { FundInvestment, FundReserves } from '../../../schemas/FundReserves.schema';
import { FundViewModel } from '../../../schemas/FundViewModel.schema';
import {
  fetchFundData,
  fetchFundReserves,
  getFundProjectedInvestmentHistory,
} from '../../../services/queries/MaggieFundQueries';
import {
  getFundContributions,
  getFundMetrics,
  getPlainDateString,
} from '../../../services/queries/MaggieMetricsQueries';
import { fundsByIdMapAtom } from '../../../services/state/AppConfigStateJ';
import { companyState } from '../../../services/state/CompanyState';
import { getForesightStore } from '../../../util/jotai-store';
import { forceFundMetricsUpdate } from '../../PortfolioOverview/state/MetricsState';

export const selectedFundIdStateFP = atom<number | null>({
  key: 'selectedFundIdStateFP',
  default: null,
});

export const selectedFundStateFP = selector<Fund | null>({
  key: 'selectedFundStateFP/default',
  get: ({ get }) => {
    const fundId = get(selectedFundIdStateFP);
    const store = getForesightStore();

    return store.get(fundsByIdMapAtom).get(fundId ?? -1) ?? null;
  },
});

export const DefaultSelectedDateFP = endOfDay(new Date());
export const selectedDateFPState = atomJ(DefaultSelectedDateFP);

type SelectedFPKey = {
  date: Date;
  fundId: number | null;
};
export const selectedFundMetricsStateFP = atomFamily<IFundMetricsResponseDataModel | null, SelectedFPKey>({
  key: 'selectedFundMetricsStateFP',
  default: selectorFamily({
    key: 'selectedFundMetricsStateFP/default',
    get:
      ({ date, fundId }) =>
      ({ get }) => {
        if (!fundId) return null;
        get(forceFundMetricsUpdate);
        return getFundMetrics(getPlainDateString(date), [fundId]);
      },
  }),
});

export const fundDataStateFP = atomFamilyJ((fundId: number) =>
  atomJ<FundData[] | Promise<FundData[]>>(fetchFundData(fundId))
);

export const fundDataByDateState = atomFamilyJ((fundId: number) =>
  atomJ(async (get) => {
    const fundData = await get(fundDataStateFP(fundId));
    return fundData.reduce((acc, data) => {
      return acc.set(data.date, data);
    }, new Map<string, FundData>());
  })
);

export const fundDataSortedByDateDescStateFP = atomFamilyJ((fundId: number) =>
  atomJ(async (get) => {
    const fundData = await get(fundDataStateFP(fundId));
    return fundData.toSorted((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  })
);

export const latestFundDataStateFP = atomFamilyJ((fundId: number) =>
  atomJ(async (get) => {
    const asOf = get(selectedDateFPState);
    return (await get(fundDataSortedByDateDescStateFP(fundId)))?.find(
      (data) => new Date(data.date).getTime() <= new Date(asOf).getTime()
    );
  })
);

export const contributionsDistributionsState = atom<FundContributionsResponse | null>({
  key: 'contributionsDistributionsState',
  default: selector<FundContributionsResponse | null>({
    key: 'contributionsDistributionsState/default',
    get: ({ get }) => {
      const store = getForesightStore();
      const date = store.get(selectedDateFPState);
      const fundId = get(selectedFundIdStateFP);
      if (!fundId) return null;
      return getFundContributions(getPlainDateString(date), [fundId]).then((data) => data?.at(0) ?? null);
    },
  }),
});

export const fundFormState = atomJ<Partial<FundViewModel> | null>(null);
export const fundFormCurrentStepState = atomJ(0);
export function useResetFundFormState() {
  return useAtomCallback(
    useCallback((get, set) => {
      set(fundFormState, null);
      set(fundFormCurrentStepState, 0);
    }, [])
  );
}

export const showFundFormsState = atomJ<'waterfall' | 'allocation' | null>(null);

export const showWaterfallSettingsState = atom({
  key: 'showWaterfallSettingsState',
  default: false,
});

export const isEditingFundDataState = atomJ(false);

export const forceReservesUpdateState = atom<number>({
  key: 'forceReservesUpdateState',
  default: 0,
});
export const selectedFundReservesState = atomFamily<FundReserves | null, number>({
  key: 'selectedFundReservesState',
  default: selectorFamily({
    key: 'selectedFundReservesState/default',
    get:
      (fundId) =>
      ({ get }) => {
        get(forceReservesUpdateState);
        const fund = get(selectedFundStateFP);
        if (!fundId) return null;
        return fetchFundReserves(getDefaultProjectionsAsOfDate(fund), fundId);
      },
  }),
});

export const selectedFundReservesByCompanyNameState = selectorFamily<Map<string, FundInvestment>, number>({
  key: 'selectedFundReservesByCompanyNameState',
  get:
    (fundId) =>
    ({ get }) => {
      const reserves = get(selectedFundReservesState(fundId));
      return (reserves?.investments ?? []).reduce((map, investment) => {
        const company = get(companyState(investment.companyId ?? -1));
        const key = String(company?.name ?? investment.name);
        if (!key) return map;
        return map.set(key, investment);
      }, new Map<string, FundInvestment>());
    },
});

export const fundReservesTransactionUuidToInvestmentState = selectorFamily<
  Map<string, FundInvestment>,
  number
>({
  key: 'fundReservesTransactionUuuidToInvestmentState',
  get:
    (fundId) =>
    ({ get }) => {
      const reserves = get(selectedFundReservesState(fundId));
      return (reserves?.investments ?? []).reduce((map, investment) => {
        investment.transactions.forEach((transaction) => {
          if (transaction.hypothetical && transaction.uuid) {
            map.set(transaction.uuid, investment);
          }
        });
        return map;
      }, new Map<string, FundInvestment>());
    },
});

export const fundReservesHistoryState = atomFamily<FundProjectedInvestmentAudit[], number | null>({
  key: 'selectedFundReservesHistoryState',
  default: selectorFamily({
    key: 'selectedFundReservesHistoryState/default',
    get: (fundId) => () => {
      if (!fundId) return [];
      return getFundProjectedInvestmentHistory(fundId);
    },
  }),
});

export const reservesHistoryByCompanyNameState = selectorFamily<
  Map<string, FundProjectedInvestmentAudit[]>,
  number | null
>({
  key: 'reservesHistoryByCompanyState',
  get:
    (fundId) =>
    ({ get }) => {
      const history = get(fundReservesHistoryState(fundId));
      return history.reduce((acc, audit) => {
        const company = get(companyState(audit.companyId ?? -1));
        const key = String(company?.name ?? audit.name);
        if (!key) return acc;
        const audits = acc.get(key!) ?? [];
        return acc.set(key!, [...audits, audit]);
      }, new Map<string, FundProjectedInvestmentAudit[]>());
    },
});

export const ReservesAction = {
  AddInvestment: 'AddInvestment',
  AddTransaction: 'AddTransaction',
  DeleteInvestment: 'DeleteInvestment',
  DeleteTransaction: 'DeleteTransaction',
  EditTransaction: 'EditTransaction',
} as const;
export type ReservesAction = (typeof ReservesAction)[keyof typeof ReservesAction];

export const showReservesFormState = atomWithReset<ReservesAction | null>(null);
export const selectedFundCompanyNameState = atomWithReset<string | null>(null);
export const selectedInvestmentTransactionUuidState = atomWithReset<string | null>(null);

export function useResetReservesFormState() {
  const resetSelectedFundCompany = useResetAtom(selectedFundCompanyNameState);
  const resetSelectedInvestmentTransactionUuid = useResetAtom(selectedInvestmentTransactionUuidState);
  const resetShowForm = useResetAtom(showReservesFormState);

  return useCallback(() => {
    resetSelectedFundCompany();
    resetSelectedInvestmentTransactionUuid();
    resetShowForm();
  }, [resetSelectedFundCompany, resetSelectedInvestmentTransactionUuid, resetShowForm]);
}

type ProjectionForm = 'feesAndExpenses' | 'recycling';
export const showProjectionFormState = atomWithReset<ProjectionForm | null>(null);
export const refetchCapitalAllocationState = atomJ<number>(0);

export function getDefaultProjectionsAsOfDate(fund: Fund | null) {
  return new Date(
    fund?.finalCloseDate ??
      fund?.managementFeeTerminationDate ??
      addYears(new Date(fund?.inceptionDate ?? endOfDay(new Date())), 10)
  );
}
