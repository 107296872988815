import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { get, set } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { CompanyCellRenderer } from '../../../components/grid-renderers/CompanyCellRenderer';
import { useLoadingBarState } from '../../../components/LoadingBar/LoadingBarContext';
import { KpiPeriod, KpiSection } from '../../../data-models/company-financials.data-model';
import { field2ToField3, IField } from '../../../data-models/field2.data-model';
import { field3ToColumnDef } from '../../../data-models/field3.data-model';
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { getMultiPeriodReport } from '../../../services/queries/MaggieMultiPeriodReportingQueries';
import { kpiConfigByIdMapAtom } from '../../../services/state/KPIConfigState';
import { LoadingId } from '../../../types';
import { useAsync } from '../../../util/hook-utils';
import { getFormattedFiscalDate } from '../../CompanyProfiles/utils/financialUtils';
import { getTypedFinancials, TypedFinancials } from '../../CompanyProfiles/utils/getTypedFinancials';
import { selectedViewIdPF } from '../state/ViewState';
import { IMultiPeriodReportResponse } from './MultiPeriodReporting.schemas';
import {
  getColumnConfigKey,
  getDateForConfig,
  IMprColumnConfig,
  mprColumnConfigToDataPoint,
} from './multiPeriodReportingUtils';
import { savedMprViewConfigAtom } from './useMprColumnConfigs';

export type MultiPeriodReportingDataByKpiId = Record<
  number,
  Record<string, Record<string, Record<KpiSection, TypedFinancials>>>
>;

export function getMultiPeriodReportingDataMap(
  report: IMultiPeriodReportResponse,
  kpiConfigMap: Map<number, IField<unknown>>
) {
  return getTypedFinancials(report.data, kpiConfigMap).reduce(
    (acc, item) => {
      return set(acc, [item.companyId, item.kpiId, item.date, item.period!, item.section!], item);
    },
    {} as Record<number, MultiPeriodReportingDataByKpiId>
  );
}

export const MprCompanyColId = 'company';
const companyColDef: ColDef = {
  field: 'company',
  colId: MprCompanyColId,
  cellRenderer: CompanyCellRenderer,
  cellRendererParams: {
    linkToProfile: true,
  },
  valueFormatter: (params: ValueFormatterParams) => {
    return params?.value?.name ?? '';
  },
  filter: 'agSetColumnFilter',
  filterParams: {
    buttons: ['reset'],
  },
  pinned: 'left',
  width: 200,
};

export function useMultiPeriodReportingColumnDefs() {
  const viewId = useRecoilValue(selectedViewIdPF);
  const configs = useAtomValue(savedMprViewConfigAtom(viewId));
  const kpiConfigMap = useAtomValue(kpiConfigByIdMapAtom);
  const configDefs = configToColumnDefs(configs?.kpis ?? [], kpiConfigMap);

  return useMemo(() => [companyColDef, ...configDefs], [configDefs]);
}

export function configToColumnDefs(
  config: IMprColumnConfig[],
  kpiMap: Map<number, IField<unknown>>
): ColDef[] {
  return [...config]
    .sort((a, b) => (a.sortOrder ?? Number.MIN_SAFE_INTEGER) - (b.sortOrder ?? Number.MIN_SAFE_INTEGER))
    .map((cf) => {
      return {
        ...field3ToColumnDef(field2ToField3(kpiMap.get(cf.kpiId!)!)),
        colId: getColumnConfigKey(cf),
        headerName: formatHeader(cf, kpiMap.get(cf.kpiId!)!),
        valueGetter: (params: ValueGetterParams) => {
          const dataMap = params.context.rowDataMap as Record<number, MultiPeriodReportingDataByKpiId>;
          return get(dataMap, [
            params.data.company.id,
            cf.kpiId!,
            getDateForConfig(cf),
            cf.period!,
            cf.section!,
          ])?.value;
        },
      };
    });
}

export function useMprConfigToColDefs() {
  const kpiConfigMap = useAtomValue(kpiConfigByIdMapAtom);
  return useCallback(
    (config: IMprColumnConfig[], includeStaticColumns = true) => {
      if (includeStaticColumns) {
        return [companyColDef, ...configToColumnDefs(config, kpiConfigMap)];
      } else {
        return configToColumnDefs(config, kpiConfigMap);
      }
    },
    [kpiConfigMap]
  );
}

function formatHeader(columnConfig: IMprColumnConfig, kpi: IField<unknown>) {
  return `${kpi.displayName} (${columnConfig.section}) ${getFormattedFiscalDate(getDateForConfig(columnConfig), columnConfig.period as KpiPeriod, 12)}`;
}

// can keep everything local as long as it's called from single component
export function useMprReportData() {
  const viewId = useRecoilValue(selectedViewIdPF);
  const configs = useAtomValue(savedMprViewConfigAtom(viewId));
  const prevConfigs = usePreviousValue(configs);
  const prevConfigSet = useMemo(
    () => new Set(prevConfigs?.kpis?.map((c) => getColumnConfigKey(c))),
    [prevConfigs]
  );

  const kpiConfigMap = useAtomValue(kpiConfigByIdMapAtom);
  const {
    actions: { startLoading, stopLoading },
  } = useLoadingBarState();

  const columns = configs?.kpis;
  const [cachedData, setCachedData] = useState<IMultiPeriodReportResponse | null>(null);
  const getReport = useCallback(async () => {
    if (!columns?.length) {
      return;
    }
    if (columns.every((c) => prevConfigSet.has(getColumnConfigKey(c))) && Boolean(cachedData)) {
      return filterCachedMprResponse(cachedData, columns, kpiConfigMap);
    }
    startLoading(LoadingId.getMultiPeriodReport);
    const payload = {
      dataPoints: columns.map((config) => {
        return mprColumnConfigToDataPoint(config);
      }),
      isDateAdjusted: true,
    };
    const reportData = await getMultiPeriodReport(payload);
    setCachedData(reportData);
    stopLoading(LoadingId.getMultiPeriodReport);
    return reportData;
  }, [cachedData, columns, kpiConfigMap, prevConfigSet, startLoading, stopLoading]);

  const { data, loading } = useAsync(getReport, { id: getRefetchId(columns) });

  const rowDataMap = useMemo(() => {
    if (!data) return null;
    return getMultiPeriodReportingDataMap(data, kpiConfigMap);
  }, [data, kpiConfigMap]);
  const companyRowData = useMemo(() => {
    return data?.companies.map((company) => {
      return { company };
    });
  }, [data]);

  return { loading, rowDataMap, companyRowData };
}

function getRefetchId(configs?: IMprColumnConfig[] | null) {
  return (
    configs
      ?.map((c) => getColumnConfigKey(c))
      ?.sort()
      .join(',') ?? ''
  );
}

export function filterCachedMprResponse(
  mprData: IMultiPeriodReportResponse | null,
  config: IMprColumnConfig[],
  kpiConfigMap: Map<number, IField<unknown>>
) {
  if (!mprData) return null;
  const dataMap = getMultiPeriodReportingDataMap(mprData, kpiConfigMap);
  const filteredCompanies = mprData.companies.filter((company) => {
    for (const cf of config) {
      if (get(dataMap, [company.id, cf.kpiId!, getDateForConfig(cf), cf.period!, cf.section!])?.value) {
        return true;
      }
    }
    return false;
  });
  return { ...mprData, companies: filteredCompanies };
}
