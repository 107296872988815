import {
  afterProcessTransactions,
  createMetricTotalProcessor,
  createTransactionsProcessor,
} from '../../../../client-customizations/default/metrics';
import { CalculatorMeta } from '../../../../client-customizations/default/metrics.type';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IFundDataModel } from '../../../../data-models/fund.data-model';
import {
  IFundDataMetricFieldsDataModel,
  IMetricsDataModel,
} from '../../../../data-models/metrics.data-model';
import { IRoundDataModel } from '../../../../data-models/round.data-model';
import { MetricsTransactionDataModel } from '../../../../schemas/MetricsTransaction.schema';
import { ColumnMeta } from '../../../../types';
import { MetricsFundDataFieldsCalculator } from './MetricsFundDataFieldsCalculator';

export type CalculatedMetricsDataModel = IMetricsDataModel & IFundDataMetricFieldsDataModel;

export function recalculateMetrics(
  metrics: IMetricsDataModel[],
  fundsMap: Map<number, IFundDataModel>,
  roundsMap: Map<number, IRoundDataModel>,
  asOfDate: Date,
  isGroupedByFund: boolean,
  getCompany: (companyId: number) => ICompanyDataModel | null,
  customFundFields: ColumnMeta[]
): (CalculatedMetricsDataModel | null)[] {
  const totalProcessor = createMetricTotalProcessor();

  /**
   * There are 4 steps in the loop:
   * - Process the transactions
   * - Process after transactions
   * - Process fund metrics
   * - Process TotalContext
   */
  const outputMetrics = metrics.reduce((resMetrics, metric) => {
    const _company = getCompany(metric.companyId);
    if (!_company) {
      return resMetrics;
    }

    let res;
    const processTransactionsResult = processTransactions(metric.transactions, {
      company: _company,
      fundsMap,
    });
    res = { ...metric, ...processTransactionsResult };

    const afterProcessTransactionsResult = afterProcessTransactions(res, {
      asOfDate,
      roundsMap,
    });
    res = { ...res, ...afterProcessTransactionsResult };

    const processFundMetricsResult = processFundMetrics(res, _company, isGroupedByFund, customFundFields);
    res = { ...res, ...processFundMetricsResult };

    totalProcessor.processMetric(res as CalculatedMetricsDataModel);
    resMetrics.push(res as CalculatedMetricsDataModel);

    return resMetrics;
  }, [] as CalculatedMetricsDataModel[]);

  return processTotalMetrics(totalProcessor, outputMetrics);
}

function processTransactions(transactions: MetricsTransactionDataModel[], meta?: CalculatorMeta) {
  const transactionsProcessor = createTransactionsProcessor(meta);
  transactions.forEach((transaction) => {
    transactionsProcessor.processTransaction(transaction);
  });

  return transactionsProcessor.getResult();
}

function processFundMetrics(
  metric: IMetricsDataModel,
  company: ICompanyDataModel,
  isGroupedByFund: boolean,
  customFundFields: ColumnMeta[]
): Partial<IMetricsDataModel & IFundDataMetricFieldsDataModel> {
  const fundDataFields = new MetricsFundDataFieldsCalculator().run(
    metric,
    isGroupedByFund,
    () => company,
    customFundFields
  );
  const firstFundData = metric.fundData.at(0);

  return {
    // Candidates to be moved into processTransactions
    convertedSharesHeld: isGroupedByFund ? firstFundData?.convertedSharesHeld : metric.convertedSharesHeld,
    initialInvestmentRound: isGroupedByFund
      ? (firstFundData?.initialInvestmentRound ?? '')
      : metric.initialInvestmentRound,
    // END

    initialPostMoney: (isGroupedByFund ? firstFundData?.initialPostMoney : metric.initialPostMoney) ?? 0,
    impliedEquityValue: !fundDataFields.ownerShipPercentage
      ? null
      : ((metric.equityFmv as number) * 100) / fundDataFields.ownerShipPercentage,
    percentOfFund: isGroupedByFund ? firstFundData?.percentOfFund : metric.percentOfFund,
    percentTotalValue: isGroupedByFund ? firstFundData?.percentTotalValue : metric.percentTotalValue,
    rtfe: (isGroupedByFund ? firstFundData?.rtfe : metric.rtfe) ?? 0,
    totalImpliedEquityValue: !fundDataFields.ownerShipPercentage
      ? null
      : (metric.fmv * 100) / fundDataFields.ownerShipPercentage,
    ...fundDataFields,
  };
}

function processTotalMetrics<T extends CalculatedMetricsDataModel>(
  totalProcessor: {
    processMetric(metric: CalculatedMetricsDataModel): void;
    getResult(metrics: CalculatedMetricsDataModel[]): T[];
  },
  metrics: CalculatedMetricsDataModel[]
) {
  return totalProcessor.getResult(metrics);
}
