import * as yup from 'yup';
import { merge } from 'lodash-es';
import { KPIAggFunc } from '../pages/PortfolioOverview/FundReporting/FundsKPIs';
import { FundProfileLinkRenderer } from '../pages/PortfolioOverview/components/OverviewTable/columns/TypeBasedConfigs/TypeCellRenderers/FundProfileLinkRenderer';
import { multiplierField, percentField, usdField, usdShortField } from './common-schema-defs';
import { companyFundMetricsFormSchema } from './CompanyFundMetrics.schema';

export function fundMetricsByFundFields() {
  return {
    activeCompanies: yup
      .array()
      .of(companyFundMetricsFormSchema())
      .nullable()
      .label('Active Companies')
      .default([]),
    date: yup.string().required().label('Date').default(''),
    fundId: yup.number().required().label('Fund').default(0),
    metrics: yup.object().shape(fundMetricsFields()).required().default({}),
    mostRecentContributedSecurities: usdField().nullable().default(null),
    mostRecentDeemedContributions: usdField().nullable().default(null),
    totalCompanies: yup.number().default(0),
  };
}

export function fundMetricsByFundFormSchema() {
  return yup.object().shape(fundMetricsByFundFields());
}

export type FundMetricsByFund = yup.InferType<ReturnType<typeof fundMetricsByFundFormSchema>>;

export function createFundMetricsByFund(overrides: Partial<FundMetricsByFund> = {}): FundMetricsByFund {
  return merge({}, fundMetricsByFundFormSchema().getDefault(), overrides);
}

export function fundMetricsByFundGridSchema() {
  const { date, fundId } = fundMetricsByFundFields();
  return yup.object().shape({
    activeCompanies: yup.array().of(companyFundMetricsFormSchema()).required().default([]),
    date,
    fundId: fundId.gridMeta({
      cellRenderer: FundProfileLinkRenderer,
      formatter: 'fund',
    }),

    metrics: fundMetricsGridSchema(),
    totalCompanies: yup.number().required().default(0),
  });
}

export function fundMetricsFields() {
  return {
    activationDate: yup.date().nullable().default(null),
    amountAvailableForLpGpSplit: usdShortField()
      .nullable()
      .default(null)
      .label('Max Available for LP/GP Split'),
    availableForGpCatchup: usdShortField().nullable().default(null),
    availableForSuperReturn: yup.number().nullable().default(null),
    capitalCalledPercent: percentField().nullable().default(null),
    capitalLossRatio: multiplierField().nullable().default(null),
    contributions: usdShortField().required().label('Total Contributions').nullable().default(null),
    contributionsForCalculation: usdShortField().nullable().default(null),
    distributableProceeds: usdShortField()
      .required()
      .label('Distributable Proceeds')
      .nullable()
      .default(null),
    distributions: usdShortField().required().label('Total Distributions').nullable().default(null),
    dpi: multiplierField().required().label('DPI (Total)').nullable().default(null),
    escrowReceivable: usdShortField().nullable().default(null).label('Escrow Receivable'),
    fmv: usdShortField().label('Total Unrealized Value').nullable().default(null),
    followOnInvestmentAmount: usdShortField().nullable().default(null).label('Follow-On Investments'),
    gpContributions: usdShortField().required().label('GP Contributions').nullable().default(null),
    gpDistributions: usdShortField().required().label('GP Distributions').nullable().default(null),
    gpIncentive: usdShortField().required().label('GP Incentive (Carry)').nullable().default(null),
    gpIncentiveFromGpCatchup: usdShortField().nullable().default(null),
    gpIncentiveFromLpGpSplit: usdShortField().nullable().default(null),
    gpIncentiveFromSuperReturn: usdShortField().nullable().default(null),
    gpROC: usdShortField().nullable().default(null),
    grossIRR: percentField().nullable().label('Gross IRR').nullable().default(null),
    gpTotal: usdShortField().nullable().default(null),
    grossMOIC: multiplierField().nullable().label('Gross MOIC').nullable().default(null),
    initialInvestmentAmount: usdShortField().nullable().label('Initial Investment').nullable().default(null),
    investmentAmount: usdShortField().nullable().label('Total Invested').nullable().default(null),
    lossRatio: multiplierField().nullable().nullable().default(null),
    lpContributions: usdShortField().required().label('LP Contributions').nullable().default(null),
    lpDistributions: usdShortField().required().label('LP Distributions').nullable().default(null),
    lpDpi: multiplierField().required().label('DPI (LP)').nullable().default(null),
    lpGpSlitAmount: usdShortField().nullable().default(null).label('LP/GP Split Amount'),
    lpNav: usdShortField().required().label('LP NAV').nullable().default(null),
    lpNavFromLpGpSplit: usdShortField().nullable().default(null).label('LP NAV from LP/GP Split'),
    lpNavFromSuperReturn: usdShortField().nullable().default(null),
    lpNavTotal: usdShortField().nullable().default(null),
    lpNetIrr: percentField().required().label('Net IRR (LP)').nullable().default(null),
    lpROC: usdShortField().nullable().default(null),
    lpTvpi: multiplierField().nullable().default(null).label('TVPI (LP)'),
    navFromSuperReturnGP: usdShortField().nullable().default(null).label('NAV from Super Return (GP)'),
    navFromSuperReturnLP: usdShortField().nullable().default(null).label('NAV from Super Return (LP)'),
    navLPPortion: usdShortField().nullable().default(null),
    navGPPortion: usdShortField().nullable().default(null),
    managementFee: usdShortField().required().label('Management Fees').nullable().default(null),
    maxGpCatchup: usdShortField().nullable().label('Max GP Catch Up').default(null),
    maxLpGpSplit: usdShortField().nullable().label('Max Available for LP/GP Split').default(null), // tier 1 (vs amountAvailableForLpGpSplit)
    navFromLpGpSplitGp: usdShortField().nullable().default(null).label('NAV from LP/GP Split (GP)'),
    navFromLpGpSplitLP: usdShortField().nullable().default(null).label('NAV from LP/GP Split (LP)'),
    netAssets: usdShortField().label('Working Capital').nullable().default(null).customMeta({
      description: 'Cash - Total Liabilities',
    }),
    netIRR: percentField().required().label('Net IRR (Total)').nullable().default(null),
    netMOIC: multiplierField().required().label('Net MOIC').nullable().default(null),
    netReturn: usdShortField().required().label('Net Return').nullable().default(null),
    realizedGL: usdShortField().nullable().label('Realized Gain/Loss').nullable().default(null),
    realizedValue: usdShortField().nullable().label('Total Realized Value').nullable().default(null),
    rvpi: multiplierField().required().label('RVPI (Total)').nullable().default(null),
    totalValue: usdShortField().required().label('Total Value').nullable().default(null),
    ttvpi: multiplierField().required().label('TVPI (Total)').nullable().default(null),
    unrealizedGL: usdShortField().nullable().label('Unrealized Gain/Loss').nullable().default(null),
    unrealizedValue: usdShortField().nullable().label('Total Unrealized Value').nullable().default(null),
  };
}

export function fundMetricsFormSchema() {
  return yup.object().shape(fundMetricsFields());
}

export function createFundMetrics(overrides: Partial<FundMetrics> = {}): FundMetrics {
  return merge({}, fundMetricsFormSchema().getDefault(), overrides);
}

export function fundMetricsGridSchema() {
  const baseFields = fundMetricsFields();
  return yup.object().shape({
    ...baseFields,
    totalValue: baseFields.totalValue.gridMeta({
      formatter: 'usdRound',
    }),
    distributableProceeds: baseFields.distributableProceeds.gridMeta({
      formatter: 'usdRound',
    }),
    contributions: baseFields.contributions.gridMeta({
      formatter: 'usdRound',
    }),
    escrowReceivable: baseFields.escrowReceivable.gridMeta({
      formatter: 'usdRound',
    }),
    gpContributions: baseFields.gpContributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    gpROC: baseFields.gpROC.gridMeta({
      formatter: 'usdRound',
    }),
    lpContributions: baseFields.lpContributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    distributions: baseFields.distributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpDistributions: baseFields.lpDistributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpROC: baseFields.lpROC.gridMeta({
      formatter: 'usdRound',
    }),
    gpDistributions: baseFields.gpDistributions.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    managementFee: baseFields.managementFee.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    fmv: baseFields.fmv.gridMeta({
      formatter: 'usdRound',
    }),
    netAssets: baseFields.netAssets.gridMeta({
      formatter: 'usdRound',
    }),
    netReturn: baseFields.netReturn.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpNav: baseFields.lpNav.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    lpNavTotal: baseFields.lpNavTotal.gridMeta({
      formatter: 'usdRound',
    }),
    netMOIC: baseFields.netMOIC.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.moic,
    }),
    netIRR: baseFields.netIRR.gridMeta({
      formatter: 'percent2dpAsIs',
      aggFunc: KPIAggFunc.sum,
    }),
    gpIncentive: baseFields.gpIncentive.gridMeta({
      formatter: 'usdRound',
      aggFunc: KPIAggFunc.sum,
    }),
    ttvpi: baseFields.ttvpi.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.tvpi,
    }),
    dpi: baseFields.dpi.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.dpi,
    }),
    rvpi: baseFields.rvpi.gridMeta({
      formatter: 'multiplier',
      aggFunc: KPIAggFunc.rvpi,
    }),
  });
}

export function fundMetricsCalcsSchema() {
  const baseFields = fundMetricsFields();
  return yup.object().shape({
    ...baseFields,
    amountAvailableForLpGpSplit: baseFields.amountAvailableForLpGpSplit.customMeta({
      formatter: 'usd',
    }),
    contributions: baseFields.contributions.customMeta({
      formatter: 'usd',
    }),
    distributableProceeds: baseFields.distributableProceeds.customMeta({
      formatter: 'usd',
    }),
    distributions: baseFields.distributions.customMeta({
      formatter: 'usd',
    }),
    dpi: baseFields.dpi.customMeta({
      formatter: 'multiplier',
    }),
    escrowReceivable: baseFields.escrowReceivable.customMeta({
      formatter: 'usd',
    }),
    fmv: baseFields.fmv.customMeta({
      formatter: 'usd',
    }),
    gpContributions: baseFields.gpContributions.customMeta({
      formatter: 'usd',
    }),
    gpDistributions: baseFields.gpDistributions.customMeta({
      formatter: 'usd',
    }),
    gpIncentive: baseFields.gpIncentive.customMeta({
      formatter: 'usd',
    }),
    lpContributions: baseFields.lpContributions.customMeta({
      formatter: 'usd',
    }),
    lpDistributions: baseFields.lpDistributions.customMeta({
      formatter: 'usd',
    }),
    lpGpSlitAmount: baseFields.lpGpSlitAmount.customMeta({
      formatter: 'usd',
    }),
    lpNav: baseFields.lpNav.customMeta({
      formatter: 'usd',
    }),
    lpNavFromLpGpSplit: baseFields.lpNavFromLpGpSplit.customMeta({
      formatter: 'usd',
    }),
    managementFee: baseFields.managementFee.customMeta({
      formatter: 'usd',
    }),
    maxGpCatchup: baseFields.maxGpCatchup.customMeta({
      formatter: 'usd',
    }),
    navFromLpGpSplitGp: baseFields.navFromLpGpSplitGp.gridMeta({
      formatter: 'usd',
    }),
    navFromLpGpSplitLP: baseFields.navFromLpGpSplitLP.gridMeta({
      formatter: 'usd',
    }),
    navFromSuperReturnGP: baseFields.navFromSuperReturnGP.gridMeta({
      formatter: 'usd',
    }),
    navFromSuperReturnLP: baseFields.navFromSuperReturnLP.gridMeta({
      formatter: 'usd',
    }),
    netAssets: baseFields.netAssets.customMeta({
      formatter: 'usd',
    }),
    netIRR: baseFields.netIRR.customMeta({
      formatter: 'percent2dpAsIs',
    }),
    netMOIC: baseFields.netMOIC.customMeta({
      formatter: 'multiplier',
    }),
    netReturn: baseFields.netReturn.customMeta({
      formatter: 'usd',
    }),
    rvpi: baseFields.rvpi.customMeta({
      formatter: 'multiplier',
    }),
    totalValue: baseFields.totalValue.customMeta({
      formatter: 'usd',
    }),
    ttvpi: baseFields.ttvpi.customMeta({
      formatter: 'multiplier',
    }),
  });
}

export type FundMetrics = yup.InferType<ReturnType<typeof fundMetricsFormSchema>>;
