import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ColDef, GetRowIdParams, GridApi, GridReadyEvent, GridState } from 'ag-grid-community';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { motion } from 'framer-motion';
import { AgTable } from '../../../components/AgTable/AgTable';
import { ExportGridButton } from '../../../components/AgTable/ExportGridButton';
import { useExtendedExportSettings } from '../../../components/AgTable/exportToExcelDefs';
import { GenericFallbacksWrapper } from '../../../components/Fallback/GenericFallbacksWrapper';
import { MultiPeriodReportingSettings } from './MultiPeriodReportingSettings';
import { useMprReportData, useMultiPeriodReportingColumnDefs } from './multiperiodReportingGridData';
import { useSaveGridState } from './useHandleGridStateChange';

// keep only one value - will be reset when view changes as the grid rerenders
export const mpReportingGridApi = atom<GridApi | null>(null);

export function MultiPeriodGrid({
  initialState,
  id,
  isDefault,
  isShared,
  viewName,
}: {
  initialState?: GridState;
  id: number;
  isDefault: boolean;
  isShared: boolean;
  viewName?: string;
}) {
  return (
    <Stack height={'100%'} px='1rem'>
      <Settings />
      <MultiPeriodReportingGrid
        initialState={initialState}
        viewName={viewName}
        id={id}
        isDefault={isDefault}
        isShared={isShared}
      />
    </Stack>
  );
}

export const showMprSettingsModalAtom = atom(false);
function Settings() {
  const [showSettings, setShowSettings] = useAtom(showMprSettingsModalAtom);
  const gridApi = useAtomValue(mpReportingGridApi);
  const handleExport = useCallback(() => {
    gridApi?.exportDataAsExcel();
  }, [gridApi]);
  return (
    <Stack direction='row' justifyContent='end' alignItems={'center'}>
      <ExportGridButton onClick={handleExport} />
      {showSettings && (
        <GenericFallbacksWrapper>
          <MultiPeriodReportingSettings onClose={() => setShowSettings(false)} />
        </GenericFallbacksWrapper>
      )}
      <IconButton sx={{ justifySelf: 'end' }} size='large' onClick={() => setShowSettings(true)}>
        <SettingsIcon color='secondary' />
      </IconButton>
    </Stack>
  );
}

function MultiPeriodReportingGrid({
  initialState,
  id,
  isDefault,
  isShared,
  viewName,
}: {
  initialState?: GridState;
  id: number;
  isDefault: boolean;
  isShared: boolean;
  viewName?: string;
}) {
  const { rowDataMap, companyRowData } = useMprReportData();
  const colDefs = useMultiPeriodReportingColumnDefs();
  const { exportParams } = useExtendedExportSettings(new Set(['company']), viewName);
  const setGridApi = useSetAtom(mpReportingGridApi);
  const saveGridState = useSaveGridState({ id, isDefault, isShared });

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
      event.api.setGridOption('defaultColDef', defaultColDef);
      event.api.setGridOption('columnDefs', colDefs);
    },
    [colDefs, setGridApi]
  );
  const getRowId = useCallback((params: GetRowIdParams) => String(params.data?.company?.id), []);

  return (
    <>
      <MultiPeriodReportingInfo data={companyRowData} />
      <AgTable
        key={id}
        onGridReady={onGridReady}
        rowData={companyRowData}
        getRowId={getRowId}
        context={{ rowDataMap }}
        rowGroupPanelShow='never'
        defaultExcelExportParams={exportParams}
        defaultCsvExportParams={exportParams}
        suppressMovableColumns
        initialState={initialState}
        onStateUpdated={saveGridState}
      />
    </>
  );
}

const defaultColDef: ColDef = {
  // flex doesn't work with columnDef.width
  minWidth: 150,
  autoHeaderHeight: true,
  wrapHeaderText: true,
};

function MultiPeriodReportingInfo({ data }: { data?: unknown[] }) {
  if (!data) return null;
  return (
    <Box component='span' sx={{ height: 0, overflow: 'visible' }}>
      <motion.span animate={{ opacity: [0, 1] }} transition={{ duration: 0.5 }} key={data?.length}>
        <Typography
          variant='body2'
          component={'span'}
          color='text.secondary'
          display='inline-flex'
          alignItems={'start'}
          gap={'.25rem'}
          sx={{
            transform: 'translateY(-1.75rem)',
          }}
        >
          <InfoOutlinedIcon fontSize='small' />
          {`${data?.length ? 'Displaying' : 'No'} companies with available data for the selected periods`}
        </Typography>
      </motion.span>
    </Box>
  );
}
