import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useView } from '../../hooks/useView';
import { CHART_COMPONENTS } from '../../../../types';
import { ChartComponent } from './ChartComponent';

const ChartContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, 24.25%);
  column-gap: 1%; // 1% to keep the same units as for cols - ensures we don't go over 100%
  row-gap: 1rem;
`;

interface IChartsListProps {
  sectionId: string;
}

export const ChartsList = ({ sectionId }: IChartsListProps): ReactElement => {
  const { getComponentsOrder, setComponentsOrder } = useView();
  const selectedCharts = getComponentsOrder(sectionId) as CHART_COMPONENTS[];

  const handleReorder = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id !== over?.id) {
      const oldIndex = selectedCharts.indexOf(active.id as CHART_COMPONENTS);
      const newIndex = selectedCharts.indexOf(over?.id as CHART_COMPONENTS);
      const reOrderedCharts = arrayMove(selectedCharts, oldIndex, newIndex);
      setComponentsOrder(sectionId, reOrderedCharts);
    }
  };

  return (
    <ChartContainer>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleReorder}>
        <SortableContext items={selectedCharts}>
          {selectedCharts.map((chartId) => (
            <ChartComponent key={chartId} id={chartId} />
          ))}
        </SortableContext>
      </DndContext>
    </ChartContainer>
  );
};
