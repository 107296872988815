import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMatch } from 'react-router';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ROUTES, ROUTE_TITLE_MAPPING } from '../../../../../constants/RouteNameMapping';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { NavigationWrapper } from '../NavigationWrapper';
import { SubSectionNavigation } from '../../SubSectionNavigation/SubSectionNavigation';
import SubSectionNavItems from '../../SubSectionNavigation/SubSectionNavItems';
import { templateAssignmentsByCompanyIdState } from '../../../../../services/state/KPI/KPICompaniesState';
import { PermissionService } from '../../../../../services/PermissionService';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { kpiRequestsCountState } from '../../../../../services/state/KPI/KPIRequestsState';
import { KPITemplatesSubNavigation } from './KPITemplatesSubNavigation';

export const KPINavigation = () => {
  const match = useMatch(`/${ROUTES.KPI}/*`);
  const nAssigned = useRecoilValue(templateAssignmentsByCompanyIdState)?.size;
  const reqResCount = useRecoilValue(kpiRequestsCountState);
  const notSentCount = reqResCount?.notSent;
  const toReviewCount = reqResCount?.submitted;
  const permissionService = PermissionService.get();
  const { showDataCollectionModuleV2 } = useFlags<MaggieFeatureFlags>();

  const sectionsList = useMemo(() => {
    const res = [
      {
        id: `${ROUTES.KPI_REQUESTS}`,
        value: ROUTE_TITLE_MAPPING[ROUTES.KPI_REQUESTS],
        parentRoute: `${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`,
        badgeIndicator: notSentCount,
      },
      {
        id: `${ROUTES.KPI_RESPONSES}`,
        value: ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES],
        parentRoute: `${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`,
        badgeIndicator: toReviewCount,
      },
      {
        id: ROUTES.COMPANIES,
        value: ROUTE_TITLE_MAPPING[ROUTES.COMPANIES],
        badgeIndicator: nAssigned,
      },
    ];

    if (showDataCollectionModuleV2) {
      res.push({
        id: ROUTES.KPI_MANAGE,
        value: 'Manage',
        parentRoute: `${ROUTES.KPI}/${ROUTES.KPI_MANAGE}`,
        badgeIndicator: 0,
      });
    }

    return res;
  }, [nAssigned, notSentCount, showDataCollectionModuleV2, toReviewCount]);

  if (match === null || !permissionService.hasPermission(PermissionKey.canReadDataCollection)) return null;

  return (
    <NavigationWrapper title='Data Collection'>
      <SubSectionNavigation title='Management'>
        <SubSectionNavItems sectionList={sectionsList} parentRoute='kpis' />
      </SubSectionNavigation>

      <KPITemplatesSubNavigation />
    </NavigationWrapper>
  );
};
