import { css } from '@mui/material';
import { EChartsOption, EChartsType, init as echartsInit } from 'echarts';
import { useEffect, useRef } from 'react';

const chartWrapperCss = css`
  display: flex;
  height: 100%;
  width: 100%;
`;

export type EChartWrapperProps = {
  lazyUpdate?: boolean;
  notMerge?: boolean;
  onChartReady?: (chart: EChartsType) => void;
  options: EChartsOption;
};

export function EChartWrapper(props: EChartWrapperProps) {
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<EChartsType | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  // Effect to handle echarts init
  useEffect(() => {
    const { options, notMerge, lazyUpdate, onChartReady } = props;
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!chartRef.current) {
      chartRef.current = echartsInit(chartContainer.current, options);

      onChartReady?.(chartRef.current);
    } else {
      chartRef.current.setOption(options, notMerge, lazyUpdate);
    }
  }, [props]);

  // Effect to handle resizing.
  useEffect(() => {
    const container = chartContainer.current;
    const chart = chartRef.current;

    if (!container || !chart) {
      return;
    }

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          chart.resize();
        }
      }
    });

    resizeObserverRef.current.observe(container);

    return () => resizeObserverRef.current?.disconnect();
  }, []);

  return <div ref={chartContainer} css={chartWrapperCss} />;
}
