import * as yup from 'yup';
import { KPIRequestFrequency, KPIRequestStatus } from '../data-models/kpi-requests.data-model';
import { RendererType } from '../data-models/field.data-model';
import { StringFormatterId, StringFormattersConfig } from '../util/formatters/StringFormatters';
import { ISelectMeta } from '../data-models/field3.data-model';

export function kpiRequestFields() {
  return {
    archivedAt: yup.string().nullable().fieldMeta({
      formatter: 'date',
    }),
    companyId: yup.number(),
    createdAt: yup.string().nullable().fieldMeta({
      formatter: 'date',
    }),
    dueDate: yup.string().nullable().fieldMeta({
      formatter: 'date',
    }),
    frequency: yup.string().oneOf(Object.values(KPIRequestFrequency)).fieldMeta({
      formatter: StringFormatterId.capitalizedString,
    }),
    granularity: yup.string(),
    id: yup.number(),
    kpiRequestResponse: kpiResponseSchema().nullable(),
    period: yup.string(),
    respondent: yup.array(),
    sentAt: yup.string().nullable().fieldMeta({
      formatter: 'date',
    }),
    status: yup.string().oneOf(Object.values(KPIRequestStatus)).nullable().fieldMeta({
      formatter: 'capitalizedString',
    }),
    templateId: yup.number(),
    templateUuid: yup
      .string()
      .fieldMeta({
        formatter: 'kpiTemplateName',
      })
      .label('Template'),
  };
}

export function sectionDataFields() {
  return {
    sectionId: yup.number(),
    value: yup.object(),
  };
}

export function sectionDataSchema() {
  return yup.object().shape(sectionDataFields());
}

export function kpiResponseFields() {
  return {
    createdAt: yup.date(),
    id: yup.number(),
    kpiData: yup.object({
      values: yup.array(),
    }),
    respondedAt: yup.date().label('Response Date'),
    sectionData: yup.array().of(sectionDataSchema()),
    updatedAt: yup.date(),
  };
}
export function kpiResponseSchema() {
  return yup.object().shape(kpiResponseFields());
}

export type IKPIResponse = Omit<
  yup.InferType<ReturnType<typeof kpiResponseSchema>>,
  'createdAt' | 'respondedAt' | 'updatedAt'
> & {
  createdAt: string;
  respondedAt: string;
  updatedAt: string;
};

export function kpiRequestsGridSchema() {
  const {
    archivedAt,
    companyId,
    dueDate,
    frequency,
    period,
    sentAt,
    status,
    templateUuid,
    kpiRequestResponse,
  } = kpiRequestFields();
  return yup.object().shape({
    archivedAt,
    companyId: companyId.label('Company').gridMeta({
      renderer: RendererType.companyId,
    }),
    dueDate,
    templateUuid: templateUuid.gridMeta({
      formatter: 'kpiTemplateName',
      label: 'Name',
    }),
    frequency: frequency.gridMeta({
      formatter: StringFormattersConfig.capitalizedString,
    }),
    kpiRequestResponse,
    period,
    sentAt: sentAt.gridMeta({
      renderer: RendererType.date,
    }),
    status: status.gridMeta<ISelectMeta<unknown>>({
      key: 'recipientKPIStatus',
      renderer: RendererType.singleSelect,
      rendererMeta: {
        values: [
          {
            value: KPIRequestStatus.Sent,
            displayName: 'Pending',
          },
          {
            value: KPIRequestStatus.Accepted,
            displayName: 'Complete',
          },
          {
            value: KPIRequestStatus.Rejected,
            displayName: 'Rejected',
          },
          {
            value: KPIRequestStatus.Submitted,
            displayName: 'Submitted',
          },
        ],
      },
    }),
  });
}

export type IKPIRequest = yup.InferType<ReturnType<typeof kpiRequestsGridSchema>>;
