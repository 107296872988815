import * as yup from 'yup';
import { merge } from 'lodash-es';
import { dateField, integerField, usdField } from './common-schema-defs';
import { baseModelFields } from './BaseModel.schema';

export function fundDataFields() {
  return {
    date: dateField().nullable().required().default(null),
    cashlessDistributions: usdField().nullable().default(null).label('Cashless Contributions'),
    contributedSecurities: usdField().nullable().default(null).label('Contributed Securities'),
    deemedContributions: usdField().nullable().default(null).label('Deemed Contributions'),
    fundId: yup.number().nullable().required().default(null),
    gpTaxDistribution: usdField().nullable().default(null).label('GP Tax & Carry Distributions'),
    id: integerField().nullable().default(null),
    idleFundsIncome: usdField().nullable().default(null),
    managementFeeSavings: usdField().nullable().default(null),
    netAssets: usdField().nullable().default(null).label('Working Capital').customMeta({
      description: 'Cash - Total Liabilities',
    }),
  };
}

export function fundDataFormSchema() {
  const { updatedAt, ...rest } = baseModelFields();
  return yup.object({ ...fundDataFields(), updatedAt: updatedAt.label('Last Updated'), ...rest });
}

export function createFormFundData(overrides: Partial<FormFundData> = {}): FormFundData {
  return merge({}, fundDataFormSchema().getDefault(), overrides);
}

export type FundData = Omit<yup.InferType<ReturnType<typeof fundDataFormSchema>>, 'date' | 'id'> & {
  date: string;
  id: number;
};

export type FormFundData = yup.InferType<ReturnType<typeof fundDataFormSchema>>;

export function createFundData(overrides: Partial<FundData> = {}): FundData {
  return merge({}, fundDataFormSchema().getDefault(), overrides);
}
