import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IKPIRequestDataModel } from '../../../../../data-models/kpi-requests.data-model';
import { ReviewResponseDialog } from './ReviewResponseDialog';

interface IAcceptDialogProps {
  company: ICompanyDataModel;
  request: IKPIRequestDataModel;
  onCancel: () => void;
  onAccept: (comment?: string) => Promise<void>;
}
export function AcceptResponse({ request, company, onCancel, onAccept }: IAcceptDialogProps) {
  return (
    <ReviewResponseDialog
      company={company}
      request={request}
      onCancel={onCancel}
      action={'accept'}
      onSubmit={onAccept}
    />
  );
}
