import { ColDef, ColumnStateParams, IAggFunc } from 'ag-grid-community';
import { RendererType } from '../data-models/field.data-model';
import { KPIAggFunc } from '../pages/PortfolioOverview/FundReporting/FundsKPIs';
import { IDisplayField } from './display-field.view-model';

export type IGridField<T> = IDisplayField<T> &
  ColumnStateParams &
  Pick<
    ColDef,
    | 'enableRowGroup'
    | 'editable'
    | 'cellRenderer'
    | 'cellRendererParams'
    | 'cellDataType'
    | 'filter'
    | 'suppressFloatingFilterButton'
    | 'sortable'
  > & {
    aggFunc?: string | IAggFunc | null | KPIAggFunc;
  };

export function createGridField<T>(overrides: Partial<IGridField<T>> = {}): IGridField<T> {
  return {
    editable: false,
    enableRowGroup: false,
    key: '',
    renderer: RendererType.text,
    ...overrides,
  };
}
