import { CashFlow } from '@webcarrot/xirr';
import { MetricsTransactionDataModel } from '../schemas/MetricsTransaction.schema';
import { KpiPeriod, KpiSection } from './company-financials.data-model';
import { createCompanyDataModel, ICompanyDataModel } from './company.data-model';
import { CustomData } from './field2.data-model';

export interface IMetricsDataModel {
  acquisitionDate: string | null;
  acquisitionShares: number;
  activeStatus: string;
  amountInvested: number;
  budgetCashBalance: number;
  budgetCashBurn: number;
  budgetEbitda: number;
  budgetGrossMargin: number;
  budgetGrossProfit: number;
  budgetNetIncome: number;
  budgetOperatingIncome: number;
  budgetRevenue: number;
  budgetTotalDebt: number;
  budgetWorkingCapital: number;
  cashBalance: number;
  cashBurn: number;
  cashBurnRate: number;
  cashflows: CashFlow[];
  cashRunway: string;
  companyId: number;
  company?: ICompanyDataModel;
  cy2CashBalance: number;
  cy2CashBurn: number;
  cy2Ebitda: number;
  cy2GrossProfit: number;
  cy2NetIncome: number;
  cy2OperatingIncome: number;
  cy2Revenue: number;
  cy2TotalDebt: number;
  cy2WorkingCapital: number;
  cyCashBalance: number;
  cyCashBurn: number;
  cyEbitda: number;
  cyGrossProfit: number;
  cyNetIncome: number;
  cyOperatingIncome: number;
  cyRevenue: number;
  cyTotalDebt: number;
  cyWorkingCapital: number;
  dilutionAssumption: number;
  distributions: number;
  dpi: number;
  ebitda: number;
  entryOwnership: number | null;
  equityFmv?: number | null;
  escrowAmount: number;
  exitDate?: string;
  exitOwnershipPercentage: number | null;
  exitType: string | null;
  exitValuation: number | null;
  expenses: number;
  fmv: number;
  followOnInvestment: number;
  fullTimeEmployees: number;
  fund: string;
  fundData: IMetricFundDataModel[];
  fundType?: string[];
  grossProfit: number;
  impliedEquityValue: number | null | undefined;
  initialInvestment: number;
  initialInvestmentDate: string;
  initialInvestmentRound: string;
  initialPostMoney?: number | null;
  stockProceeds?: number;
  initialPreMoney: number;
  initialSharesHeld: number;
  initialSharePrice: number;
  initialSharesReceiptDate: string | null;
  interest: number;
  investmentAge: number;
  investmentStatus: string;
  irr: number;
  kpiData: Record<
    number,
    Record<KpiPeriod | 'L3M' | 'L6M', Record<KpiSection, { date: string; value: number }>>
  >;
  kpiDataExtra?: {
    capEfficiency?: number;
    cashFromOpsL6m?: number;
    ebitdaStandardL6m?: number;
    metricL6m?: number;
    revenueGrowthL3mYoy?: number;
    totalRevenueNetChangeL6m?: number;
  };
  kpiLastReportedDateActual?: string;
  kpiLastReportedDateBudget?: string;
  lastPostMoneyRound: number | null | undefined;
  ltmCashBalance: number;
  ltmCashBurn: number;
  ltmEbitda: number;
  ltmGrossProfit: number;
  ltmNetIncome: number;
  ltmOperatingIncome: number;
  ltmRevenue: number;
  ltmTotalDebt: number;
  ltmWorkingCapital: number;
  moic: number | undefined;
  monthlygrossMargin: number;
  monthlyRevenueGrowth: number;
  navPercentage: number;
  netIncome: number;
  noOfShares: number;
  normalizedMostRecentStage?: string | null;
  operatingIncome: number;
  ownerShipPercentage?: number | null;
  participatingMostRecentFinancingAmount: number;
  participatingLastRoundSharePrice: number;
  participatingLastRoundSharePriceDate: string | null;
  participatingLastRoundSeries: string;
  pricePerShare: number;
  projectedMOIC: number;
  projectedProceeds: number;
  realizedGL: number;
  adjustedRealizedGL: number;
  adjustedUnrealizedGL: number;
  realizedMOIC: number;
  unrealizedMOIC: number;
  adjustedRealizedMOIC: number;
  adjustedUnrealizedMOIC: number;
  realizedValue: number;
  reserves: number;
  rvpi: number;
  seedCore: string;
  totalCost: number;
  realizedCost: number;
  unrealizedCost: number;
  totalDebt: number;
  totalImpliedEquityValue: number | null | undefined;
  totalReturn: number;
  totalRevenue?: number;
  totalValue: number;
  transactions: MetricsTransactionDataModel[];
  tvpi: number;
  unrealizedGL: number;
  unrealizedValue: number;
  workingCapital: number;
  yearlygrossMargin: number;
  yearlyRevenueGrowth: number;
  convertedSharesHeld: number | null;
  convertedOwnership: number | null;
  percentOfFund?: number;
  percentTotalValue?: number;
  realizedAmountWithEscrow: number;
  rtfe?: number;
}

export interface ICompanyMetricsDataModel extends IMetricsDataModel {
  company: ICompanyDataModel;
}

export function createCompanyMetricsDataModel(
  overrides: Partial<ICompanyMetricsDataModel> = {}
): ICompanyMetricsDataModel {
  return {
    ...createMetricsDataModel(),
    company: createCompanyDataModel(),
    companyId: 0,
    ...overrides,
  };
}

export interface IFundDataMetricFieldsDataModel {
  dilutionAssumption: number;
  reserves: number;
  initialPreMoney: number;
  acquisitionShares: number;
  acquisitionDate: string | null;
  convertedOwnership: number;
  entryOwnership: number | null;
  ownerShipPercentage: number | null;
  projectedProceeds: number;
  projectedMOIC: number;
  exitOwnershipPercentage: number | null;
  fmvBasis: number | null;
  exitType: string | null;
  exitValuation: number | null;
  exitTypes: string | null;
  exitValuations: number | null;
  _customFundFields: Record<string, unknown>;
}

export function createFundDataMetricsFieldsDataModel(
  overrides: Partial<IFundDataMetricFieldsDataModel> = {}
): IFundDataMetricFieldsDataModel {
  return {
    dilutionAssumption: 0,
    reserves: 0,
    initialPreMoney: 0,
    acquisitionShares: 0,
    acquisitionDate: null,
    convertedOwnership: 0,
    ownerShipPercentage: 0,
    projectedProceeds: 0,
    projectedMOIC: 0,
    exitOwnershipPercentage: null,
    fmvBasis: 0,
    exitType: null,
    exitValuation: 0,
    entryOwnership: 0,
    exitTypes: null,
    exitValuations: null,
    _customFundFields: {},
    ...overrides,
  };
}

export interface IMetricFundDataModel {
  acquisitionDate?: string | null;
  acquisitionShares?: number;
  amountInvested?: number;
  companyId: number;
  convertedOwnership: number | null;
  convertedSharesHeld: number | null;
  customData?: CustomData;
  dilutionAssumption?: number;
  distributions: number;
  entryOwnership?: number | null;
  exitDate?: string | null;
  exitOwnership: number | null;
  exitOwnershipPercentage?: number | null;
  exitType: string | null;
  exitValuation: number | null;
  fmv?: number;
  fmvBasis?: number;
  fundId: number;
  id: number;
  impliedEquityValue?: number | null;
  initialInvestmentRound: string;
  initialPreMoney?: number;
  initialSharePrice?: number;
  initialSharesHeld?: number;
  irr?: number;
  moic?: number;
  noOfShares?: number;
  ownerShipPercentage?: number | null;
  ownershipSource?: string | null;
  percentOfFund?: number;
  percentTotalValue?: number;
  projectedExitTiming?: string | null;
  projectedMOIC?: number;
  projectedProceeds?: number;
  reserves?: number;
  rtfe?: number;
  initialPostMoney?: number | null;
  stockProceeds?: number;
  initialInvestment?: number;
}

export function createMetricsFundDataModel(
  overrides: Partial<IMetricFundDataModel> = {}
): IMetricFundDataModel {
  return {
    acquisitionDate: null,
    acquisitionShares: 0,
    amountInvested: 0,
    companyId: 0,
    initialInvestmentRound: '',
    initialInvestment: 0,
    convertedSharesHeld: null,
    convertedOwnership: null,
    dilutionAssumption: 0,
    distributions: 0,
    entryOwnership: 0,
    exitOwnership: 0,
    exitOwnershipPercentage: null,
    exitType: null,
    exitValuation: 0,
    fmv: 0,
    fmvBasis: 0,
    fundId: 0,
    id: 0,
    initialPreMoney: 0,
    irr: 0,
    noOfShares: 0,
    ownerShipPercentage: 0,
    ownershipSource: null,
    projectedExitTiming: null,
    projectedMOIC: 0,
    projectedProceeds: 0,
    reserves: 0,
    percentOfFund: 0,
    percentTotalValue: 0,
    rtfe: 0,
    stockProceeds: 0,
    ...overrides,
  };
}

export function createMetricsDataModel(overrides: Partial<IMetricsDataModel> = {}): IMetricsDataModel {
  return {
    acquisitionDate: null,
    acquisitionShares: 0,
    activeStatus: '',
    amountInvested: 0,
    budgetCashBalance: 0,
    budgetCashBurn: 0,
    budgetEbitda: 0,
    budgetGrossMargin: 0,
    budgetGrossProfit: 0,
    budgetNetIncome: 0,
    budgetOperatingIncome: 0,
    budgetRevenue: 0,
    budgetTotalDebt: 0,
    budgetWorkingCapital: 0,
    cashBalance: 0,
    cashBurn: 0,
    cashBurnRate: 0,
    cashflows: [],
    cashRunway: '',
    companyId: 0,
    cy2CashBalance: 0,
    cy2CashBurn: 0,
    cy2Ebitda: 0,
    cy2GrossProfit: 0,
    cy2NetIncome: 0,
    cy2OperatingIncome: 0,
    cy2Revenue: 0,
    cy2TotalDebt: 0,
    cy2WorkingCapital: 0,
    cyCashBalance: 0,
    cyCashBurn: 0,
    cyEbitda: 0,
    cyGrossProfit: 0,
    cyNetIncome: 0,
    cyOperatingIncome: 0,
    cyRevenue: 0,
    cyTotalDebt: 0,
    cyWorkingCapital: 0,
    dilutionAssumption: 0,
    distributions: 0,
    dpi: 0,
    ebitda: 0,
    entryOwnership: 0,
    equityFmv: 0,
    escrowAmount: 0,
    exitDate: '',
    exitOwnershipPercentage: 0,
    initialSharesReceiptDate: null,
    exitType: '',
    exitValuation: 0,
    expenses: 0,
    fmv: 0,
    followOnInvestment: 0,
    fullTimeEmployees: 0,
    fund: '',
    fundData: [],
    grossProfit: 0,
    impliedEquityValue: 0,
    initialInvestment: 0,
    initialInvestmentDate: '',
    initialInvestmentRound: '',
    initialPreMoney: 0,
    initialSharesHeld: 0,
    initialSharePrice: 0,
    interest: 0,
    investmentAge: 0,
    investmentStatus: '',
    irr: 0,
    kpiData: {},
    lastPostMoneyRound: null,
    ltmCashBalance: 0,
    ltmCashBurn: 0,
    ltmEbitda: 0,
    ltmGrossProfit: 0,
    ltmNetIncome: 0,
    ltmOperatingIncome: 0,
    ltmRevenue: 0,
    ltmTotalDebt: 0,
    ltmWorkingCapital: 0,
    moic: 0,
    monthlygrossMargin: 0,
    monthlyRevenueGrowth: 0,
    navPercentage: 0,
    netIncome: 0,
    noOfShares: 0,
    operatingIncome: 0,
    ownerShipPercentage: null,
    participatingMostRecentFinancingAmount: 0,
    participatingLastRoundSharePrice: 0,
    participatingLastRoundSharePriceDate: '',
    participatingLastRoundSeries: '',
    pricePerShare: 0,
    projectedMOIC: 0,
    projectedProceeds: 0,
    realizedGL: 0,
    adjustedUnrealizedGL: 0,
    adjustedRealizedGL: 0,
    realizedMOIC: 0,
    unrealizedMOIC: 0,
    adjustedRealizedMOIC: 0,
    adjustedUnrealizedMOIC: 0,
    realizedValue: 0,
    reserves: 0,
    rvpi: 0,
    seedCore: '',
    totalCost: 0,
    realizedCost: 0,
    unrealizedCost: 0,
    totalDebt: 0,
    totalImpliedEquityValue: 0,
    totalReturn: 0,
    totalRevenue: 0,
    totalValue: 0,
    transactions: [],
    tvpi: 0,
    unrealizedGL: 0,
    unrealizedValue: 0,
    workingCapital: 0,
    yearlygrossMargin: 0,
    yearlyRevenueGrowth: 0,
    convertedSharesHeld: null,
    convertedOwnership: null,
    percentOfFund: 0,
    percentTotalValue: 0,
    rtfe: 0,
    stockProceeds: 0,
    realizedAmountWithEscrow: 0,
    ...overrides,
  };
}

export interface IMetricsResponseDataModel {
  signedUrl?: string;
  included: ICompanyDataModel[];
  totalFMV: number;
  totalIRR: number;
  totalCashflows: CashFlow[];
  page: number;
  pageSize: number;
  totalPages: number;
  metrics: IMetricsDataModel[];
}

export function createMetricsResponseDataModel(
  overrides: Partial<IMetricsResponseDataModel> = {}
): IMetricsResponseDataModel {
  return {
    signedUrl: '',
    included: [],
    totalFMV: 0,
    totalIRR: 0,
    totalCashflows: [],
    page: 0,
    pageSize: 0,
    totalPages: 0,
    metrics: [],
    ...overrides,
  };
}
