import { ColDef } from 'ag-grid-community';
import { DrillDownRenderer } from '../../TypeBasedConfigs/TypeCellRenderers/DrillDownRenderer';
import { ColumnMeta, PredefinedColumn } from '../../../../../../../types';

export const DrillDownColId = 'DrillDown';

export class DrillDown implements PredefinedColumn {
  public static NAME = DrillDownColId;

  private meta: ColumnMeta = {
    default: true,
    displayName: 'Drill Down',
    groupName: 'Company Details',
    id: Math.ceil(Math.random() * 10000000),
    name: DrillDown.NAME,
    path: '',
    visible: true,
  } as ColumnMeta;

  private definitions: ColDef = {
    cellRenderer: DrillDownRenderer,
    cellRendererParams: { showDrillDownBtn: true },
    colId: DrillDownColId,
    headerName: 'Drill Down',
    hide: false,
    maxWidth: 70,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    minWidth: 70,
    pinned: 'right',
    filter: false,
  };

  getMeta() {
    return this.meta;
  }
  getDefinition() {
    return this.definitions;
  }
}
