import { Typography } from '@mui/material';
import { atom, useAtomValue } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { Loader } from '../../../../../components/Loader/Loader';
import { LoadingStatus } from '../../../../../types';
import { useAsync } from '../../../../../util/hook-utils';
import {
  KPIReviewState,
  loadReviewerViewInitialData,
  ReviewerViewData,
  useKPIReviewerActions,
} from './hooks/useKPIRequestReviewActions';
import { KPIRenderedTemplate } from './KPIRenderedTemplate';
import { KPIReviewHeader } from './KPIReviewHeader';
import {
  Background,
  headerWrapperCSS,
  headerWrapperInnerCSS,
  kpiContentsInnerWrapperCss,
  kpiContentsWrapperCss,
  statusWrapperCss,
} from './KpiViewCommonStyles';
import { ReviewResponseDialog } from './ReviewResponseDialog';
import { KpiResponseStatus } from './KpiResponseStatus';

export function KPIReviewerView() {
  const { requestId } = useParams<{ requestId: string }>();
  const { error, loading, data } = useAsync(
    useCallback(() => loadReviewerViewInitialData(Number(requestId)), [requestId])
  );

  switch (loading) {
    case LoadingStatus.loading:
      return <Loader />;

    case LoadingStatus.error:
      return <Typography>{error}</Typography>;

    case LoadingStatus.success:
    default:
      return <Layout initialData={data} />;
  }
}

type LayoutProps = {
  initialData: ReviewerViewData;
};

function Layout(props: LayoutProps) {
  const componentState = useMemo(
    () =>
      atom<KPIReviewState>({
        ...props.initialData,
        isModified: false,
        isSubmitting: false,
        submissionError: null,
      }),
    [props.initialData]
  );
  const { currentRequest, currentResponse, currentTemplate, isModified, isSubmitting } =
    useAtomValue(componentState);

  const { onSectionDataUpdated, onKPIGridDataUpdated, rejectResponse, acceptResponse } =
    useKPIReviewerActions(componentState);

  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);

  const handleAccept = useCallback(
    async (comment?: string) => {
      await acceptResponse(comment);
      setShowAcceptDialog(false);
    },
    [acceptResponse]
  );

  const handleReject = useCallback(
    async (comment?: string) => {
      await rejectResponse(comment);
      setShowRejectDialog(false);
    },
    [rejectResponse]
  );

  return (
    <Background>
      {showAcceptDialog && (
        <ReviewResponseDialog
          company={props.initialData.company}
          request={currentRequest}
          onSubmit={handleAccept}
          onCancel={() => setShowAcceptDialog(false)}
          action='accept'
        />
      )}
      {showRejectDialog && (
        <ReviewResponseDialog
          company={props.initialData.company}
          request={currentRequest}
          onSubmit={handleReject}
          onCancel={() => setShowRejectDialog(false)}
          action='reject'
        />
      )}
      <div css={headerWrapperCSS}>
        <div css={headerWrapperInnerCSS}>
          <KPIReviewHeader
            company={props.initialData.company}
            isSubmitting={isSubmitting}
            kpiRequest={currentRequest}
            onAcceptResponse={() => setShowAcceptDialog(true)}
            onRejectResponse={() => setShowRejectDialog(true)}
            isModified={isModified}
          />
          <div css={statusWrapperCss}>
            <KpiResponseStatus
              status={props.initialData.currentRequest.status}
              acceptMessage={props.initialData.currentRequest.acceptMessage}
              rejectReason={props.initialData.currentRequest.rejectReason}
            />
          </div>
        </div>
      </div>
      <div css={kpiContentsWrapperCss}>
        <div css={kpiContentsInnerWrapperCss}>
          <GenericFallbacksWrapper>
            {currentTemplate ? (
              <KPIRenderedTemplate
                onKPIGridDataUpdated={onKPIGridDataUpdated}
                onSectionDataUpdated={onSectionDataUpdated}
                request={currentRequest}
                response={currentResponse}
                template={currentTemplate}
              />
            ) : (
              <Typography>Invalid Template</Typography>
            )}
          </GenericFallbacksWrapper>
        </div>
      </div>
    </Background>
  );
}
