import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { FormLoadingButton } from '../../../components/Form/FormComponents';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { FormFundData, FundData, fundDataFormSchema } from '../../../schemas/FundData.schema';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { schemaToFormFields } from '../../../util/schema-utils';
import {
  fundDataByDateState,
  fundDataSortedByDateDescStateFP,
  isEditingFundDataState,
  selectedDateFPState,
} from '../state/FPState';
import {
  editableFundDataFields,
  editableFundDataFields2,
  getInitialFundDataForDate,
  useResetOnDateChange,
  useShouldDisableSubmit,
  useSubmitFundData,
} from './FundDataFormActions';

export function EditFundData({ fundId }: { fundId: number }) {
  const setIsEditing = useSetAtom(isEditingFundDataState);
  const dataByDate = useAtomValue(fundDataByDateState(fundId));
  const selectedDate = useAtomValue(selectedDateFPState);
  const dataSortedByDate = useAtomValue(fundDataSortedByDateDescStateFP(fundId));
  const initialValue = useMemo(() => {
    return getInitialFundDataForDate(selectedDate, dataByDate, dataSortedByDate);
  }, [dataByDate, dataSortedByDate, selectedDate]);

  const methods = useForm<FormFundData>({
    defaultValues: { ...initialValue, date: selectedDate },
    resolver: yupResolver(fundDataFormSchema()),
  });

  const disabled = useShouldDisableSubmit({ methods, dataByDate });
  const onSubmit = useSubmitFundData({ methods, dataByDate, fundId });
  const [loading, setLoading] = useState(false);
  const _onSubmit = useCallback(async () => {
    setLoading(true);
    const data = await onSubmit();
    if (data) methods.reset({ ...data } as unknown as FormFundData);
    setLoading(false);
  }, [methods, onSubmit]);

  return (
    <FormProvider {...methods}>
      <Stack width='100%' height='100%'>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography>{`Edit Fund Data`}</Typography>
          <Stack direction={'row'} gap={'1rem'}>
            <Button variant={'outlined'} color={'secondary'} onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <FormLoadingButton disabled={disabled} onClick={_onSubmit} loading={loading}>
              Save Changes
            </FormLoadingButton>
          </Stack>
        </Stack>
        <FundDataFields dataByDate={dataByDate} methods={methods} fundId={fundId} />
      </Stack>
    </FormProvider>
  );
}

interface FundDataFieldsProps {
  dataByDate: Map<string, FundData>;
  methods: UseFormReturn<FormFundData>;
  fundId: number;
}
export function FundDataFields({ dataByDate, methods, fundId }: FundDataFieldsProps) {
  const render = useResetOnDateChange({ methods, dataByDate, fundId });
  const { showFundDataForm2 } = useFlags<MaggieFeatureFlags>();
  const fieldPaths = useMemo(() => {
    if (showFundDataForm2) {
      return ['date', ...editableFundDataFields2];
    } else {
      return ['date', ...editableFundDataFields];
    }
  }, [showFundDataForm2]);

  const fields = useMemo(() => {
    return schemaToFormFields(fundDataFormSchema().pick(fieldPaths as (keyof FundData)[]));
  }, [fieldPaths]);

  return (
    <Stack
      mt='2rem'
      display={'grid'}
      gridTemplateColumns={'repeat(auto-fit, minmax(10rem, 1fr))'}
      gap={'0.5rem'}
      sx={{ '& .field-label': { marginBottom: '-0.4rem' } }}
    >
      {fields.map((field) => (
        <FormFieldWithLabelFactory
          key={field.key === 'date' ? field.key : `${field.key}-${render}`}
          formField={field}
        />
      ))}
      <Alert severity='warning' style={{ gridColumn: '1 / -1' }}>
        Changes to these parameters will affect data displayed in Fund Reporting
      </Alert>
    </Stack>
  );
}
