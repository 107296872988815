import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { noop, pick } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { ICompanyDataModel } from '../../../data-models/company.data-model';
import { field2ToFormField, FieldEntity, FormOptions } from '../../../data-models/field2.data-model';
import { IListDataModel } from '../../../data-models/list.data-model';
import { PanelPreferences } from '../../../data-models/preferences-v3.data-model';
import { companyFields } from '../../../schemas/Company.schema';
import { customFieldsByEntity } from '../../../services/state/AdminPanel/CustomFieldsState';
import { schemaToFormFields } from '../../../util/schema-utils';
import { createFormFromFieldsArray } from '../../../view-models/form.view-model';
import { useDebounceCompanyUpdates } from '../../CompanyProfiles2/Summary/useDebounceCompanyUpdates';

const FieldsWrapperCss = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: min-content;
`;

type CompanyDetailsViewModel = Partial<ICompanyDataModel> & { owner: string | null };

interface CompanyDetailsProps {
  company: ICompanyDataModel;
  activeList: IListDataModel;
}

export function CompanyDetails2(props: CompanyDetailsProps) {
  const companyDetailsPanelPref = { selectedFields: [] };
  const companyCustomFieldsPref = { selectedFields: [] };

  return (
    <Box flexDirection={'column'}>
      <CompanyDetailsPanel
        activeList={props.activeList}
        company={props.company}
        preferences={companyDetailsPanelPref}
      />
      <CompanyCustomFieldsPanel
        activeList={props.activeList}
        company={props.company}
        preferences={companyCustomFieldsPref}
      />
    </Box>
  );
}

function CompanyDetailsPanel(props: CompanyDetailsProps & { preferences: PanelPreferences }) {
  const { company } = props;
  const updateCompany = useDebounceCompanyUpdates(company.id);
  const form = useCompanyDetailsViewModel(company);
  const formMethods = useForm({
    values: company,
  });

  function onChange(data: Partial<CompanyDetailsViewModel>) {
    updateCompany(data);
  }

  return (
    <FormProvider {...formMethods}>
      <div css={FieldsWrapperCss}>
        <FormFactoryNoLayout form={form} onChange={onChange} changeDebounce={500} />
      </div>
    </FormProvider>
  );
}

function useCompanyDetailsViewModel(company: ICompanyDataModel) {
  const editableFields = new Set(['assignee']);
  const totalRaisedField = company.companyType === 'portfolio' ? 'raisedToDate' : 'totalPIC';
  const companyDetailFields = pick(companyFields(), [
    'assignee',
    'sectorId',
    'website',
    'ceoName',
    'founderName',
    'founded',
    'primaryLocation',
    totalRaisedField,
    'latestFundingRound',
    'latestFundingDate',
  ]);
  const formFields = schemaToFormFields(yup.object(companyDetailFields));

  formFields.forEach((field) => {
    field.disabled = !editableFields.has(field.key);
  });

  return createFormFromFieldsArray<CompanyDetailsViewModel>(formFields, { variant: 'form-inline' });
}

function CompanyCustomFieldsPanel(props: CompanyDetailsProps & { preferences: PanelPreferences }) {
  const form = useCompanyCustomFieldsViewModel();
  const formMethods = useForm({
    values: props.company,
  });

  return (
    <FormProvider {...formMethods}>
      <div css={FieldsWrapperCss}>
        <FormFactoryNoLayout form={form} onChange={noop} />
      </div>
    </FormProvider>
  );
}

function useCompanyCustomFieldsViewModel() {
  const customFields = useRecoilValue(customFieldsByEntity);
  const companyCustomFormFields = (customFields.get(FieldEntity.company) ?? [])
    .filter((field) => {
      return field.systemMeta.formRefs.includes(FormOptions.companyLists);
    })
    .map((field) => {
      return {
        ...field2ToFormField(field, {
          disabled: true,
        }),
      };
    });

  return createFormFromFieldsArray<CompanyDetailsViewModel>(companyCustomFormFields, {
    variant: 'form-inline',
  });
}
