import {
  IMultiPeriodReportingRequest,
  IMultiPeriodReportResponse,
} from '../../pages/PortfolioOverview/MultiPeriodReporting/MultiPeriodReporting.schemas';
import { MaggieHttp } from '../MaggieHttp';

export async function getMultiPeriodReport(viewPayload: IMultiPeriodReportingRequest) {
  const { data } = await MaggieHttp.maggie.post<IMultiPeriodReportingRequest, IMultiPeriodReportResponse>(
    '/kpiCustomData/multi',
    viewPayload
  );
  return data;
}
