import { ColumnState } from 'ag-grid-community';
import { customLabelsCalculator } from '../../../../client-customizations/greycroft/metric-formulas';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { IFundDataModel } from '../../../../data-models/fund.data-model';
import { IMetricsConfigDataModel } from '../../../../data-models/metrics-config.data-model';
import { IMetricFundDataModel, IMetricsDataModel } from '../../../../data-models/metrics.data-model';
import { IRoundDataModel } from '../../../../data-models/round.data-model';
import {
  isEscrowTransactionType,
  isExpenseTransaction,
  isInterestOrExpenseTransaction,
  isInterestTransaction,
  isRealizedCostTransactionType,
  isRestructureTransactionType,
  isReturnOfCapitalType,
} from '../../../../data-models/transaction.data-model';
import { MetricsTransactionDataModel } from '../../../../schemas/MetricsTransaction.schema';
import { ColumnMeta } from '../../../../types';
import { calculateXIRR } from '../../providers/calculateXIRR';
import { fundsByIdMapAtom } from '../../../../services/state/AppConfigStateJ';
import { getForesightStore } from '../../../../util/jotai-store';
import { NoGroupFundLabel } from '../../../../util/formatters/FundFormatter';
import { Fund } from '../../../../schemas/Fund.schema';
import { CalculatedMetricsDataModel, recalculateMetrics } from './CompanyMetricsCalculator';
import { MetricsFundDataFieldsCalculator } from './MetricsFundDataFieldsCalculator';

const ACCOUNTING_MIN_ZERO_VALUE = 5; // Anything below that is considered 0 for transactions.

export class MetricsCalculator {
  static COLUMNS: Record<string, string> = {
    Fund: 'Fund',
    FundType: 'fundType',
    InvestmentStatus: 'InvestmentStatus', // colId (coming from columnMeta.name), not path
  };

  #useMetricsCalculatorV2: boolean;
  #fundsById: Map<number, IFundDataModel> = new Map();

  constructor(useMetricsCalculatorV2 = false) {
    this.#useMetricsCalculatorV2 = useMetricsCalculatorV2;
    this.#fundsById = getForesightStore().get(fundsByIdMapAtom);
  }

  run(
    fundsMap: Map<number, IFundDataModel>,
    roundsMap: Map<number, IRoundDataModel>,
    metrics: IMetricsDataModel[],
    groups: ColumnState[],
    asOfDate: Date | null,
    getCompany: (id: number) => ICompanyDataModel | null,
    metricsConfig: IMetricsConfigDataModel | null,
    customFundFields: ColumnMeta[] = []
  ): CalculatedMetricsDataModel[] {
    const isGroupedByFund = this.hasGroupByFund(groups);
    let metricsData = metrics;
    if (isGroupedByFund) {
      metricsData = this.splitMetricsByFund(metrics);
    } else if (this.hasGroupByFundType(groups)) {
      metricsData = splitMetricsByFundType(metrics, this.#fundsById);
    }
    const isGroupedByInvestmentStatus = this.hasGroupByInvestmentStatus(groups);

    const res = this.#useMetricsCalculatorV2
      ? recalculateMetrics(
          metricsData,
          fundsMap,
          roundsMap,
          asOfDate || new Date(),
          isGroupedByFund,
          getCompany,
          customFundFields
        )
      : this.recalculateKpis(
          fundsMap,
          roundsMap,
          metricsData,
          asOfDate || new Date(),
          isGroupedByFund,
          getCompany,
          metricsConfig,
          customFundFields,
          isGroupedByInvestmentStatus
        );

    return res.filter(Boolean) as CalculatedMetricsDataModel[];
  }

  public recalculateKpis(
    fundsMap: Map<number, IFundDataModel>,
    roundsMap: Map<number, IRoundDataModel>,
    metrics: IMetricsDataModel[],
    asOfDate: Date,
    isGroupedByFund: boolean,
    getCompany: (companyId: number) => ICompanyDataModel | null,
    metricsConfig: IMetricsConfigDataModel | null,
    customFundFields: ColumnMeta[],
    isGroupedByInvestmentStatus: boolean
  ): (IMetricsDataModel | null)[] {
    const outputMetrics = metrics.map((metric) => {
      const numericalKpis = {
        amountInvested: 0,
        distributions: 0,
        noOfShares: 0,
        escrowAmount: 0,
        realizedGLWithEscrow: 0,
        realizedAmountWithEscrow: 0,
        expenses: 0,
        interest: 0,
        equityFmv: 0,
        initialSharesReceiptDate: '',
        fmv: 0,
        initialInvestment: 0,
        investmentAge: 0,
        realizedValue: 0,
        totalValue: 0,
        totalReturn: 0,
        realizedMOIC: 0,
        unrealizedMOIC: 0,
        adjustedRealizedMOIC: 0,
        adjustedUnrealizedMOIC: 0,
        pricePerShare: 0,
        investmentStatus: metric.investmentStatus,
        participatingMostRecentFinancingAmount: 0,
        participatingLastRoundSharePrice: 0,
        initialSharesHeld: 0,
        initialSharePrice: 0,
        totalCost: 0,
        realizedCost: 0,
        unrealizedCost: 0,
        adjustedRealizedGL: 0,
        adjustedUnrealizedGL: 0,
        percentOfFund: metric.percentOfFund ?? 0,
        percentTotalValue: metric.percentTotalValue ?? 0,
        rtfe: metric.rtfe ?? 0,
        initialPostMoney: metric?.initialPostMoney ?? 0,
        stockProceeds: metric.stockProceeds ?? 0,
        realizedMOICWithEscrow: 0,
      };

      const textualKpi: Record<string, string> = {
        initialInvestmentRound: metric.initialInvestmentRound ?? '',
        initialInvestmentDate: '',
        participatingLastRoundSeries: '',
        participatingLastRoundSharePriceDate: '',
        exitDate: '',
      };

      if (isGroupedByFund) {
        textualKpi.initialInvestmentRound = metric.fundData[0]?.initialInvestmentRound ?? '';
        numericalKpis.percentOfFund = metric.fundData[0]?.percentOfFund ?? 0;
        numericalKpis.percentTotalValue = metric.fundData[0]?.percentTotalValue ?? 0;
        numericalKpis.rtfe = metric.fundData[0]?.rtfe ?? 0;
        numericalKpis.initialPostMoney = metric.fundData[0]?.initialPostMoney ?? 0;
        numericalKpis.stockProceeds = metric.fundData[0]?.stockProceeds ?? 0;
      }

      const funds = new Set();
      const fundsInMetrics = new Set<number>();

      let firstRoc = '';
      let zeroRoc = '';
      let restructureAmount = 0;
      const _company = getCompany(metric.companyId);
      const isMerged = _company?.isMerged ?? false;

      const initialTransaction = findInitialTransaction(metric.transactions);
      if (initialTransaction) {
        textualKpi.initialInvestmentDate = initialTransaction.transactionDate;
        numericalKpis.initialSharePrice = initialTransaction.pps ?? 0;
        numericalKpis.initialSharesHeld = initialTransaction.quantity;
        numericalKpis.investmentAge =
          asOfDate.getFullYear() - new Date(initialTransaction.transactionDate).getFullYear();
        numericalKpis.initialInvestment = initialTransaction.investmentAmount;
      }

      metric.transactions?.forEach((transaction) => {
        numericalKpis.amountInvested += transaction.investmentAmount ?? 0;
        numericalKpis.distributions += transaction.distributions ?? 0;
        numericalKpis.fmv += transaction.currentInvestment ?? 0;

        if (transaction.quantity > 0 && !numericalKpis.initialSharesReceiptDate) {
          numericalKpis.initialSharesReceiptDate = transaction.transactionDate;
        }

        if (!isNoteOrSafeTransaction(transaction)) {
          numericalKpis.equityFmv += transaction.currentInvestment ?? 0;
          numericalKpis.equityFmv = Number(numericalKpis.equityFmv.toFixed(4));
        }

        if (transaction.pps) {
          numericalKpis.pricePerShare = transaction.pps;
        }

        if (isEscrowTransactionType(transaction.transType)) {
          numericalKpis.escrowAmount += transaction.amount ?? 0;
        }

        if (isRealizedCostTransactionType(transaction.transType)) {
          numericalKpis.realizedCost -= transaction.amount ?? 0;
        }

        if (isRestructureTransactionType(transaction.transType)) {
          restructureAmount += transaction.amount ?? 0;
        }

        if (Number.isFinite(transaction.fundId) && fundsMap.has(transaction.fundId!)) {
          const fund = fundsMap.get(transaction.fundId!)!;
          funds.add(fund.name);
          fundsInMetrics.add(transaction.fundId!);
        }

        let acq = false;
        if (transaction.restructureId && transaction.dealId && !isMerged) {
          const dealId = transaction.restructureId.split('.').pop();
          if (dealId !== undefined && dealId !== transaction.dealId) {
            acq = true;
          }
        }
        if (!acq) {
          numericalKpis.noOfShares += transaction.quantity;
        }

        const _isReturnOfCapital = isReturnOfCapitalType(transaction.transType);
        if (_isReturnOfCapital && transaction.quantity <= 0) {
          if (!firstRoc) {
            firstRoc = transaction.transactionDate;
          }
          if (numericalKpis.noOfShares <= 0 && !zeroRoc) {
            zeroRoc = transaction.transactionDate;
          }
        }

        numericalKpis.expenses += isExpenseTransaction(transaction) ? transaction.amount : 0;
        numericalKpis.interest += isInterestTransaction(transaction) ? transaction.amount : 0;
        if (isInterestOrExpenseTransaction(transaction)) {
          numericalKpis.realizedValue -= transaction.amount || 0;
        }
        numericalKpis.realizedValue -= _isReturnOfCapital ? transaction.amount : 0;
      });

      numericalKpis.realizedAmountWithEscrow = numericalKpis.distributions + numericalKpis.escrowAmount;

      if (numericalKpis.fmv < 0) {
        numericalKpis.fmv = 0;
      }
      if (numericalKpis.equityFmv < 0) {
        numericalKpis.equityFmv = 0;
      }

      if (numericalKpis.realizedValue < 0) {
        numericalKpis.realizedValue = 0;
      } else if (numericalKpis.realizedValue > numericalKpis.amountInvested) {
        numericalKpis.realizedValue = numericalKpis.amountInvested;
      }

      // Most recent participating round
      const reversedTransactions = [...metric.transactions].reverse();
      const participatingLastRound = reversedTransactions.find(
        (t) => t.transType === 'Investment - Cost' && t.roundId !== null
      );

      numericalKpis.participatingLastRoundSharePrice = participatingLastRound?.pps ?? 0;
      textualKpi.participatingLastRoundSharePriceDate = participatingLastRound?.transactionDate ?? '';

      // Get round name by roundId
      const round = participatingLastRound?.roundId ? roundsMap.get(participatingLastRound.roundId) : null;
      if (round?.displayName) {
        textualKpi.participatingLastRoundSeries = round.displayName;
      }

      if (participatingLastRound?.transactionDate) {
        // Get transactions where date >= participatingLastRound - 60 days
        const participatingLastRoundDateRange = new Date(participatingLastRound.transactionDate); // new Date(participatingLastRound.transactionDate);
        participatingLastRoundDateRange.setDate(participatingLastRoundDateRange.getDate() - 60);

        const mostRecentFinancingTransactions = reversedTransactions.filter((t) => {
          const isPreviousTransactionOfSameRound =
            new Date(t.transactionDate) <= new Date(participatingLastRound.transactionDate);
          const isTransactionWithinLast60Days =
            new Date(t.transactionDate) >= participatingLastRoundDateRange;

          return (
            t.transType === participatingLastRound.transType &&
            t.roundId === participatingLastRound.roundId &&
            isPreviousTransactionOfSameRound &&
            isTransactionWithinLast60Days &&
            t.amount > 0
          );
        });
        numericalKpis.participatingMostRecentFinancingAmount = mostRecentFinancingTransactions.reduce(
          (acc, t) => acc + (t.amount ?? 0),
          0
        );
      }
      if (numericalKpis.realizedCost < 0) {
        numericalKpis.realizedCost = 0;
      }

      numericalKpis.totalCost = numericalKpis.amountInvested + numericalKpis.interest + restructureAmount;

      numericalKpis.unrealizedCost = numericalKpis.totalCost - numericalKpis.realizedCost;

      numericalKpis.realizedGLWithEscrow =
        numericalKpis.realizedAmountWithEscrow - numericalKpis.realizedValue;

      if (numericalKpis.fmv <= 0.1) {
        textualKpi.investmentStatus = 'Realized';
      } else if (numericalKpis.fmv > 0.1 && numericalKpis.realizedValue > 0.1) {
        textualKpi.investmentStatus = 'Partially Realized';
      } else {
        textualKpi.investmentStatus = 'Unrealized';
      }

      textualKpi.exitDate = getExitDate({
        isGroupedByInvestmentStatus,
        investmentStatus: textualKpi.investmentStatus,
        zeroRoc,
        firstRoc,
      });

      const unrealizedValue = numericalKpis.amountInvested - numericalKpis.realizedValue;

      numericalKpis.realizedMOIC = Number(
        (numericalKpis.distributions / numericalKpis.realizedValue).toFixed(2)
      );
      numericalKpis.unrealizedMOIC = Number((numericalKpis.fmv / unrealizedValue).toFixed(2));

      numericalKpis.totalValue = numericalKpis.fmv + numericalKpis.distributions + numericalKpis.escrowAmount;

      numericalKpis.totalReturn = numericalKpis.totalValue - numericalKpis.amountInvested;

      const dpi = numericalKpis.distributions / (numericalKpis.amountInvested || 1);

      const followOnInvestment = numericalKpis.amountInvested - (numericalKpis.initialInvestment || 0);

      const rvpi = numericalKpis.fmv / (numericalKpis.amountInvested || 1);

      const tvpi = (numericalKpis.fmv + numericalKpis.distributions) / (numericalKpis.amountInvested || 1);

      const moic =
        numericalKpis.amountInvested <= 0
          ? undefined
          : (numericalKpis.fmv + numericalKpis.distributions + numericalKpis.escrowAmount) /
            numericalKpis.amountInvested;
      const realizedGL = numericalKpis.distributions - numericalKpis.realizedValue || 0;
      const unrealizedGL = numericalKpis.fmv - unrealizedValue || 0;
      const proceeds = metric.acquisitionShares > 0 ? numericalKpis.fmv : 0;
      numericalKpis.adjustedRealizedGL =
        numericalKpis.distributions +
        proceeds -
        (numericalKpis.realizedValue + numericalKpis.interest + numericalKpis.expenses);
      numericalKpis.adjustedUnrealizedGL =
        numericalKpis.fmv - (unrealizedValue + numericalKpis.interest + numericalKpis.expenses);

      if (numericalKpis.realizedValue + numericalKpis.interest + numericalKpis.expenses !== 0) {
        numericalKpis.adjustedRealizedMOIC = Number(
          (
            numericalKpis.adjustedRealizedGL /
            (numericalKpis.realizedValue + numericalKpis.interest + numericalKpis.expenses)
          ).toFixed(2)
        );
      }

      if (unrealizedValue + numericalKpis.interest + numericalKpis.expenses !== 0) {
        numericalKpis.adjustedUnrealizedMOIC = Number(
          (
            numericalKpis.adjustedUnrealizedGL /
            (unrealizedValue + numericalKpis.interest + numericalKpis.expenses)
          ).toFixed(2)
        );
      }

      if (numericalKpis.realizedValue > 0) {
        numericalKpis.realizedMOICWithEscrow = Number(
          (numericalKpis.realizedAmountWithEscrow / numericalKpis.realizedValue).toFixed(2)
        );
      }

      const irr = this.calculateCompanyXirr(metric, asOfDate, numericalKpis.fmv); // TODO: irr is not that straightforward to calculate.
      const seedCore = metricsConfig ? customLabelsCalculator(metric, fundsInMetrics) : '';
      const convertedOwnership = isGroupedByFund
        ? metric.fundData[0]?.convertedOwnership
        : metric.convertedOwnership;
      const convertedSharesHeld = isGroupedByFund
        ? metric.fundData[0]?.convertedSharesHeld
        : metric.convertedSharesHeld;

      const metricRecalculated = {
        ...metric,
        ...numericalKpis,
        ...textualKpi,
        dpi,
        followOnInvestment,
        moic,
        realizedGL,
        rvpi,
        tvpi,
        unrealizedValue,
        unrealizedGL,
        seedCore,
        irr,
        convertedOwnership,
        convertedSharesHeld,
        fund: Array.from(funds).join(','),
        fundType: Array.from(
          new Set(Array.from(fundsInMetrics).map((f) => fundsMap.get(f)?.fundType ?? NoGroupFundLabel))
        ),
      };

      const fundDataFields = new MetricsFundDataFieldsCalculator().run(
        metricRecalculated,
        isGroupedByFund,
        getCompany,
        customFundFields
      );

      metricRecalculated.totalImpliedEquityValue = !fundDataFields.ownerShipPercentage
        ? null
        : (metricRecalculated.fmv * 100) / fundDataFields.ownerShipPercentage;

      metricRecalculated.impliedEquityValue = !fundDataFields.ownerShipPercentage
        ? null
        : (metricRecalculated.equityFmv * 100) / fundDataFields.ownerShipPercentage;

      return {
        ...metricRecalculated,
        ...fundDataFields,
      };
    });

    const totalFMV = outputMetrics.reduce((acc, m) => acc + (m ? m.fmv : 0), 0);
    outputMetrics.forEach((m) => {
      if (m) {
        m.navPercentage = Number(((m.fmv * 100) / totalFMV).toFixed(2));
      }
    });

    return outputMetrics;
  }

  private hasGroupByFund(columns: ColumnState[]): boolean {
    return Boolean(columns?.find((column) => column.colId === MetricsCalculator.COLUMNS.Fund));
  }

  private hasGroupByFundType(columns: ColumnState[]): boolean {
    return Boolean(columns?.find((column) => column.colId === MetricsCalculator.COLUMNS.FundType));
  }

  private hasGroupByInvestmentStatus(columns: ColumnState[]): boolean {
    return Boolean(columns?.find((column) => column.colId === MetricsCalculator.COLUMNS.InvestmentStatus));
  }

  private splitMetricsByFund(metrics: IMetricsDataModel[]): IMetricsDataModel[] {
    const metricsByFund: Record<number, IMetricsDataModel[]> = {};

    metrics.forEach((metric) => {
      const transactionsByFund = this.splitCompanyTransactionsByFund(metric);

      Object.keys(transactionsByFund).forEach((fundId) => {
        const numericFundId = Number(fundId);

        if (!metricsByFund[numericFundId]) {
          metricsByFund[numericFundId] = [];
        }

        metricsByFund[numericFundId].push({
          ...metric,
          transactions: transactionsByFund[numericFundId],
          fundData: metric.fundData.filter((fd) => fd?.fundId === numericFundId),
        });
      });
    });

    return Object.values(metricsByFund).flatMap((fundMetrics) => fundMetrics);
  }

  private splitCompanyTransactionsByFund(
    metric: IMetricsDataModel
  ): Record<number, MetricsTransactionDataModel[]> {
    const transactionsByFund: Record<number, MetricsTransactionDataModel[]> = {};

    metric.transactions?.forEach((transaction) => {
      const fId = transaction.fundId;

      if (!fId) return;

      if (!transactionsByFund[fId]) {
        transactionsByFund[fId] = [];
      }

      transactionsByFund[fId].push(transaction);
    });

    Object.keys(transactionsByFund).forEach((fund) => {
      transactionsByFund[Number(fund)].sort(
        (fa, fb) => new Date(fa.transactionDate).getTime() - new Date(fb.transactionDate).getTime()
      );
    });

    return transactionsByFund;
  }

  private calculateCompanyXirr(metric: IMetricsDataModel, asOfDate: Date, fmv: number) {
    const cashflows = [];

    metric.transactions.forEach((transaction) => {
      if (transaction.investmentAmount) {
        cashflows.push({
          date: new Date(transaction.transactionDate),
          amount: -1 * transaction.investmentAmount,
        });
      }

      if (transaction.distributions) {
        cashflows.push({
          date: new Date(transaction.transactionDate),
          amount: transaction.distributions,
        });
      }
    });

    cashflows.push({
      date: asOfDate,
      amount: fmv,
    });

    return cashflows.length < 2 ? 0 : calculateXIRR(cashflows);
  }
}

export function findInitialTransaction(transactions: MetricsTransactionDataModel[]) {
  let initialTransaction: MetricsTransactionDataModel | undefined;

  transactions.forEach((transaction) => {
    if (
      transaction.investmentAmount < ACCOUNTING_MIN_ZERO_VALUE ||
      transaction.investmentRoundId == undefined
    ) {
      return;
    }

    if (initialTransaction && transaction.investmentRoundId === initialTransaction.investmentRoundId) {
      initialTransaction.investmentAmount += transaction.investmentAmount;
      initialTransaction.quantity += transaction.quantity;
    }

    if (!initialTransaction) {
      initialTransaction = { ...transaction };
    }
  });

  return initialTransaction;
}

export function isNoteOrSafeTransaction(t: MetricsTransactionDataModel) {
  const position = t.position?.toLocaleLowerCase();
  return (
    (t.transType === 'Investment - Cost' || t.transType === 'Investment - Restructure Out') &&
    t.quantity === 0 &&
    position &&
    (position.includes('note') ||
      position.includes('safe') ||
      position.includes('convertible') ||
      position.includes('simple agreement'))
  );
}

interface IGetExitDateParams {
  isGroupedByInvestmentStatus: boolean;
  investmentStatus: string;
  zeroRoc: string;
  firstRoc: string;
}
export function getExitDate({
  isGroupedByInvestmentStatus,
  investmentStatus,
  zeroRoc,
  firstRoc,
}: IGetExitDateParams) {
  if (isGroupedByInvestmentStatus && investmentStatus === 'Unrealized') {
    return '';
  } else {
    return zeroRoc || firstRoc;
  }
}

export function splitTransactionsByFundType(
  metric: IMetricsDataModel,
  fundsById: Map<number, Fund>
): Map<string, MetricsTransactionDataModel[]> {
  const transactionsByFundType = metric.transactions.reduce((map, transaction) => {
    if (!transaction.fundId) return map;
    const fundType = fundsById.get(transaction.fundId)?.fundType ?? NoGroupFundLabel;
    if (!map.has(fundType)) {
      map.set(fundType, []);
    }
    map.get(fundType)!.push(transaction);
    return map;
  }, new Map<string, MetricsTransactionDataModel[]>());

  transactionsByFundType.forEach((transactions) => {
    transactions.sort(
      (fa, fb) => new Date(fa.transactionDate).getTime() - new Date(fb.transactionDate).getTime()
    );
  });
  return transactionsByFundType;
}

export function splitMetricsByFundType(metrics: IMetricsDataModel[], fundsById: Map<number, Fund>) {
  return metrics.reduce((acc, metric) => {
    const fundDataByFundType = metric.fundData.reduce((map, fundData) => {
      const fundType = fundsById.get(fundData.fundId)?.fundType ?? NoGroupFundLabel;
      if (!map.has(fundType)) {
        map.set(fundType, []);
      }
      map.get(fundType)!.push(fundData);
      return map;
    }, new Map<string, IMetricFundDataModel[]>());

    const transactionsByFundType = splitTransactionsByFundType(metric, fundsById);

    transactionsByFundType.forEach((transactions, fundType) => {
      acc.push({
        ...metric,
        transactions,
        fundData: fundDataByFundType.get(fundType) ?? [],
      });
    });
    return acc;
  }, [] as IMetricsDataModel[]);
}
