import { endOfMonth, endOfQuarter, endOfYear, sub } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { Temporal } from 'temporal-polyfill';
import {
  ICompanyFinancialsDataModel,
  KpiPeriod,
  KpiSection,
} from '../../../data-models/company-financials.data-model';
import { kpiConfigByIdMapAtom } from '../../../services/state/KPIConfigState';
import { compareBySortOrder } from '../../../util/comparators';
import { dateToPlainDateString } from '../../../util/date-utilts';
import { MONTHS } from '../../KPI/components/Sections/FormItem/KPITable/useKPITableMetricsInputs';
import {
  IMprViewSettings,
  IMultiPeriodReportingDataPoint,
  MprColumnSortType,
  MprConfigFormData,
  ReportingPeriodType,
} from './MultiPeriodReporting.schemas';

export function mprColumnConfigToDataPoint(column: IMprColumnConfig): IMultiPeriodReportingDataPoint {
  const { kpiId, period, section } = column;
  const date = getDateForConfig(column);

  return {
    date,
    kpiId,
    period,
    section,
  };
}

export function getDateForConfig(columnConfig: IMprColumnConfig): string {
  const type = columnConfig.type;

  let date;
  if (type === ReportingPeriodType.relative) {
    date = getDateStrForRelativePeriod(columnConfig.period as KpiPeriod, columnConfig.relativeDistance ?? 0);
  } else if (type === ReportingPeriodType.absolute) {
    date = getDateStrForAbsolutePeriod(columnConfig.year!, columnConfig.month as number | undefined);
  } else {
    throw new Error('Invalid type');
  }
  return date;
}

export function getDateStrForRelativePeriod(period: KpiPeriod, relativeDistance: number): string {
  let date;
  if (period === KpiPeriod.quarter) {
    date = endOfQuarter(sub(new Date(), { months: (relativeDistance! + 1) * 3 }));
  } else if (period === KpiPeriod.year) {
    date = endOfYear(sub(new Date(), { years: (relativeDistance! + 1) * 1 }));
  } else {
    date = endOfMonth(sub(new Date(), { months: (relativeDistance! + 1) * 1 }));
  }
  return dateToPlainDateString(date);
}

export function getDateStrForAbsolutePeriod(year: number, month: number = 12): string {
  const date = Temporal.PlainYearMonth.from(`${year}-${String(month).padStart(2, '0')}`);
  return date.toPlainDate({ day: date.daysInMonth }).toString();
}

export interface IMprColumnConfig
  extends Pick<ICompanyFinancialsDataModel, 'kpiId' | 'section' | 'period'>,
    Pick<MprConfigFormData, 'type'> {
  month?: number | null;
  sortOrder?: number;
  relativeDistance?: number | null;
  year?: number | null;
}

export function createMprColumnConfig(overrides: Partial<IMprColumnConfig> = {}): IMprColumnConfig {
  return {
    kpiId: 1,
    section: KpiSection.actual,
    period: KpiPeriod.quarter,
    type: ReportingPeriodType.relative,
    relativeDistance: 0,
    ...overrides,
  };
}

export function columnConfigsFromFormData(settings: MprConfigFormData): IMprColumnConfig[] {
  const result: IMprColumnConfig[] = [];

  settings.kpiId.forEach((kpiId) => {
    settings.section.forEach((section) => {
      if (settings.type === ReportingPeriodType.relative) {
        const { period, relativeDistance } = settings;
        if (!relativeDistance?.length || !period) {
          throw new Error('Missing data');
        }
        relativeDistance.forEach((distance) => {
          result.push({
            kpiId: kpiId!,
            section,
            period,
            relativeDistance: distance,
            type: settings.type,
          });
        });
      } else if (settings.type === ReportingPeriodType.absolute) {
        (settings.month ?? [12])?.forEach((month) => {
          if (!settings.year?.length) {
            throw new Error('Missing data');
          }
          settings.year?.forEach((year) => {
            result.push({
              kpiId: kpiId!,
              section,
              period: settings.period!,
              month,
              year,
              type: settings.type,
            });
          });
        });
      }
    });
  });

  return result;
}

// use for colIds and to compare configs
export function getColumnConfigKey(config: IMprColumnConfig): string {
  const { kpiId, section, period, type, relativeDistance, year, month } = config;
  return JSON.stringify({ kpiId, section, period, type, relativeDistance, year, month });
}

export const LastFullPeriodPrefix = 'Last Full';
export function getFormattedConfigPeriod(config: IMprColumnConfig) {
  const { type, period, relativeDistance, year, month } = config;
  if (type == ReportingPeriodType.relative) {
    if (relativeDistance === 0) {
      return `${LastFullPeriodPrefix} ${period}`;
    } else {
      return `${LastFullPeriodPrefix} ${period} - ${relativeDistance}`;
    }
  } else if (type == ReportingPeriodType.absolute) {
    if (period === KpiPeriod.year) {
      return `${year}`;
    } else if (period === KpiPeriod.quarter) {
      return `Q${month! / 3} ${year}`;
    } else if (period === KpiPeriod.month) {
      return `${MONTHS[month! + 1]} ${year}`;
    }
  }
  return '';
}

const PeriodToSortOrder: Record<KpiPeriod, number> = {
  [KpiPeriod.month]: 1,
  [KpiPeriod.quarter]: 2,
  [KpiPeriod.year]: 3,
};

export function multiPeriodDateSort(a: IMprColumnConfig, b: IMprColumnConfig) {
  const aDate = getDateForConfig(a);
  const bDate = getDateForConfig(b);
  const aPeriod = a.period;
  const bPeriod = b.period;
  if (aPeriod === bPeriod) {
    return aDate.localeCompare(bDate);
  } else {
    return PeriodToSortOrder[aPeriod as KpiPeriod] - PeriodToSortOrder[bPeriod as KpiPeriod];
  }
}

function useSortByKpiName() {
  const kpiConfigById = useAtomValue(kpiConfigByIdMapAtom);
  return useCallback(
    (a: IMprColumnConfig, b: IMprColumnConfig) => {
      return (kpiConfigById.get(a.kpiId!)?.displayName ?? '').localeCompare(
        kpiConfigById.get(b.kpiId!)?.displayName ?? ''
      );
    },
    [kpiConfigById]
  );
}
export function useSortColumnConfigs() {
  const sortByKpiName = useSortByKpiName();

  const sortByDateAndKpiName = useCallback(
    (configs: IMprColumnConfig[]) => {
      return configs.toSorted((a, b) => sortByKpiName(a, b)).sort(multiPeriodDateSort);
    },
    [sortByKpiName]
  );
  const sortByKpiNameAndDate = useCallback(
    (configs: IMprColumnConfig[]) => {
      return configs.toSorted(multiPeriodDateSort).sort(sortByKpiName);
    },
    [sortByKpiName]
  );

  return { sortByDateAndKpiName, sortByKpiNameAndDate };
}

export function usePreprocessMprViewSettings() {
  const { sortByDateAndKpiName, sortByKpiNameAndDate } = useSortColumnConfigs();

  return useCallback(
    (config: IMprViewSettings) => {
      if (!config.columnSort) {
        return config;
      }
      let sortedKpis: IMprColumnConfig[];
      if (config.columnSort === MprColumnSortType.dateAsc) {
        sortedKpis = sortByDateAndKpiName(config.kpis);
      } else if (config.columnSort === MprColumnSortType.kpi) {
        sortedKpis = sortByKpiNameAndDate(config.kpis);
      } else {
        sortedKpis = config.kpis.toSorted(compareBySortOrder);
      }
      return {
        ...config,
        kpis: sortedKpis,
      };
    },
    [sortByDateAndKpiName, sortByKpiNameAndDate]
  );
}
