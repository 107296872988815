import { ColDef } from 'ag-grid-community';
import { ColumnMeta, PredefinedColumn } from '../../../../../../../types';
import { NameRenderer } from './NameRenderer';

export class CompanyNameAndLogo implements PredefinedColumn {
  public static NAME = 'CompanyNameAndLogo';

  getMeta() {
    return this.meta;
  }

  getDefinition() {
    return this.definition;
  }

  private meta: ColumnMeta = {
    aggFunc: null,
    id: Math.ceil(Math.random() * 1000),
    name: CompanyNameAndLogo.NAME,
    displayName: 'Company',
    default: true,
    path: '',
    type: 'string',
    options: '',
    required: true,
    groupable: false,
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    exportable: true,
    formatting: 'text',
    groupName: 'Company Details',
    groupOrder: 1,
    groupPriority: 1,
    tableOrder: 1,
    description: '',
    createdBy: null,
    updatedBy: null,
    createdAt: '2022-01-18T15:48:00.738Z',
    updatedAt: '2022-01-18T15:48:00.738Z',
    aggregation: false,
  };

  private definition: ColDef = {
    width: 200,
    colId: this.meta.name,
    headerName: `${this.meta.displayName} ⓘ`,
    enableRowGroup: true,
    cellRenderer: NameRenderer,
    cellRendererParams: { showLogos: true, showDrillDownBtn: true },
    pinned: 'left',
    lockPinned: true,
    headerTooltip: 'Data from Crunchbase',
  };
}
