import { atom, useAtom, useAtomValue } from 'jotai';
import { atomFamily, atomWithDefault } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedViewIdPF } from '../state/ViewState';
import { useSaveMprStateInView } from './mprViewActions';
import { mpReportingGridApi } from './MultiPeriodGrid';
import { createMprViewSettings, IMprViewSettings, MprColumnSortType } from './MultiPeriodReporting.schemas';
import { useMprConfigToColDefs } from './multiperiodReportingGridData';
import { getColumnConfigKey, IMprColumnConfig, useSortColumnConfigs } from './multiPeriodReportingUtils';

export const savedMprViewConfigAtom = atomFamily(() => atom<IMprViewSettings | null>(null));

const draftColumnConfigsAtom = atomFamily((viewId: number) =>
  atomWithDefault<IMprColumnConfig[]>((get) => get(savedMprViewConfigAtom(viewId))?.kpis ?? [])
);
const draftColumnSortConfigsAtom = atomFamily((viewId: number) =>
  atomWithDefault<MprColumnSortType | null>((get) => get(savedMprViewConfigAtom(viewId))?.columnSort ?? null)
);

export function useMprColumnConfigs() {
  const updateView = useSaveMprStateInView();
  const viewId = useRecoilValue(selectedViewIdPF);
  const { sortByDateAndKpiName, sortByKpiNameAndDate } = useSortColumnConfigs();
  const [columns, setColumns] = useAtom(draftColumnConfigsAtom(viewId));
  const [draftSortType, setDraftSortType] = useAtom(draftColumnSortConfigsAtom(viewId));
  const configToColDefs = useMprConfigToColDefs();
  const columnMap = useMemo(() => {
    return columns.reduce(
      (map, config) => map.set(getColumnConfigKey(config), config),
      new Map<string, IMprColumnConfig>()
    );
  }, [columns]);
  const uniqueCols = useMemo(() => [...columnMap.values()], [columnMap]);

  const setDraftColumnConfigs = useMemo(() => {
    return function (cb: (prev: IMprColumnConfig[]) => IMprColumnConfig[]) {
      setColumns((prev) => {
        const newDraft = cb(prev);
        if (draftSortType === MprColumnSortType.dateAsc) {
          return sortByDateAndKpiName(newDraft);
        } else if (draftSortType === MprColumnSortType.kpi) {
          return sortByKpiNameAndDate(newDraft);
        } else {
          return newDraft;
        }
      });
    };
  }, [draftSortType, setColumns, sortByDateAndKpiName, sortByKpiNameAndDate]);

  const [saved, saveConfigs] = useAtom(savedMprViewConfigAtom(viewId));
  const gridApi = useAtomValue(mpReportingGridApi);

  const saveViewConfigs = useCallback(
    async (updateGridColDefs = true) => {
      // save current sort order for custom sort - will be ignored otherwise
      const columnsWithSortOrder = uniqueCols.map((config, i) => ({ ...config, sortOrder: i }));
      const newConfig = { kpis: columnsWithSortOrder, columnSort: draftSortType };

      saveConfigs((prev) => ({
        ...(prev ?? createMprViewSettings()),
        ...newConfig,
      }));

      if (updateGridColDefs) {
        gridApi?.setGridOption('columnDefs', configToColDefs(columnsWithSortOrder));
      }
      updateView({ multiPeriodReportingConfig: newConfig });
    },
    [uniqueCols, draftSortType, updateView, saveConfigs, gridApi, configToColDefs]
  );

  const resetDrafts = useCallback(() => {
    setColumns(saved?.kpis ?? []);
    setDraftSortType(saved?.columnSort ?? null);
  }, [saved?.columnSort, saved?.kpis, setColumns, setDraftSortType]);

  const setDraftSortConfig = useCallback(
    (type: MprColumnSortType | null) => {
      setDraftSortType(type);
      switch (type) {
        case MprColumnSortType.dateAsc: {
          setColumns((prev) => sortByDateAndKpiName(prev));
          break;
        }
        case MprColumnSortType.kpi: {
          setColumns((prev) => sortByKpiNameAndDate(prev));
          break;
        }
      }
    },
    [setColumns, setDraftSortType, sortByDateAndKpiName, sortByKpiNameAndDate]
  );

  return {
    draftColumnConfigs: uniqueCols,
    setDraftColumnConfigs,
    draftColumnSortType: draftSortType,
    setDraftColumnSortType: setDraftSortConfig,
    saveConfigState: saveViewConfigs,
    resetDrafts,
  };
}
