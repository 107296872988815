import { createContext, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import type { DraggableAttributes, DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton, styled } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const SortableItemWrapper = styled('li')`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr min-content;
`;

interface Props {
  id: UniqueIdentifier;
}

interface Context {
  attributes: DraggableAttributes;
  listeners?: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {} as DraggableAttributes,
  listeners: undefined,
  ref() {
    /**/
  },
});

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({ id });
  const context = useMemo(
    () => ({
      attributes: attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <SortableItemWrapper ref={setNodeRef} style={style}>
        {children}
      </SortableItemWrapper>
    </SortableItemContext.Provider>
  );
}

const DragIconWrapper = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0.25rem;
`;

export function BasicDragHandle({ disabled = false }: { disabled?: boolean }) {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <DragIconWrapper>
      <IconButton {...attributes} {...listeners} ref={ref} disabled={disabled}>
        <DragIndicatorIcon />
      </IconButton>
    </DragIconWrapper>
  );
}
