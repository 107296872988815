import { Step, StepLabel, Stepper } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { StepperContainer, StepperSx, StepSx } from '../../../components/Form/FormComponents';
import { Fund } from '../../../schemas/Fund.schema';
import { FundViewModel } from '../../../schemas/FundViewModel.schema';
import { fundFormCurrentStepState, selectedFundStateFP, useResetFundFormState } from '../state/FPState';
import { WaterfallSettingsFormStep1 } from './FundWaterfallFormStep1';
import { WaterfallSettingsFormStep2 } from './FundWaterfallFormStep2';

export interface IFundFormProps {
  defaultValues?: Partial<FundViewModel>;
  onCancel: () => void;
  onSubmit: (data: Partial<Fund>) => Promise<Fund | void>;
}

export function FundWaterfallForm2({ defaultValues, onCancel, onSubmit }: IFundFormProps) {
  const fund = useRecoilValue(selectedFundStateFP);
  const currentStep = useAtomValue(fundFormCurrentStepState);
  const resetFundFormState = useResetFundFormState();

  useEffect(() => {
    return resetFundFormState;
  }, [resetFundFormState]);

  const steps = useMemo(
    () => [
      {
        label: 'Waterfall Tiers',
        component: <WaterfallSettingsFormStep1 defaultValues={defaultValues} onCancel={onCancel} />,
      },
      { label: 'Other Settings', component: <WaterfallSettingsFormStep2 onSubmit={onSubmit} /> },
    ],
    [defaultValues, onCancel, onSubmit]
  );

  if (!fund) return null;

  return (
    <StepperContainer>
      <Stepper activeStep={currentStep} sx={StepperSx}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} sx={StepSx}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>{steps[currentStep]?.component}</>
    </StepperContainer>
  );
}
