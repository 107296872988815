import omit from 'lodash-es/omit';
import * as yup from 'yup';
import { kpiRequestFields } from '../../../../../data-schemas/KPIRequestSchema';
import { companyFields } from '../../../../../schemas/Company.schema';

export function kpiRequestViewModelFields() {
  const { name, sectorId } = companyFields();

  return {
    ...omit(kpiRequestFields(), ['kpiRequestResponse']),
    acceptMessage: yup.string().label('Commentary'),
    companyName: name.label('Company'),
    sectorId,
    fye: yup.string().label('FYE').nullable(),
    rejectReason: yup.string().label('Commentary'),
    responseDate: yup.string().fieldMeta({ formatter: 'date' }),
  };
}

export function kpiRequestViewModelSchema() {
  return yup.object(kpiRequestViewModelFields());
}

export type KpiRequestManageViewModel = yup.InferType<ReturnType<typeof kpiRequestViewModelSchema>>;
