import { styled } from '@mui/system';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { FormFactoryNoLayout } from '../../../components/Form/FormFactory';
import { RendererType } from '../../../data-models/field.data-model';
import { ISimpleChoice } from '../../../data-models/field2.data-model';
import { companyFields } from '../../../schemas/Company.schema';
import { companyState } from '../../../services/state/CompanyState';
import { FDMap } from '../../../util/data-structure/FDMap';
import { NumericFormattersConfig } from '../../../util/formatters/NumericFormatters';
import { useSchemaToFormFields } from '../../../util/schema-utils';
import {
  createForm,
  IFormField,
  IFormFieldSelectMeta,
  IFormFieldTextMeta,
} from '../../../view-models/form.view-model';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { FieldsWrapper, usePanelPagination } from './CompanyDetailsPanel';
import { fromCompanyDataModels, IDigestPanelViewModel, toCompanyDataModels } from './DigestPanel.view-model';
import { Panel } from './Panel';
import { useDebounceCompanyUpdates } from './useDebounceCompanyUpdates';
import { useCompanyEditPerms } from './useEditPerms';
import { useMergedDigestData } from './useMergedDigestData';

const DigestContents = styled('div')`
  display: grid;
  height: 100%;
  grid-template-rows: max-content 1fr;
  & [data-fieldKey='shortDescription'],
  & [data-fieldKey='description'] {
    grid-column: 1/-1;
  }
`;

export function DigestPanel({ companyId }: { companyId: number }) {
  const viewModel = useDigestPaneViewModel();
  const digestPanelSchema = useDigestPanelSchema(viewModel);
  const schemaToFormFields = useSchemaToFormFields();
  const setEditableFields = useCompanyEditPerms(companyId);

  const mainFieldsSchema = digestPanelSchema;
  const mainFields = useMemo(
    () => setEditableFields(schemaToFormFields(mainFieldsSchema)),
    [setEditableFields, schemaToFormFields, mainFieldsSchema]
  );

  const { paginationConfig, visibleFields } = usePanelPagination({
    fields: mainFields,
    itemsPerPage: 9,
  });

  return (
    <Panel
      title={'Digest'}
      paginationConfig={paginationConfig}
      showContentOverflow={true}
      style={{ minHeight: '26rem' }}
    >
      <DigestMainSection digestViewModel={viewModel} fields={visibleFields} />
    </Panel>
  );
}

interface IDigestMainSectionProps {
  fields: IFormField<unknown>[];
  digestViewModel: IDigestPanelViewModel;
}

function DigestMainSection(props: IDigestMainSectionProps) {
  const { digestViewModel, fields } = props;
  const companyId = digestViewModel.id;
  const methods = useForm({
    values: digestViewModel,
  });
  const onChange = useDebounceCompanyUpdates(companyId);

  const _onChange = useCallback(
    (data: Partial<IDigestPanelViewModel>) => {
      onChange(toCompanyDataModels({ ...data, id: companyId }));
    },
    [companyId, onChange]
  );

  const form = createForm<IDigestPanelViewModel>({
    fields: FDMap.fromArray(fields, 'key'),
    variant: 'form-inline',
  });

  return (
    <FormProvider {...methods}>
      <DigestContents>
        <FieldsWrapper style={{ minHeight: '60%' }}>
          <FormFactoryNoLayout form={form} onChange={_onChange} />
        </FieldsWrapper>
      </DigestContents>
    </FormProvider>
  );
}

export function useDigestPaneViewModel() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId))!;
  const data = useMergedDigestData(company);

  return fromCompanyDataModels(data);
}

export function useDigestPanelSchema(viewModel: IDigestPanelViewModel) {
  const ceoNameOptions: ISimpleChoice<string>[] = viewModel._viewModel.ceoNames.map((name) => {
    return { displayName: name, value: name };
  });
  const aliasesOptions: ISimpleChoice<string>[] =
    viewModel.aliases?.map((alias) => {
      return { displayName: alias, value: alias };
    }) ?? [];
  const latestLeadInvestorOptions: ISimpleChoice<string>[] =
    viewModel.latestLeadInvestor?.map((investor) => {
      return { displayName: investor, value: investor };
    }) ?? [];

  const {
    aliases,
    description,
    foundedDate,
    lastPostMoney,
    latestFundingDate,
    latestLeadInvestor,
    shortDescription,
    socials,
  } = companyFields();

  return yup.object({
    foundedDate,
    ceoNames: yup
      .array()
      .of(yup.string())
      .formMeta<IFormFieldSelectMeta<string>>({
        key: '_viewModel.ceoNames',
        label: 'CEO Names',
        formatter: 'stringArray',
        renderer: RendererType.multiSelect,
        rendererMeta: {
          allowCustomAdd: true,
          values: ceoNameOptions,
        },
      }),
    lastPostMoney: lastPostMoney.formMeta<IFormFieldTextMeta>({
      disabled: true,
      formatter: NumericFormattersConfig.usdShort,
    }),
    primaryLocation: yup
      .string()
      .nullable()
      .default(null)
      .formMeta<IFormFieldSelectMeta<unknown>>({
        key: '_viewModel.primaryLocation',
        renderer: RendererType.location,
        rendererMeta: {
          multi: false,
          values: [],
        },
      }),
    lastFunding: yup.string().formMeta({
      key: '_viewModel.lastFunding',
      disabled: true,
    }),
    secondaryLocations: yup
      .array()
      .of(yup.mixed())
      .label('Secondary Locations')
      .default([])
      .formMeta<IFormFieldSelectMeta<unknown>>({
        key: '_viewModel.secondaryLocations',
        renderer: RendererType.location,
        rendererMeta: {
          multi: true,
          values: [],
        },
      }),
    latestFundingDate: latestFundingDate.formMeta<IFormFieldTextMeta>({
      disabled: true,
    }),
    aliases: aliases.formMeta({
      key: 'aliases',
      disabled: true,
      renderer: RendererType.multiSelect,
      formatter: 'stringArray',
      rendererMeta: {
        allowCustomAdd: true,
        values: aliasesOptions,
      },
    }),
    shortDescription: shortDescription.label('Short Description').formMeta<IFormFieldTextMeta>({
      renderer: RendererType.text,
      variant: 'form-inline',
      rendererMeta: {
        multiline: true,
        maxRows: 2,
      },
    }),
    socials: socials.formMeta({
      renderer: RendererType.companySocialMedia,
    }),
    latestLeadInvestor: latestLeadInvestor.formMeta({
      key: 'latestLeadInvestor',
      disabled: true,
      renderer: RendererType.multiSelect,
      formatter: 'stringArray',
      rendererMeta: {
        allowCustomAdd: true,
        values: latestLeadInvestorOptions,
      },
    }),
    description: description.formMeta<IFormFieldTextMeta>({
      renderer: RendererType.text,
      rendererMeta: {
        maxRows: 3,
        multiline: true,
      },
      variant: 'form-inline',
    }),
  });
}
