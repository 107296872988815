import { useLocation, useNavigate } from 'react-router';
import { IconButton, Stack, styled } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { noop } from 'lodash-es';
import { companyState } from '../../../services/state/CompanyState';
import { selectedCompanyIdProfile } from '../../CompanyProfiles/state/UIState';
import { CompanyLogoAndName } from '../../../components/grid-renderers/CompanyCellRenderer';
import { StatusChip } from '../../CompanyProfiles/Summary/components/StatusChip/StatusChip';
import { DealsSortedByLastUpdatedDescState } from '../../CompanyProfiles/state/CompanyDealsState';
import { formatExternalLink } from '../../../util/url-utils';
import { CompanyProfileTabs } from './CompanyProfileTabs';
import { HeaderActionButtons } from './HeaderActionButtons';
import { LastContacted } from './LastContacted';
import { CompanySectorField } from './CompanySectorField';

const HeaderAndTabs = styled('div')`
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.7);
  display: grid;
  grid-template-rows: min-content min-content;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const HeaderWrapper = styled('div')`
  display: grid;
  grid-template-columns: minmax(100px, max-content) min-content minmax(auto, 20rem) min-content auto min-content;
  gap: 1rem;
  padding: 1rem;
  align-items: center;
`;

export function CompanyProfilesHeader2() {
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const company = useRecoilValue(companyState(companyId));
  const deals = useRecoilValue(DealsSortedByLastUpdatedDescState(companyId));
  const showBackButton = location.state?.fromList ?? false;

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!company) {
    return <div>Unknown company</div>;
  }

  return (
    <HeaderAndTabs>
      <HeaderWrapper>
        {showBackButton && (
          <IconButton onClick={goBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Stack direction={'row'} alignItems={'center'}>
          <CompanyLogoAndName
            name={company.name}
            logoUrl={company.logoUrl ?? ''}
            typographyProps={{ fontSize: '28px' }}
          />
          <IconButton href={formatExternalLink(company.website ?? '')} target='blank'>
            <LinkIcon fontSize='small' />
          </IconButton>
        </Stack>
        <StatusChip
          allCompanyDeals={deals}
          company={company}
          handleTogglerPassDetails={noop}
          showPassDetails={false}
        />
        <Stack pt={'0.25rem'}>
          <CompanySectorField />
        </Stack>
        <LastContacted companyId={companyId} />
        <div>{/*Spacer*/}</div>
        <HeaderActionButtons />
      </HeaderWrapper>
      <CompanyProfileTabs companyId={companyId} />
    </HeaderAndTabs>
  );
}
