import { WritableAtom } from 'jotai';
import { SetStateAction, useMemo } from 'react';
import { KpiSection } from '../../../../../../data-models/company-financials.data-model';
import {
  IKPIDataValue,
  IKPIResponseFormSectionData,
  KPIRequestStatus,
} from '../../../../../../data-models/kpi-requests.data-model';
import {
  acceptKPIRequestResponse,
  fetchKPIRequestById,
  fetchKPIRequestResponseById,
  rejectKPIRequestResponse,
  updateKPIRequestResponse,
} from '../../../../../../services/queries/KPIRequestsQueries';
import { fetchKPITemplateById } from '../../../../../../services/queries/KPITemplatesQueries';
import { fetchCompanyRaw } from '../../../../../../services/queries/MaggieCompanyQueries';
import { getForesightStore } from '../../../../../../util/jotai-store';

export type KPIReviewState = ReviewerViewData & {
  isModified: boolean;
  isSubmitting: boolean;
  submissionError: string | null;
};

export type KPIReviewStateAtom = WritableAtom<KPIReviewState, [SetStateAction<KPIReviewState>], unknown>;

export function useKPIReviewerActions(stateAtom: KPIReviewStateAtom, store = getForesightStore()) {
  const { get, set } = store;

  return useMemo(() => {
    const onKPIGridDataUpdated = async (section: KpiSection, updatedData: IKPIDataValue[]) => {
      const { currentResponse } = get(stateAtom);
      const newKPIData = currentResponse.kpiData.values
        .filter((data) => data.section !== section)
        .concat(updatedData);

      set(stateAtom, (prev) => {
        return { ...prev, isModified: true, currentResponse: { ...currentResponse, ...newKPIData } };
      });
    };

    const onSectionDataUpdated = async (updatedData: IKPIResponseFormSectionData) => {
      const { currentResponse } = get(stateAtom);
      const newSectionData = currentResponse.sectionData.map((section) => {
        if (section.sectionId === updatedData.sectionId) {
          return updatedData;
        }
        return section;
      });

      set(stateAtom, (prev) => {
        return { ...prev, isModified: true, currentResponse: { ...currentResponse, newSectionData } };
      });
    };

    const acceptResponse = async (comment?: string) => {
      const { currentRequest, currentResponse, isModified } = get(stateAtom);
      set(stateAtom, (prev) => ({ ...prev, isSubmitting: true }));

      try {
        if (isModified) {
          await updateKPIRequestResponse(currentRequest.id.toString(), currentResponse);
        }
        await acceptKPIRequestResponse(currentRequest.id.toString(), comment);
      } catch (err) {
        set(stateAtom, (prev) => ({
          ...prev,
          submissionError: `Failed to submit response ${(err as Error)?.message}`,
        }));
      }

      set(stateAtom, (prev) => {
        return {
          ...prev,
          currentRequest: { ...prev.currentRequest, isSubmitting: false, status: KPIRequestStatus.Accepted },
        };
      });
    };

    const rejectResponse = async (comment?: string) => {
      const { currentRequest } = get(stateAtom);
      set(stateAtom, (prev) => ({ ...prev, isSubmitting: true }));

      try {
        await rejectKPIRequestResponse(currentRequest.id.toString(), comment);
      } catch (err) {
        set(stateAtom, (prev) => ({
          ...prev,
          submissionError: `Failed to submit response ${(err as Error)?.message}`,
        }));
      }

      set(stateAtom, (prev) => {
        return {
          ...prev,
          currentRequest: { ...prev.currentRequest, isSubmitting: false, status: KPIRequestStatus.Rejected },
        };
      });
    };

    return {
      acceptResponse,
      rejectResponse,
      onKPIGridDataUpdated,
      onSectionDataUpdated,
    };
  }, [get, stateAtom, set]);
}
export type KPIReviewerActions = ReturnType<typeof useKPIReviewerActions>;

export async function loadReviewerViewInitialData(requestId: number) {
  const currentRequest = (await fetchKPIRequestById(requestId))!;
  const currentResponse = await fetchKPIRequestResponseById(currentRequest.id);
  const company = await fetchCompanyRaw(currentRequest.companyId);
  const currentTemplate = await fetchKPITemplateById(currentRequest.templateId);

  return {
    company,
    currentRequest,
    currentResponse,
    currentTemplate,
  };
}
export type ReviewerViewData = Awaited<ReturnType<typeof loadReviewerViewInitialData>>;
