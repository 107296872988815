import { ExcelStyle } from 'ag-grid-community';
import { merge } from 'lodash-es';
import { RendererType } from '../data-models/field.data-model';
import { getFormatterModelForField2, IField } from '../data-models/field2.data-model';
import {
  getFormatterModelForField3,
  ICurrencyMeta,
  IField3,
  ISelectMeta,
  PrimitiveType,
} from '../data-models/field3.data-model';
import {
  createFormatterDataModel,
  IBooleanFormatConfig,
  ICustomDateFormatConfig,
  ICustomNumberFormatConfig,
  IFormatterDataModel,
  IStringFormatConfig,
} from '../data-models/formatter.data-model';
import {
  getMultiSelectFormatter,
  getSelectFormatter,
  IDisplayField,
} from '../view-models/display-field.view-model';
import { FDMap } from './data-structure/FDMap';
import {
  ArrayFormatterId,
  ArrayFormattersConfig,
  IArrayFormatConfig,
} from './formatters/ArrayFormattersConfig';
import { BooleanFormatterId, BooleanFormattersConfig } from './formatters/BooleanFormatters';
import { DateFormattersConfig, DateFormattersId } from './formatters/DateFormatters';
import {
  adjustValueWithAccountingErrorMargin,
  NumericFormatterId,
  NumericFormattersConfig,
} from './formatters/NumericFormatters';
import { StringFormatterId, StringTransforms } from './formatters/StringFormatters';
import { StaticFormattersById } from './static-formatters';

export enum CustomFormatterId {
  dealType = 'dealType',
  dealStage = 'dealStage',
  fund = 'fund',
  investmentRound = 'investmentRound',
  kpiTemplateName = 'kpiTemplateName',
  round = 'round',
  sector = 'sector',
  stage = 'stage',
  transactionType = 'transactionType',
  transactionCategory = 'transactionCategory',
  transactionSubType = 'transactionSubType',
  user = 'user',
  userByEmail = 'userByEmail',
}

export type StandardFormatterId =
  | keyof typeof NumericFormatterId
  | keyof typeof DateFormattersId
  | keyof typeof StringFormatterId
  | keyof typeof BooleanFormatterId
  | keyof typeof ArrayFormatterId
  | keyof typeof CustomFormatterId
  | keyof typeof StaticFormattersById;
export type FormatterFn<T> = (value: T, config?: object) => string;
export type IPartialFormatterDataModel<T> = Partial<IFormatterDataModel<T>> &
  Pick<IFormatterDataModel<T>, 'id'>;

export class FormatterService {
  static #instance: FormatterService | undefined;
  #baseMeta;
  #formattersMap;
  #excelStyleMap;

  private constructor() {
    this.#baseMeta = new Map<string, IFormatterDataModel<unknown>>();
    this.#formattersMap = new FDMap<string, FormatterFn<unknown>>();
    this.#excelStyleMap = new Map<string, ExcelStyle>();
  }

  init(formatterModels: IFormatterDataModel<unknown>[], override = false) {
    if (override) {
      this.#baseMeta = new Map<string, IFormatterDataModel<unknown>>();
      this.#formattersMap = new FDMap<string, FormatterFn<unknown>>();
    }

    const formattersWithExtend: IFormatterDataModel<unknown>[] = [];
    formatterModels.forEach((meta) => {
      meta.extends ? formattersWithExtend.push(meta) : this.addFormatter(meta);
    });

    formattersWithExtend.forEach((meta) => {
      this.addFormatter(meta);
    });

    Object.keys(StaticFormattersById).forEach((id: string) => {
      this.#formattersMap.set(id, StaticFormattersById[id as keyof typeof StaticFormattersById]);
    });

    return this;
  }

  getCustomExcelStyles() {
    return [...this.#excelStyleMap.values()];
  }

  // Handle field2.data-model created configurations.
  getFormatterForField<T>(field: IField<unknown>): FormatterFn<T> {
    const model = getFormatterModelForField2(field);

    return this.#getOrCreateFormatter(model);
  }

  getFormatterForFieldV3<T>(field: IField3<unknown>): FormatterFn<T> {
    const model = getFormatterModelForField3(field);

    return this.#getOrCreateFormatter(model);
  }

  getFormatterForId<T>(formatterId: string): FormatterFn<T> {
    if (this.#formattersMap.has(formatterId)) {
      return this.#formattersMap.get(formatterId) as FormatterFn<T>;
    }

    return defaultFormatter;
  }

  getFormatterForFormField<T>(field: IDisplayField<unknown>): FormatterFn<T> {
    if (field.formatter && !(field.rendererMeta as ISelectMeta<unknown>)?.formatValueToLabel) {
      return typeof field.formatter === 'string'
        ? this.getFormatterForId(field.formatter)
        : this.#getOrCreateFormatter(field.formatter);
    }

    switch (field.renderer) {
      case RendererType.boolean:
        return this.#getOrCreateFormatter(BooleanFormattersConfig.yesNo);
      case RendererType.currency: {
        const currencyName = (field.rendererMeta as ICurrencyMeta)?.defaultCurrency ?? 'USD';

        return this.#getOrCreateFormatter({
          type: 'number',
          id: `${currencyName}-short`,
          config: {
            compactDisplay: 'short',
            currency: currencyName,
            notation: 'compact',
            style: 'currency',
          },
        });
      }

      case RendererType.date:
        return this.#getOrCreateFormatter(DateFormattersConfig.date);
      case RendererType.integer:
        return this.getFormatterForModel(NumericFormattersConfig.integer);
      case RendererType.multiplier:
        return this.#getOrCreateFormatter(NumericFormattersConfig.multiplier);
      case RendererType.number:
        return this.#getOrCreateFormatter(NumericFormattersConfig.numeric);
      case RendererType.percent:
        return this.#getOrCreateFormatter(NumericFormattersConfig.percent2dpAsIs);
      case RendererType.singleSelect: {
        const formatterKey = `${field.renderer}_${field.key}`;

        return this.#formattersMap.getOrDynamicDefault(
          formatterKey,
          () => getSelectFormatter(field) as FormatterFn<unknown>
        );
      }
      case RendererType.multiSelect: {
        const formatterKey = `${field.renderer}_${field.key}`;

        return this.#formattersMap.getOrDynamicDefault(
          formatterKey,
          () => getMultiSelectFormatter(field) as FormatterFn<unknown>
        );
      }

      default:
        return this.#getFormatterForTypeV3(field.dataType ?? 'string');
    }
  }

  getFormatterForModel<T>(formatterDataModel: IPartialFormatterDataModel<T>) {
    if (formatterDataModel.id) {
      return this.#formattersMap.get(formatterDataModel.id) ?? this.#getOrCreateFormatter(formatterDataModel);
    }

    return (
      this.#formattersMap.get(formatterDataModel.type as string) ??
      this.#getOrCreateFormatter(formatterDataModel)
    );
  }

  getFormatterModel(formatterId: StandardFormatterId) {
    return this.#baseMeta.get(formatterId);
  }

  getFormatterModelForFormField<T>(field: IDisplayField<unknown>) {
    let res: IFormatterDataModel<unknown> | undefined;

    if (typeof field.formatter === 'string') {
      res = this.#baseMeta.get(field.formatter);
    } else if (field.renderer) {
      res = this.#baseMeta.get(field.renderer);
    }

    return (res ??
      createFormatterDataModel({
        id: 'default',
      })) as IFormatterDataModel<T>;
  }

  addFormatter<T>(model: IFormatterDataModel<T>) {
    this.#baseMeta.set(model.id, model);
    this.#formattersMap.set(model.id, this.#createFormatter(model));
    if (model.excelStyle) {
      this.#excelStyleMap.set(model.id, { ...model.excelStyle, id: model.id });
    }
  }

  setFormatterForId<T>(formatterId: StandardFormatterId, formatter: FormatterFn<T>): void {
    this.#formattersMap.set(formatterId, formatter as FormatterFn<unknown>);
  }

  #getFormatterForTypeV3<T>(type: PrimitiveType): FormatterFn<T> {
    switch (type) {
      case 'boolean':
        return this.#getOrCreateFormatter(BooleanFormattersConfig.yesNo);
      case 'array':
        return this.#getOrCreateFormatter(ArrayFormattersConfig.stringArray);
      case RendererType.number: {
        return this.#getOrCreateFormatter(NumericFormattersConfig.numeric);
      }
      case 'date': {
        return this.#getOrCreateFormatter(DateFormattersConfig.date);
      }

      case 'string':
      default:
        return defaultFormatter;
    }
  }

  #getOrCreateFormatter<T>(formatterDataModel: IPartialFormatterDataModel<T>) {
    let formatter;

    if (this.#formattersMap.has(formatterDataModel.id)) {
      return this.getFormatterForId(formatterDataModel.id);
    }

    if (formatterDataModel.extends !== undefined) {
      const baseConfig = this.#baseMeta.get(formatterDataModel.extends);
      const extendedConfig = merge({}, baseConfig, formatterDataModel) as IFormatterDataModel<T>;
      this.#baseMeta.set(extendedConfig.id, extendedConfig);

      formatter = this.#createFormatter(extendedConfig);
    } else {
      this.#baseMeta.set(formatterDataModel.id, formatterDataModel);

      formatter = this.#createFormatter(formatterDataModel as IFormatterDataModel<T>);
    }

    this.#formattersMap.set(formatterDataModel.id, formatter);

    return formatter;
  }

  #createFormatter(meta: IFormatterDataModel<unknown>): FormatterFn<unknown> {
    switch (meta.type) {
      case 'array':
        return this.#createArrayFormatter(meta.config as IArrayFormatConfig) as FormatterFn<unknown>;
      case 'currency':
      case 'number':
        return createNumberFormatter(meta.config as ICustomNumberFormatConfig) as FormatterFn<unknown>;
      case 'date':
        return createDateFormatter(meta.config as ICustomDateFormatConfig) as FormatterFn<unknown>;
      case 'boolean':
        return createBooleanFormatter(meta.config as IBooleanFormatConfig) as FormatterFn<unknown>;
      case 'string':
        return createStringFormatter(meta.config as IStringFormatConfig) as FormatterFn<unknown>;

      default:
        return defaultFormatter;
    }
  }

  #createArrayFormatter<T>(arrayFormatConfig: IArrayFormatConfig): FormatterFn<Array<T>> {
    return (value: Array<T> | null | undefined) => {
      if (Array.isArray(value)) {
        if (arrayFormatConfig.itemFormatter) {
          const itemFormatter =
            typeof arrayFormatConfig.itemFormatter === 'string'
              ? this.getFormatterForId(arrayFormatConfig.itemFormatter)
              : this.getFormatterForModel(arrayFormatConfig.itemFormatter);

          return value.map((val) => itemFormatter(val)).join(', ');
        }
        return value.join(', ');
      }

      return '';
    };
  }

  static initService(formattersMeta: IFormatterDataModel<unknown>[]) {
    if (!this.#instance) {
      this.#instance = new FormatterService().init(formattersMeta);
    }

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      throw new Error('Formatter service has not been initialized');
    }
    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }

  static formatKPIPeriod = StaticFormattersById.kpiPeriod;

  /** @deprecated use {@link FormatterService.format} directly instead. */
  static numeric(value: number) {
    return this.get().#getOrCreateFormatter(NumericFormattersConfig.numeric)(value);
  }

  /** @deprecated use {@link FormatterService.format} directly instead. */
  // Quick access to most common formatters, assumes the class has been initialized.
  static percent(value: number) {
    return this.get().#getOrCreateFormatter(NumericFormattersConfig.percent)(value);
  }

  /** @deprecated use {@link FormatterService.format} directly instead. */
  static usdShort(value: number) {
    return this.get().#getOrCreateFormatter(NumericFormattersConfig.usdShort)(value);
  }

  static format(model: StandardFormatterId | IPartialFormatterDataModel<unknown> | string, value: unknown) {
    return typeof model === 'string'
      ? this.get().getFormatterForId(model)(value)
      : this.get().getFormatterForModel(model)(value);
  }
}

function createBooleanFormatter(options: IBooleanFormatConfig): FormatterFn<boolean> {
  return (value: boolean | null | undefined) => {
    if (value === true) {
      return options.trueValue;
    }
    if (value === false) {
      return options.falseValue;
    }
    return options.noValue;
  };
}

function createDateFormatter(options: ICustomDateFormatConfig) {
  const baseFormatter = new Intl.DateTimeFormat(navigator.language, options).format;

  return (date: string | Date | null | undefined) => {
    if (isEmpty(date)) {
      return options.noValue ?? '';
    } else {
      return typeof date === 'string' ? baseFormatter(new Date(date)) : baseFormatter(date!);
    }
  };
}

function createNumberFormatter(options: ICustomNumberFormatConfig): FormatterFn<number> {
  const baseFormatter = new Intl.NumberFormat(navigator.language, options).format;
  const { invalidValue = '', noValue = '', adjustWithAccountingErrorMargin = true } = options;

  return (baseValue: number) => {
    if (baseValue == null) return noValue;
    if (!isFinite(baseValue)) return invalidValue;

    const value =
      options.style === 'currency' && adjustWithAccountingErrorMargin
        ? adjustValueWithAccountingErrorMargin(baseValue)
        : baseValue;

    if (value === 0 && typeof options.zeroValue === 'string') return options.zeroValue;
    else if (value < 0 && typeof options.negativeValue === 'string') return options.negativeValue;
    else {
      if (options.suffix) {
        return `${baseFormatter(value)}${options.suffix}`;
      }
      return baseFormatter(value);
    }
  };
}

function createStringFormatter(options: IStringFormatConfig): FormatterFn<string> {
  return (value: string | null | undefined) => {
    if (value === '') {
      return options.emptyValue ?? '';
    }
    if (value == null) {
      return options.noValue ?? '';
    }
    if (options.transforms) {
      return options.transforms.reduce((current, transformKey) => {
        return StringTransforms[transformKey](current);
      }, value);
    }
    return String(value);
  };
}

/**
 * Shorthand alias
 */
export const FMT = FormatterService;

export function defaultFormatter(value: unknown) {
  return String(value ?? '');
}

function isEmpty(value: unknown) {
  return value === null || value === undefined || value === '';
}
