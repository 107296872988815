import { cloneDeep } from '@apollo/client/utilities';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { IViewConfig2 } from '../../../data-models/view.data-model';
import { serializeView, ViewModel } from '../../../models/view.model';
import { useUpdateSelectedView } from '../hooks/useViewActions';
import { createMprViewSettings } from './MultiPeriodReporting.schemas';
import { MprCompanyColId } from './multiperiodReportingGridData';
import { getColumnConfigKey, usePreprocessMprViewSettings } from './multiPeriodReportingUtils';
import { savedMprViewConfigAtom } from './useMprColumnConfigs';

export function useInitializeMprView(selectedView: ViewModel) {
  const view = serializeView(selectedView);
  const setViewConfig = useSetAtom(savedMprViewConfigAtom(view.id));
  const preprocessMprViewSettings = usePreprocessMprViewSettings();

  const { multiPeriodReportingConfig, gridState } = view.config ?? {};
  return useCallback(() => {
    if (!view?.id) return { initialized: false };
    const preprocessedConfig = multiPeriodReportingConfig
      ? preprocessMprViewSettings({ ...multiPeriodReportingConfig })
      : null;
    setViewConfig((prev) => {
      if (prev) return prev;
      if (!multiPeriodReportingConfig) {
        return createMprViewSettings();
      } else {
        return preprocessedConfig;
      }
    });
    const initialState = { ...cloneDeep(gridState), partialColumnState: true };
    if (
      multiPeriodReportingConfig?.columnSort &&
      initialState?.columnOrder?.orderedColIds &&
      preprocessedConfig
    ) {
      initialState.columnOrder!.orderedColIds = [MprCompanyColId].concat(
        preprocessedConfig.kpis.map((kpi) => getColumnConfigKey(kpi))
      );
    }
    return { initialized: true, initialState };
  }, [view?.id, multiPeriodReportingConfig, gridState, setViewConfig, preprocessMprViewSettings]);
}

export function useSaveMprStateInView() {
  const updateSelectedView = useUpdateSelectedView();

  return useCallback(
    async ({ gridState, multiPeriodReportingConfig }: IViewConfig2) => {
      if (!gridState && !multiPeriodReportingConfig) return;
      const updated: IViewConfig2 = {};
      if (gridState) updated.gridState = gridState;
      if (multiPeriodReportingConfig) updated.multiPeriodReportingConfig = multiPeriodReportingConfig;
      updateSelectedView(updated);
    },
    [updateSelectedView]
  );
}
