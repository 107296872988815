import { css } from '@emotion/react';
import { styled } from '@mui/material';
import { colors } from '../../../../../theme/colors';

export const Background = styled('div')`
  background: ${({ theme }) => theme.gradients.primary};
  min-height: 100%;
`;

export const headerWrapperCSS = css`
  background: white;
  border-bottom: 2px ${colors.neutral[40]} solid;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 5rem;
  position: sticky;
  top: 0;
  z-index: 5;
`;

export const statusWrapperCss = css`
  flex-basis: 100%;
  margin-top: 1rem;
`;

export const headerWrapperInnerCSS = css`
  width: 900px;
`;

export const kpiContentsWrapperCss = css`
  justify-content: center;
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
  padding: 0 5rem;
`;

export const kpiContentsInnerWrapperCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  width: 900px;
`;
