import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Stack } from '@mui/material';
import { fundOptionsState } from '../../PortfolioOverview/state/FundFilterState';
import { DateRangeSelector } from '../../Finance/form-components/DateRangeSelector';
import { FormatterService } from '../../../util/formatter-service';
import { DateFormattersConfig } from '../../../util/formatters/DateFormatters';
import { DefaultShortcuts } from '../../../components/DatePicker/RangeShortcuts';
import { PopupFilter } from '../../../components/PopupFilter';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { plainDateToZonedDateTime } from '../../../util/date-utilts';
import {
  fundFilterState,
  transactionTypeFilterState,
  transactionsDateFilterState,
} from './AllTransactionsFilterState';
import { useTransactionTypeOptions } from './useTransactionTypeOptions';

export function AllTransactionFilters() {
  const [selectedFunds, setSelectedFunds] = useRecoilState(fundFilterState);
  const fundOptions = useRecoilValue(fundOptionsState);
  const transactionTypeOptions = useTransactionTypeOptions();
  const [selectedSubTypes, setSelectedSubTypes] = useRecoilState(transactionTypeFilterState);

  return (
    <Stack
      display={'grid'}
      gridTemplateColumns='auto auto 1fr'
      gap={'1rem'}
      alignItems={'center'}
      justifyContent={'start'}
      width={'100%'}
    >
      <PopupFilter
        selected={selectedFunds}
        setSelected={setSelectedFunds}
        options={fundOptions}
        labels={{ item: 'Fund' }}
        groupable
      />
      <PopupFilter
        groupable
        searchable
        selected={selectedSubTypes}
        setSelected={setSelectedSubTypes}
        options={transactionTypeOptions}
        labels={{ item: 'Transaction Type' }}
      />
      <TransactionDateFilter />
    </Stack>
  );
}

export function TransactionDateFilter() {
  const [selectedDate, setSelectedDate] = useRecoilState(transactionsDateFilterState);
  const [selectedStartingDate, setSelectedStartingDate] = useState<Date | null>(() =>
    plainDateToZonedDateTime(selectedDate.fromDate)
  );
  const [selectedEndingDate, setSelectedEndingDate] = useState<Date | null>(() =>
    plainDateToZonedDateTime(selectedDate.toDate)
  );

  const dateFormatter = FormatterService.get().getFormatterForModel(DateFormattersConfig.dateNumeric);

  useEffect(() => {
    if (selectedStartingDate && selectedEndingDate) {
      setSelectedDate({
        fromDate: getPlainDateString(selectedStartingDate),
        toDate: getPlainDateString(selectedEndingDate),
      });
    }
  }, [selectedStartingDate, selectedEndingDate, setSelectedDate]);

  return (
    <DateRangeSelector
      setSelectedStartingDate={setSelectedStartingDate}
      setSelectedEndingDate={setSelectedEndingDate}
      textContent={(selectedStartingDate, selectedEndingDate) => {
        return selectedStartingDate && selectedEndingDate
          ? `${dateFormatter(selectedStartingDate)} - ${dateFormatter(selectedEndingDate)}`
          : 'Select Date Range';
      }}
      style={{ justifySelf: 'end' }}
      initialStartDate={new Date(selectedDate.fromDate)}
      initialEndDate={new Date(selectedDate.toDate)}
      shortcuts={DefaultShortcuts}
    />
  );
}
