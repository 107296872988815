import { FC, useMemo } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { Typography, useTheme, Chip } from '@mui/material';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IDealStageDataModel } from '../../../../DealFlow2/data-models/dealStage.data-model';
import { dealStagesByIdMapState } from '../../../../DealFlow2/state/DealboardDataState';
import { IDealDataModel } from '../../../../../data-models/deal.data-model';
import { IPassChipProps, PassChip } from '../Header/PassChip';
import { DealsForCompanyLoadedState } from '../../../../../services/state/DealState';

interface Props extends IPassChipProps {
  allCompanyDeals: IDealDataModel[] | null;
  company: ICompanyDataModel;
}

export const StatusChip: FC<Props> = ({
  allCompanyDeals,
  company,
  handleTogglerPassDetails,
  showPassDetails,
}) => {
  const { colors } = useTheme();
  const backgroundColor = colors.warning[10];
  const color = colors.warning[60];
  const stages = useRecoilValueLoadable(dealStagesByIdMapState);
  const dealsLoaded = useRecoilValue(DealsForCompanyLoadedState(company.id));
  const commonSx = { py: 6, px: 4 };

  const status = useMemo(() => {
    const stageMap = stages.valueMaybe();
    if (!stageMap || !dealsLoaded) return null;
    if (company.companyType === 'portfolio') return 'Portfolio';
    else if (hasActiveDeal(allCompanyDeals, stageMap)) return 'Pipeline';
    else if (hasPassedDeal(allCompanyDeals, stageMap)) return 'Pass';
    return 'Discovery';
  }, [stages, dealsLoaded, company.companyType, allCompanyDeals]);

  if (!status) return null;

  if (status === 'Pass')
    return (
      <PassChip
        handleTogglerPassDetails={handleTogglerPassDetails}
        showPassDetails={showPassDetails}
        sx={commonSx}
      />
    );

  return (
    <Chip
      label={<Typography variant={'body2'}>{status}</Typography>}
      sx={{
        backgroundColor,
        color,
        ...commonSx,
      }}
    />
  );
};

function hasActiveDeal(deals: IDealDataModel[] | null, stagesMap?: Map<number, IDealStageDataModel>) {
  if (!stagesMap || !deals) return false;

  return deals.some((d) => {
    const stageName = stagesMap.get(d.stageId)?.name;
    if (!stageName) return false;
    return !['Pass', 'Track', 'Missed', 'Closed'].includes(stageName);
  });
}

function hasPassedDeal(deals: IDealDataModel[] | null, stagesMap?: Map<number, IDealStageDataModel>) {
  if (!stagesMap || !deals) return false;

  return deals.some((d) => {
    const stageName = stagesMap.get(d.stageId)?.name;
    if (!stageName) return false;
    return stageName === 'Pass';
  });
}
